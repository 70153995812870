import React from 'react'
import AuthWrapper from '../components/layout/AuthWrapper'
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <AuthWrapper title="Forgot Password">
      <ForgotPasswordForm/>
    </AuthWrapper>
  )
}

export default ForgotPassword