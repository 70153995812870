import { useState } from "react";
import {
  Input,
  InputGroup,
  Stack,
  InputRightElement,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
  Image,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { signInSchema } from "../../utils/formValidationSchema";
import { useDispatch } from "react-redux";
import { signIn } from "../../services/authService";
import { persistAuth } from "../../redux/slices/authSlice";
import logo from '../../assets/images/thumbnail_image006.png'


const LoginForm = () => {;
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true)
    setError("")
    const res = (await signIn(values))
    if(res?.status === true) {
      dispatch(persistAuth(res?.data))
      navigate("/dashboard")
    }else{
      setError("Invalid Credentials...")
    }
    setLoading(false)
  };

  return (
    <>
      <Stack width={"full"} justify={"center"} my={"30px"}>
        <Image src={logo} alt="" width={"80px"} mx={"auto"}/>
      </Stack>
      {error && (
        <Alert status="error" className="mt-3 mb-4">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={signInSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, handleBlur, handleChange }) => (
          <Form className="mb-6">
            <Stack spacing={6}>
              <FormControl
                isInvalid={errors.email && touched.email}
                mb={5}
                color="black"
              >
                <InputGroup>
                  <Input
                    size="lg"
                    type="text"
                    name="email"
                    placeholder="Enter Email Address"
                    variant="flushed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputGroup>
                {touched.email && errors.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={errors.password && touched.password}
                mb={5}
                color="black"
              >
                <InputGroup size="lg">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    variant="flushed"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      background={"none"}
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <ViewIcon w={5} h={5} color="GrayText" />
                      ) : (
                        <ViewOffIcon color={"GrayText"} w={5} h={5} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {touched.password && errors.password && (
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <HStack justifyContent="space-between" mt={4}>
                <Link to="/forgot-password" className="text-primaryOrange">
                  Forgot Password
                </Link>
              </HStack>
              <Button
                isLoading={loading}
                loadingText="Logging in ..."
                colorScheme="orange"
                type="submit"
              >
                Login
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
