import React from 'react'
import { HStack, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import ResponsivePagination from "react-responsive-pagination";
import { fetchModule, updateModulePagination } from '../redux/slices/moduleSlice';
import { useDispatch } from 'react-redux';
import { unlockModule } from '../services/moduleService';
import toast from 'react-hot-toast';

const CourseModuleList = ({modules, courseId}) => {
    const moduleList = modules?.module?.data;
    const dispatch = useDispatch()

    const unlockClass = async (id) => {
      try {
        const res = await unlockModule({id: id})
        console.log(res)
        if(res.status){
          toast.success(res.message);
          dispatch(fetchModule(courseId));
        }
      } catch (error) {
        console.log(error)
        toast.error(error?.message)
      }
    }

  return (
    <>
    <UnorderedList>
       {
        moduleList?.map(({id, title, description, isOpen}, index) => (
            <ListItem key={id} marginY={4} paddingBottom={4} borderBottom={"1px solid #BEBEBE53"}>
                <HStack gap={"8rem"}>
                    <Stack>
                        <Text fontWeight={"semibold"}>Module {index + 1}</Text>
                        <Text color="#E48405">{title}</Text>
                        <Text>{description}</Text>
                    </Stack>
                    <Stack>
                    <Button 
                      onClick={() => unlockClass(id)}
                      background={"none"} 
                      border={"1px solid #5CBF22"} 
                      color={"#5CBF22"} 
                      fontSize={"13px"}
                    >
                       { isOpen ? "Class Open" : "Unlock Class"} 
                    </Button>
                    </Stack>
                </HStack>
            </ListItem>
        ))
       } 
    </UnorderedList>

    <div className="mt-5">
          <ResponsivePagination
            current={modules.params?.pageNumber}
            total={modules?.module?.totalPages}
            onPageChange={(page) => dispatch(updateModulePagination(page))}
          />
        </div>
    </>
  )
}

export default CourseModuleList