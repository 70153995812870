import axios from 'axios';
import storeInit from "../redux/store"
import { removeUser } from '../redux/slices/authSlice';


const httpRequest = async (url, method = 'get', body = null, others) => {
    const token = storeInit.store.getState()?.auth?.user?.token;
    try {
        const response = await axios({
            url,
            method,
            data: body,
            headers: {
                Authorization: `Bearer ${token}`,
              },
            ...others,
        });
        return response?.data;
    } catch (error) {
        if(error.response?.status === 401){
            console.log("unauthorized");
            storeInit.store.dispatch(removeUser());
        }else{
            return error.response?.data;
        }
     
    }
};

export default httpRequest;