import {
  Box,
  Center,
  Flex,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { menu } from "../../data/app";
import { useSelector } from "react-redux";
import { BiCaretDown } from "react-icons/bi";
import { Fragment, useState } from "react";
import logo from '../../assets/images/thumbnail_image006.png'

const Sidebar = ({ onClose }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const role = useSelector((state) => state?.auth?.user?.role);
  const { pathname } = useLocation();

  return (
    <Flex
      as={"nav"}
      direction="column"
      bgColor={"white"}
      color={"black"}
      w="full"
      h="full"
    >
      <Center h="4rem" boxShadow={"0px 3px 6px #00000029"} p={4}>
        <Image src={logo} w={'60px'} h={"50px"} />
      </Center>

      <List
        alignSelf={"stretch"}
        sx={{
          "& svg": {
            width: "1.5rem",
            height: "1.5rem",
          },
        }}
      >
        {menu[role]?.map(({id, name, link, icon, submenu }, index) => {
          return !submenu ? (
            <Fragment key={id}>
            <ListItem
              as={NavLink}
              to={link}
              py={3}
              mt={[1, 2, 5]}
              display={"flex"}
              position="relative"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {({ isActive }) => (
                <Box w="full">
                  <Flex alignItems={"center"} minW={"80%"} gap={1} px={2}>
                    {isActive && (
                      <Box
                        position={"absolute"}
                        left={0}
                        width={"full"}
                        height={"full"}
                        zIndex={1}
                        bg={"#F2F2F2"}
                        // borderLeft={"0.3rem solid #5CBF22"}
                        // borderLeft={"0.3rem solid #E53E3E"}
                        className="border-l-4 border-altasColor"
                      />
                    )}
                    <ListIcon
                      zIndex={2}
                      flexShrink={0}
                      flexGrow={0}
                      as={icon}
                      m={0}
                    />
                    <Text zIndex={2} as={"span"} flexGrow={1}>
                      {name}
                    </Text>
                  </Flex>
                </Box>
              )}
            </ListItem>
            </Fragment>
          ) : (
            <>
              <ListItem
                key={name}
                cursor={"pointer"}
                py={3}
                display={"flex"}
                position="relative"
                onClick={() => {
                  setShowDropdown(!showDropdown);
                }}
                mt={[1, 2, 5]}
              >
                <Box w="full">
                  <Flex alignItems={"center"} minW={"80%"} gap={1} px={2}>
                    {pathname.includes("user-management") && (
                      <Box
                        position={"absolute"}
                        left={0}
                        width={"full"}
                        height={"full"}
                        zIndex={1}
                        bg={"#F2F2F2"}
                        // borderLeft={"0.3rem solid #5CBF22"}
                        // borderLeft={"0.3rem solid #E53E3E"}
                        className="border-l-4 border-altasColor"
                      />
                    )}
                    <ListIcon
                      zIndex={2}
                      flexShrink={0}
                      flexGrow={0}
                      as={icon}
                      m={0}
                    />
                    <Text zIndex={2} as={"span"} flexGrow={1}>
                      {name}
                    </Text>

                    <BiCaretDown style={{ marginLeft: "auto", zIndex: 1 }} />
                  </Flex>
                </Box>
              </ListItem>
              {showDropdown ? (
                <Flex direction={"column"}>
                  {submenu.map(({ id, name, link }) => {
                    return (
                      <Box
                        pl={9}
                        key={id}
                        as={NavLink}
                        to={link}
                        py={2}
                        onClick={() => {
                          onClose && onClose();
                        }}
                        // bg="#E8AC28"
                        className={"bg-atlasLight"}
                      >
                        {({ isActive }) => (
                          <Flex h="full">
                            {isActive && (
                              <Box
                                position={"absolute"}
                                left={0}
                                width={"full"}
                                height={6}
                                zIndex={1}
                                className="border-l-4 border-altasColor"
                                // borderLeft={"0.3rem solid #5CBF22"}
                                // borderLeft={"0.3rem solid #E53E3E"}
                              />
                            )}
                            {name}
                          </Flex>
                        )}
                      </Box>
                    );
                  })}
                </Flex>
              ) : null}
            </>
          );
        })}
      </List>

      <Flex
        as={NavLink}
        gap={1}
        px={2}
        justifyContent={"center"}
        alignItems={"center"}
        mt="auto"
        h="7rem"
        // bg="#53ad1f"
        // bg={"red.500"}
        className="bg-altasColor"
        color={"white"}
        to="/settings"
        onClick={() => {
          onClose && onClose();
        }}
      >
        <MdSettings size={"1.5rem"} />
        <Text>Settings</Text>
      </Flex>

      <Center py={4}>
        <Text fontSize={"0.75rem"} color={"#525252"}>
          Powered by Vatebra Ltd
        </Text>
      </Center>
    </Flex>
  );
};

export default Sidebar;
