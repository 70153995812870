import React from 'react';
import PageTitle from '../components/PageTitle';
import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Text } from '@chakra-ui/react';
import {BsArrowLeft} from 'react-icons/bs';
import {MdOutlineEdit, MdOutlinePhotoCamera} from 'react-icons/md';
import profilepics from '../assets/images/default_profile_pics.png';

const Account = () => {

    return (
        <>
            <PageTitle title="Account"/>
            
            <Box mx={['0px', '20px', '50px']} >
                <Button leftIcon={<BsArrowLeft style={{width:'12px', height:'12px'}} />}textAlign={'left'} 
                color={'#040505'} letterSpacing={'0.29px'} fontSize={'16px'}fontWeight={'normal'} 
                bg={'transparent'} _hover={{backgroundColor: ""}} my={'10px'} pl={'0'}
                > Back
                </Button>
                
                <Box bg={"#FFFFFF"} height={['1050px', '722px', '722px']}>
                    <Box  >
                        <Flex direction={['column', 'row', 'row']} justifyContent={['center', 'space-between', 'flex-end']} alignItems={'center'} gap={['10px', '50px', '200px']} width={['90%', '80%', '80%']} mx={'auto'} >
                            <Box position={'relative'} bottom={'20px'} >
                                <form>
                                    <label htmlFor="pictureUpload">
                                        <Image src={profilepics} alt="Profile Pics" borderRadius='full' w={'150px'} h={'150px'} cursor={'pointer'}
                                        />
                                        <Box bg={'red.400'} color={'#FFFFFF'} borderRadius='full' h={'30px'} w={'30px'} display={'flex'}
                                            justifyContent={'center'} alignItems={'center'} position={'relative'}  bottom={'50px'} left={'120px'} cursor={'pointer'}
                                        >
                                            <MdOutlinePhotoCamera />
                                        </Box>
                                    </label>
                                    <input type="file" id="pictureUpload" name="pictureUpload" style={{ display: 'none' }}/>
                                </form>
                                
                            </Box>
                            <Button leftIcon={<MdOutlineEdit style={{width:'16px', height:'16px'}} />} bg={'#E53E3E'}  color={'#FFFFFF'} fontSize={'14px'}
                                fontWeight={'medium'} letterSpacing={'0.5px'} borderRadius={'3px'} _hover={{backgroundColor: "#E53E3E"}}
                            >
                                Edit profile
                            </Button>
                        </Flex>
                    </Box>
                    <Box width={['90%', '80%', '80%']} mx={'auto'}>
                        <Text fontSize={'16px'} fontWeight={'bold'} color={'#E53E3E'} mt={['30px', '10px', '10px']} mb={'30px'}>
                            Update profile information
                        </Text>
                        <Flex  gap={'30px'} direction={['column', 'row', 'row']}>
                            <FormControl>
                                <FormLabel fontSize={'16px'} color={'#808181'}>First name</FormLabel>
                                <Input type='text' placeholder='Thomas' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                    focusBorderColor='#ffffff' borderColor={'#FFFFFF'} _hover={{borderColor:'#FFFFFF'}} 
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontSize={'16px'} color={'#808181'}>Last name</FormLabel>
                                <Input type='text' placeholder='Andrew P.' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                     focusBorderColor='#ffffff' borderColor={'#FFFFFF'} _hover={{borderColor:'#FFFFFF'}}
                                />
                            </FormControl>
                        </Flex>

                        <Flex  gap={'30px'} mt={'37px'} mb={'33px'} direction={['column', 'row', 'row']}>
                            <FormControl>
                                <FormLabel fontSize={'16px'} color={'#808181'}>Email address</FormLabel>
                                <Input type='email' placeholder='t_andrew@hotmail.com' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                    focusBorderColor='#ffffff' borderColor={'#FFFFFF'} _hover={{borderColor:'#FFFFFF'}} 
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontSize={'16px'} color={'#808181'}>Phone number</FormLabel>
                                <Input type='tel' placeholder='07040567800' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                     focusBorderColor='#ffffff' borderColor={'#FFFFFF'} _hover={{borderColor:'#FFFFFF'}}
                                />
                            </FormControl>
                        </Flex>
                    </Box>

                    <Box bg={'#F6F6F6'} >
                        <Box width={['90%', '80%', '80%']} mx={'auto'} pb={'30px'}>
                            <Text fontSize={'16px'} fontWeight={'bold'} color={'#E53E3E'} pt={'20px'} mb={'30px'}>
                            Change password
                            </Text>
                            <Flex  gap={'30px'} direction={['column', 'row', 'row']} >
                                <FormControl>
                                    <FormLabel fontSize={'16px'} color={'#808181'}>Current password <span style={{color:"#D40E1F"}}>*</span></FormLabel>
                                    <Input type='password' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                        focusBorderColor='#F6F6F6' borderColor={'#F6F6F6'} _hover={{borderColor:'#F6F6F6'}} 
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'16px'} color={'#808181'} bg={'transparent'}>New password <span style={{color:"#D40E1F"}}>*</span></FormLabel>
                                    <Input type='password' borderRadius={'0'} bg={'#E2E2E2'} _placeholder={{color:'#374B43'}}
                                        focusBorderColor='#F6F6F6' borderColor={'#F6F6F6'} _hover={{borderColor:'#F6F6F6'}}
                                    />
                                </FormControl>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    );
};

export default Account;