import React, { useState } from 'react';
import { Button, Center, Text } from '@chakra-ui/react';
import { BiMinus, BiPlus } from 'react-icons/bi';

function Counter() {
  const [count, setCount] = useState(0);

  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  return (
    <Center>
      <Button background={"none"} _focus={{background : "none"}} _hover={{background : "none"}} onClick={decreaseCount} mr={4} >
        <BiMinus/>
      </Button>
      <Text fontSize="sm" border={"1px solid #5CBF2259"} paddingX={2} borderRadius={"md"}>{count}</Text>
      <Button background={"none"} _focus={{background : "none"}} _hover={{background : "none"}} onClick={increaseCount} ml={4}>
        <BiPlus />
      </Button>
    </Center>
  );
}

export default Counter;
