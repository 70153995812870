import { MdManageAccounts } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { BiSolidBookReader} from "react-icons/bi";
import { HiSpeakerphone } from "react-icons/hi";
import { BiSolidReport } from "react-icons/bi";

export const roles = [
  {
    id: "Lecturer",
    name: "Lecturer",
  },
  {
    id: "Admin",
    name: "Admin",
  },
];

export const menu = {
  Admin: [
    {id: 1, name: "Dashboard", link: "/dashboard", icon: RiDashboardFill },
    {
      id: 2,
      name: "Programme Management",
      link: "/programme-management",
      icon: BiSolidBookReader,
    },
    {
      id: 3,
      name: "User Management",
      icon: MdManageAccounts,
      submenu: [
        {
          id: 33,
          name: "Admin",
          link: "/user-management/admin",
        },
        {
          id: 34,
          name: "Lecturer",
          link: "/user-management/lecturer",
        },
        {
          id: 35,
          name: "Student",
          link: "/user-management/student",
        },
      ],
    },
    {
      id: 4,
      name: "Announcement",
      link: "/announcement",
      icon: HiSpeakerphone,
    },
    {
      id: 5,
      name: "Report",
      link: "/report",
      icon: BiSolidReport,
    },
    {
      id: 6,
      name: "Library Management",
      link: "/library-management",
      icon: MdManageAccounts,
    },
  ],
  Lecturer: [
    {id: 7, name: "Dashboard", link: "/dashboard", icon: RiDashboardFill },
    {id: 8, name: "Course Management", link: "/course-management", icon: BiSolidBookReader},
    {id: 81, name: "Announcement", link: "/announcement", icon: HiSpeakerphone},
    // { id: 9, name: "Account", link: "/account", icon: MdManageAccounts },
  ],
  Student: [
    {id: 10, name: "Dashboard", link: "/dashboard", icon: RiDashboardFill },
    {id: 11, name: "Courses", link: "/courses", icon: BiSolidBookReader,},
    { id: 12, name: "Library", link: "/library-management", icon: MdManageAccounts },
    { id: 13, name: "Grades", link: "/grade", icon: MdManageAccounts },
    { id: 14, name: "Annoucement", link: "/announcement", icon: HiSpeakerphone },
    // { id: 15, name: "Files", link: "/files", icon: BiFileFind },
  ],
};
