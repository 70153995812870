import React, { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import { Flex } from "@chakra-ui/react";
import DashboardCard from "../components/DashboardCard";
import GenderPieChart from "../components/GenderPieChart";
import AttendanceGraph from "../components/AttendanceGraph";
import GeneralAnnouncement from "../components/GeneralAnnouncement";
// import StudentsReport from "../components/StudentsReport";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminDashboard } from "../redux/slices/dashboardSlice";


// const calendarStyles = {
//   background: "none",
//   bottom: 0,
//   // color: "transparent",
//   cursor: "pointer",
//   height: "auto",
//   left: 0,
//   position: "absolute",
//   right: 0,
//   top: 0,
//   width: "auto",
// };

const Dashboard = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard);
  const dashboardData = data?.adminDashboard;

  useEffect(() => {
    dispatch(fetchAdminDashboard())
  },[dispatch])

  const cardDetails = [
    { name: "Active Students", number: dashboardData?.totalStudents, iconImg: "/students-icon.svg" },
    { name: "Courses", number: dashboardData?.totalCourses, iconImg: "/courses-icon.svg" },
    { name: "Programmes", number: dashboardData?.totalDepartments, iconImg: "/departments-icon.svg" },
    { name: "Active Lecturers", number: dashboardData?.totalLecturers, iconImg: "/lecturers-icon.svg" },
  ];

  return (
    <>
      <PageTitle title="Dashboard" />
      {/* <Flex
        gap={2}
        alignItems={"center"}
        bg="white"
        px={2}
        my={2}
        display={"inline-flex"}
        alignSelf={"start"}
        sx={{
          "input[type='date']::-webkit-calendar-picker-indicator ":
            calendarStyles,
        }}
      >
        <BiCalendar size={"1.5rem"} />
        <Input
          w={"7rem"}
          px={1}
          border={"none"}
          type="date"
          _placeholder={{ color: "#B4B1B1" }}
        />{" "}
        -{" "}
        <Input
          w={"7rem"}
          px={1}
          border={"none"}
          type="date"
          _placeholder={{ color: "#B4B1B1" }}
        />
      </Flex> */}

      <Flex wrap={"wrap"} mx={-2}>
        {cardDetails.map((card, i) => (
          <DashboardCard {...card} key={i} />
        ))}
      </Flex>

      <Flex
        sx={{
          "@media screen and (max-width: 75rem)": {
            flexWrap: "wrap",
          },
          "@media screen and (min-width: 75rem)": {
            flexWrap: "no-wrap",
          },
        }}
        mx={-2}
      >
        <Flex
          p={2}
          sx={{
            flex: "100%",
            "@media screen and (min-width: 48rem)": {
              flex: "100%",
            },
            "@media screen and (min-width: 62rem)": {
              flex: "50%",
            },
            "@media screen and (min-width: 75rem)": {
              flex: "50%",
            },
          }}
        >
          <AttendanceGraph />
        </Flex>
        <Flex
          p={2}
          sx={{
            flex: "100%",
            "@media screen and (min-width: 48rem)": {
              flex: "100%",
            },
            "@media screen and (min-width: 62rem)": {
              flex: "25%",
            },
            "@media screen and (min-width: 75rem)": {
              flex: "25%",
            },
          }}
        >
          <GenderPieChart maleStudentCount={dashboardData?.maleStudents} femaleStudentCount={dashboardData?.femaleStudents}/>
        </Flex>
        <Flex
          p={2}
          sx={{
            flex: "100%",
            "@media screen and (min-width: 48rem)": {
              flex: "100%",
            },
            "@media screen and (min-width: 62rem)": {
              flex: "25%",
            },
            "@media screen and (min-width: 75rem)": {
              flex: "25%",
            },
          }}
        >
          <GeneralAnnouncement />
        </Flex>
      </Flex>

      {/* <StudentsReport /> */}
    </>
  );
};

export default Dashboard;
