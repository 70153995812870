export const convertArray = (arr) => {
    return arr?.map((item) => {
        const value = item?.toLowerCase()
        return value;
    })
}

export const newQuestionsArr = (arr, items) => {
    return arr?.map((item, index) => ({
        ...item,
        item: items[index]
    }))
}
