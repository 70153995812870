import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import { ChakraBaseProvider } from "@chakra-ui/react";
import Dashboard from "./screens/Dashboard";
import Report from "./screens/Report";
import Announcement from "./screens/Announcement";
import StudentManagement from "./screens/StudentManagement";
import UserManagement from "./screens/UserManagement";
import ProgrammeManagement from "./screens/ProgrammeManagement";
import ProgrammeManagementList from "./screens/ProgrammeManagementList";
import CourseList from "./screens/CourseList";
import Settings from "./screens/Settings";
import { extendTheme } from "@chakra-ui/react";
import ProtectedRoute from "./components/ProtectedRoute";
import PageNotFound from "./screens/404";
import LibraryManagement from "./screens/LibraryManagement";
import ChangePassword from "./screens/ChangePassword";
import FormModal from "./components/modals/FormModal";
import FullPageLoader from "./components/loaders/FullPageLoader";
import Account from "./screens/Account";
import CourseManagement from "./screens/CourseManagement";
import { useSelector } from "react-redux";
import LecturerDashboard from "./screens/LecturerDashboard";
import Events from "./screens/Events";
import EmailReminder from "./screens/EmailReminder";
import CreateNewEmail from "./screens/CreateNewEmail";
import Submission from "./screens/Submission";
import UIploadQuestions from "./screens/UIploadQuestions";
import ClassModule from "./screens/ClassModule";
import ConfirmPopup from "./modals/ConfirmPopup";
import { Toaster } from "react-hot-toast";
import StudentDashboard from "./screens/StudentDashboard";
import CourseDetails from "./screens/CourseDetail";
import StudentCourses from "./screens/StudentCourses";
import StudentFiles from "./screens/StudentFiles";
import StudentGrades from "./screens/StudentGrades";
import StudentGradeSubject from "./screens/StudentGradeSubject";
import LiveClassSession from "./components/LiveClassSession";
import ForgotPassword from "./screens/ForgotPassword";

const theme = extendTheme({
  fonts: {
    heading: `'DaxlinePro', sans-serif`,
    body: `'DaxlinePro', sans-serif`,
  },
});

const App = () => {
  const user = useSelector((state) => state?.auth?.user);

  return (
    <ChakraBaseProvider theme={theme} resetCSS>
      <FormModal />
      <ConfirmPopup/>
      <FullPageLoader />
      <Toaster/>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/confirm-email" element={<ChangePassword />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          element={
            <ProtectedRoute path="dashboard" roles={["Admin", "Lecturer", "Student"]} />
          }
        >
          <Route
            path="dashboard"
            element={
              user?.role === "Lecturer" ? 
              <LecturerDashboard />
              : user?.role === "Student" ? 
              <StudentDashboard /> :
              <Dashboard /> 
            }
          />
          <Route path="events" element={<Events />} />
        </Route>

        <Route
          element={
            <ProtectedRoute path="programme-management" roles={["Admin"]} />
          }
        >
          <Route
            path="programme-management"
            element={<ProgrammeManagement />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute
              path="programme-management-list/:sessionId"
              roles={["Admin"]}
            />
          }
        >
          <Route
            path="programme-management-list/:sessionId/:year"
            element={<ProgrammeManagementList />}
          />
        </Route>
        <Route
          element={<ProtectedRoute path="course-list" roles={["Admin"]} />}
        >
          <Route path="course-list/:programmeId/:name" element={<CourseList />} />
        </Route>

        <Route
          element={<ProtectedRoute path="user-management" roles={["Admin"]} />}
        >
          <Route path="user-management/:type" element={<UserManagement />} />
        </Route>

        <Route
          element={
            <ProtectedRoute path="student-management" roles={["Admin"]} />
          }
        >
          <Route path="student-management" element={<StudentManagement />} />
        </Route>

        <Route
          element={<ProtectedRoute path="announcement" roles={["Admin", "Student", "Lecturer"]} />}
        >
          <Route path="announcement" element={<Announcement />} />
        </Route>

        <Route element={<ProtectedRoute path="report" roles={["Admin"]} />}>
          <Route path="report" element={<Report />} />
        </Route>

        <Route element={<ProtectedRoute path="settings" roles={["Admin"]} />}>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route
          element={
            <ProtectedRoute path="library-management" roles={["Admin", "Student"]} />
          }
        >
          <Route path="library-management" element={<LibraryManagement />} />
        </Route>
        <Route
          element={
            <ProtectedRoute path="course-management" roles={["Lecturer", "Admin"]} />
          }
        >
          <Route path="course-management" element={<CourseManagement />} />
          <Route path="course-management/email-reminder/:courseId" element={<EmailReminder />} />
          <Route path="course-management/email-reminder/new-email/:courseId" element={<CreateNewEmail />} />
        </Route>
        <Route element={<ProtectedRoute path="class-module/:courseId/:courseName" roles={["Lecturer", "Admin"]} />}>
          <Route path="class-module/:courseId/:courseName" element={<ClassModule />} />
        </Route>
        <Route element={<ProtectedRoute path="submissions" roles={["Lecturer", "Admin"]} />}>
          <Route path="submission" element={<Submission />} />
        </Route>
        <Route element={<ProtectedRoute path="upload-questions/:courseId" roles={["Lecturer", "Admin"]} />}>
          <Route path="upload-questions/:courseId" element={<UIploadQuestions />} />
          <Route path="continue-upload-questions/:courseId/:assesmentId" element={<UIploadQuestions />} />
        </Route>
        <Route element={<ProtectedRoute path="account" roles={["Lecturer", "Admin", "Student"]} />}>
          <Route path="account" element={<Account />} />
        </Route>
        <Route element={<ProtectedRoute path="course-detail" roles={["Lecturer", "Admin", "Student"]} />}>
          <Route path="course-detail/:courseId" element={<CourseDetails />} />
        </Route>
        <Route element={<ProtectedRoute path="liveclass-session/:appId/:topic" roles={["Lecturer", "Student"]} />}>
          <Route path="liveclass-session/:appId/:topic" element={<LiveClassSession />} />
        </Route>
        <Route element={<ProtectedRoute path="grade" roles={["Lecturer", "Admin", "Student"]} />}>
          <Route path="grade" element={<StudentGrades />} />
          <Route path="grade/subject/:courseName" element={<StudentGradeSubject />} />
        </Route>

        <Route element={<ProtectedRoute path="courses" roles={["Admin", "Student"]} />}>
          <Route path="courses" element={<StudentCourses />} />
        </Route>
        <Route element={<ProtectedRoute path="files" roles={["Admin", "Student"]} />}>
          <Route path="files" element={<StudentFiles />} />
        </Route>
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </ChakraBaseProvider>
  );
};

export default App;
