import React from 'react';
import { dateInWord } from '../utils/func';
import { Link } from 'react-router-dom';

const Courses = ({courseList}) => {

    return (
        <>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 lg:gap-4 mt-4">
        {courseList?.map(
          ({ id, courseName, createdOn, studentCount, image }) => (
            <Link to={`/class-module/${id}/${courseName}`} className="relative" key={id}>
              <img src={image} alt="" className='w-full h-56 object-cover'/>
              <div className="absolute bottom-0 w-full p-2 border bg-white">
                <h3 className="font-extrabold text-lg">{courseName}</h3>
                <div className="flex items-center mt-1 justify-between">
                  <p className="text-[#0405057d] text-xs">
                    Created On : {dateInWord(createdOn)}
                  </p>
                  {/* <p className="text-[#0405057d]">
                    <BiDotsVerticalRounded />
                  </p> */}
                </div>
                <p className="mt-1 text-[#E53E3E] text-[13px] font-semibold">
                  {studentCount} students enrolled
                </p>
              </div>
            </Link>
          )
        )}
      </div>

      {/* <div className="mt-5">
          <ResponsivePagination
            current={courses.courseParams?.pageNumber}
            total={courses?.courseParams?.totalPages}
            onPageChange={(page) => dispatch(updateLecturerCoursePagination(page))}
          />
        </div> */}

      </>
    );
};

export default Courses;