import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { addProgrammeSchema } from "../../utils/formValidationSchema";
import { duration } from "../../data/mock";
import { editProgramme } from "../../services/ProgrammesService";
import { useState } from "react";
import { setFormModal } from "../../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import { fetchProgrammes } from "../../redux/slices/programmeSlice";
import toast from "react-hot-toast";

const EditProgramme = ({data}) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [changestartDate, setChangestartDate] = useState(false)
  const [changeEndDate, setChangeEndDate] = useState(false)
  
  const initialValues = {
    name: data?.name,
    code: data?.code,
    duration:data?.duration,
    starDate: data?.startDate,
    endDate: data?.endDate,
  };

  const handleSubmit = async (values) => {
    values.sessionId = data
    const formdata = {
        "id": data?.id,
        "name": values.name,
        "code": values.code,
        "duration": values.duration,
        "starDate": values.starDate,
        "endDate": values.endDate,
        "sessionId": data?.sessionId
      }
    setLoading(true)
    const res = await editProgramme(formdata, data?.id)
    if(res?.status === true) {
      dispatch(fetchProgrammes(data?.sessionId))
      toast.success(res?.message, {duration: 10000})
    }else {
        setLoading(false)
      toast.error(res?.data?.messages[0], {duration: 10000})
    }
    setLoading(false)
    dispatch(setFormModal({ status: false }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addProgrammeSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => {
        return(
          <Form>
            <FormControl  isInvalid={errors.name && touched.name}
                color="black">
              <FormLabel fontSize={"small"}>Programme Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="name"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={data?.name}
              />
              {touched.name && errors.name && (
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.code && touched.code}
                color="black">
              <FormLabel fontSize={"small"}>Programme Code</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="code"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={data?.code}
              />
              {touched.code && errors.code && (
                <FormErrorMessage>{errors.code}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.duration && touched.duration}
                color="black">
              <FormLabel fontSize={"small"}>Duration of Programme</FormLabel>
              <Select
                  name="duration"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={"gray.100"}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  placeholder="Select Duration"
                  defaultValue={data?.duration}
                >
                  {duration?.map((dur) => {
                      return(
                          <option key={dur} selected={data?.duration}>{dur}</option>
                        )
                      }
                  )}
                </Select>
              {touched.duration && errors.duration && (
                <FormErrorMessage>{errors.duration}</FormErrorMessage>
              )}
            </FormControl>
            <div className="flex gap-8">
              <FormControl mt={4} isInvalid={errors.starDate && touched.starDate}
                color="black">
                <FormLabel fontSize={"small"}>
                  Start <span className="text-red-500">*</span>{" "}
                </FormLabel>
                {
                  changestartDate ? <>
                      <Input
                          type="date"
                          required
                          placeholder="Pick a Date"
                          fontSize={"small"}
                          bg={"gray.100"}
                          name="starDate"
                          focusBorderColor="#ffffff"
                          borderColor={"#FFFFFF"}
                          _hover={{ borderColor: "#FFFFFF" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={data?.startDate}
                      />
                      {touched.starDate && errors.starDate && (
                          <FormErrorMessage>{errors.starDate}</FormErrorMessage>
                      )}
                  </> :
                  <>
                      <input type="text" disabled className="bg-slate-100 p-2 w-full rounded" value={data?.startDate?.split('T')[0]}/>
                      <span className="text-xs text-blue-500 cursor-pointer" onClick={() => setChangestartDate(true)}>change start date</span>
                  </>
                }
              </FormControl>
              <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                color="black">
                <FormLabel fontSize={"small"}>
                  End <span className="text-red-500">*</span>{" "}
                </FormLabel>
                {
                  changeEndDate ? <>
                      <Input
                          type="date"
                          required
                          placeholder="Pick a Date"
                          fontSize={"small"}
                          bg={"gray.100"}
                          name="endDate"
                          focusBorderColor="#ffffff"
                          borderColor={"#FFFFFF"}
                          _hover={{ borderColor: "#FFFFFF" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={data?.endDate}
                      />
                      {touched.endDate && errors.endDate && (
                          <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                      )}
                  </> :
                  <>
                      <input type="text" disabled className="bg-slate-100 p-2 w-full rounded" value={data?.endDate?.split('T')[0]}/>
                      <span className="text-xs text-blue-500 cursor-pointer" onClick={() => setChangeEndDate(true)}>change end date</span>
                  </>
                }
              </FormControl>
            </div>
  
            <Button
              width={"100%"}
              // background={"#5CBF22"}
              background={"#9361ea"}
              _hover={{ background: "#D40E1F" }}
              color={"whiteAlpha.900"}
              className="mt-5"
              type="Save"
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save
            </Button>
          </Form>
        )
      } 
      }
    </Formik>
  );
};

export default EditProgramme;
