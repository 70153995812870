import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
  Checkbox,
  Grid,
  GridItem,
  FormErrorMessage,
  Alert,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProgrammes,
  fetchProgrammes,
  fetchSessions,
} from "../../redux/slices/programmeSlice";
import { useFormik } from "formik";
import { addStudentSchema } from "../../utils/formValidationSchema";
import { clearCourses, fetchCourses } from "../../redux/slices/courseSlice";
import { createStudent, updateStudent } from "../../services/usersService";
import { setFormModal } from "../../redux/slices/modalSlice";
import toast from "react-hot-toast";
import { convertArray } from "../../utils/convertArray";
import { fetchAllStudents } from "../../redux/slices/getAllStudentSlice";

const AddStudents = ({data}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState("");
  const programmes = useSelector((state) => state.programmes);
  const courses = useSelector((state) => state.courses);
  const activeCourses = courses?.courses?.data?.filter((course) => course.isActive === true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch, programmes.sessionParams]);

  const initialValues = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    gender: data?.gender,
    regNumber: data?.regNumber,
    email: data?.email,
    session: data?.programme?.sessionId,
    role: "student",
    programmeId: data?.programmeId,
    dateEnrolled: data?.dateEnrolled,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addStudentSchema,
    validateOnMount: true,
    onSubmit: async (values, onSubmitProps) => {
      const formData = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        regNumber: values.regNumber,
        email: values.email,
        role: "student",
        programmeId: values.programmeId,
        dateEnrolled: values.dateEnrolled,
        enrolledCoursesId: list,
      };
      const editForm = {
        id: data?.id,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        regNumber: values.regNumber,
        email: values.email,
        programmeId: values.programmeId,
        CourseId: list || data?.assignedCourses,
      };

      if(list.length < 1) {
        return toast.error("Please assign course(s) to student")
      }
      setLoading(true)
      let res;
      if(data){
       res = await updateStudent(editForm, data?.id);
      }else{
        res = await createStudent(formData);
      }

      if(res.status === true) {
        dispatch(fetchAllStudents())
        dispatch(setFormModal({status: false}))
        toast.success("Student added successfully", {duration: 10000});
      }else{
        setLoading(false)
        onSubmitProps.setSubmitting(false);
        toast.error(res?.data?.message, {duration: 10000});
      }
      console.log(res);
      setLoading(false)
    },
  });

  useEffect(() => {
    if(data?.assignedCourses){
      const value = convertArray(data?.assignedCourses);
      setList(value);
    }
  },[data?.assignedCourses])

  useEffect(() => {
    if (formik.values?.session) {
      dispatch(fetchProgrammes(formik.values?.session));
    } else {
      dispatch(clearProgrammes());
    }
  }, [dispatch, formik.values?.session]);

  useEffect(() => {
    if (formik.values?.programmeId) {
      dispatch(fetchCourses(formik.values?.programmeId));
    } else {
      dispatch(clearCourses());
    }
  }, [dispatch, formik.values?.programmeId]);

  const onCheckItem = (value) => {
    setList([...list, value]);
    if (list.includes(value)) {
      const items = list.filter((x) => x !== value);
      setList(items);
    }
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Flex gap={"20px"} alignItems={"center"} mt={4}>
          <FormControl isInvalid={formik.errors.firstName && formik.touched.firstName} color="black">
            <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
              First Name
            </FormLabel>
            <Input
              placeholder="Enter here"
              name="firstName"
              bg={"#E2E2E280"}
              borderRadius={"0"}
              _placeholder={{ color: "#BEBEBE9C" }}
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              defaultValue={formik.values.firstName}
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formik.errors.lastName && formik.touched.lastName} color="black">
            <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
              Last Name
            </FormLabel>
            <Input
              name="lastName"
              placeholder="Enter here"
              bg={"#E2E2E280"}
              borderRadius={"0"}
              _placeholder={{ color: "#BEBEBE9C" }}
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              defaultValue={formik.values.lastName}
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <FormControl mt={4} isInvalid={formik.errors.gender && formik.touched.gender}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Gender
          </FormLabel>
          <Select
            name="gender"
            bg={"#E2E2E280"}
            borderRadius={"0"}
            _placeholder={{ color: "#BEBEBE9C" }}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            placeholder="Select here"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            defaultValue={formik.values.gender}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
          {formik.touched.gender && formik.errors.gender && (
            <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl mt={4} isInvalid={formik.errors.regNumber && formik.touched.regNumber}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Reg Number
          </FormLabel>
          <Input
            name="regNumber"
            type="string"
            placeholder="Enter here"
            bg={"#E2E2E280"}
            borderRadius={"0"}
            _placeholder={{ color: "#BEBEBE9C" }}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            defaultValue={formik.values.regNumber}
          />
          {formik.touched.regNumber && formik.errors.regNumber && (
            <FormErrorMessage>{formik.errors.regNumber}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl mt={4} isInvalid={formik.errors.email && formik.touched.email}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Email Address
          </FormLabel>
          <Input
            type="email"
            placeholder="Enter here"
            bg={"#E2E2E280"}
            borderRadius={"0"}
            _placeholder={{ color: "#BEBEBE9C" }}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            defaultValue={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="email"
          />
          {formik.touched.email && formik.errors.email && (
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl mt={4} isInvalid={formik.errors.session && formik.touched.session}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Session
          </FormLabel>
          <Select
            name="session"
            value={formik.values.session}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            bg={"#E2E2E280"}
            borderRadius={"0"}
            _placeholder={{ color: "#BEBEBE9C" }}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            placeholder="Enter here"
          >
            {programmes?.sessions?.data?.map(({ id, year }) => (
              <option value={id} key={id}>{`${year} session`}</option>
            ))}
          </Select>
          {formik.touched.session && formik.errors.session && (
            <FormErrorMessage>{formik.errors.session}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl mt={4} isInvalid={formik.errors.programmeId && formik.touched.programmeId}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Programme
          </FormLabel>
          <Select
            name="programmeId"
            value={formik.values.programmeId}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            bg={"#E2E2E280"}
            borderRadius={"0"}
            _placeholder={{ color: "#BEBEBE9C" }}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            placeholder="Select Programme"
          >
            {formik.values.session === "" ? (
              <option disabled>Select session to load programmes</option>
            ) : (
              programmes?.programmes?.data?.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))
            )}
          </Select>
          {formik.touched.programmeId && formik.errors.programmeId && (
            <FormErrorMessage>{formik.errors.programmeId}</FormErrorMessage>
          )}
        </FormControl>
        {
          !data?.dateEnrolled && 
            <FormControl mt={4} isInvalid={formik.errors.dateEnrolled && formik.touched.dateEnrolled}>
              <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
                Date Enrolled
              </FormLabel>
              <Input
                type="date"
                name="dateEnrolled"
                value={formik.values.dateEnrolled}
                placeholder="pick a date"
                bg={"#E2E2E280"}
                borderRadius={"0"}
                _placeholder={{ color: "#BEBEBE9C" }}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.dateEnrolled && formik.errors.dateEnrolled && (
                <FormErrorMessage>{formik.errors.dateEnrolled}</FormErrorMessage>
              )}
            </FormControl>
        }
        <Box mt={4}>
          <FormLabel color={"#374B43"} fontSize={"14px"} fontWeight={"bold"}>
            Assign Course
          </FormLabel>
          {formik.values.programmeId === "" ? (
            <Alert status="warning">Select Programme to load Courses</Alert>
          ) : courses?.courses?.data < 1 ? (
            <Alert status="warning">No Active Course found for this programme</Alert>
          ) : (
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={["10px", "20px", "20px"]}
            >
              {activeCourses?.map(({ id, courseName }) => {
                const courseId = id.toUpperCase();
                return(
                <GridItem key={id}> 
                  <Checkbox
                    value={id}
                    defaultChecked={data?.assignedCourses?.includes(courseId)}
                    iconColor="#5CBF22"
                    onChange={() => onCheckItem(id)}
                  >
                    {courseName}
                  </Checkbox>
                </GridItem>
              )})}
            </Grid>
          )}

          {formik.touched.enrolledCoursesId &&
            formik.errors.enrolledCoursesId && (
              <FormErrorMessage>
                {formik.errors.enrolledCoursesId}
              </FormErrorMessage>
            )}
        </Box>

        <ModalFooter mb={2}>
          <Button
            type="submit"
            width={"100%"}
            // background={"#5CBF22"}
            background={"red.300"}
            _hover={{ background: "#D40E1F" }}
            color={"whiteAlpha.900"}
            className="mt-5"
            isLoading={loading}
            loadingText="Processing..."
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </Box>
  );
};

export default AddStudents;
