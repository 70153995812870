import { Box, HStack, Center } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineDownload } from 'react-icons/md'
import { LiaFileSolid } from 'react-icons/lia'
import { MdArrowDropDown } from 'react-icons/md'
import { Flex, Text } from '@chakra-ui/react'
import { PiLinkSimpleHorizontalBold } from 'react-icons/pi'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { BiPlay } from 'react-icons/bi'
import { BiTimeFive } from 'react-icons/bi'

const list = [
    { id: 1, materials: 'Impact of Events-/hjkhlk/.', pages: '8 Pages', date: '10 May 2023', download: 'Download File' },
    { id: 2, materials: 'Impact of Events-/hjkhlk/.', pages: '8 Pages', date: '10 May 2023', download: 'Download File' },
    { id: 3, materials: 'Impact of Events-/hjkhlk/.', pages: '8 Pages', date: '10 May 2023', download: 'Download File' },
]

const list2 = [
    { id: 1, link: 'What is consumer behavior and why should i care', dateUploaded: '10 May 2023', copyLink: 'Copy link' },
    { id: 2, link: 'What is consumer behavior and why should i care', dateUploaded: '10 May 2023', copyLink: 'Copy link' },
    { id: 3, link: 'What is consumer behavior and why should i care', dateUploaded: '10 May 2023', copyLink: 'Copy link' },
]

const list3 = [
    { id: 1, link: 'Introduction to design thinking', length: '1hr30mins', dateUploaded: '10 May 2023', video: 'Download Video' },
]

function CourseResources() {
    return (
        <div>
            <Box mb={"30px"} 
            bg={"red.100"}
            // bg={'#F3FBEF'}
            borderRadius={'20px 0px 28px 0px'} py={'30px'} px={'30px'}>
                <p className='font-semibold text-2xl text-slate-800'>Resources</p>
            </Box>
            <div>
                <div className='border-b flex'>
                    <p className='text-sm text-[#E53E3E] font-bold'>PDF Materials</p>
                    <p className='text-sm text-[#0405057C] ml-[370px] font-bold'>Date Uploaded</p>
                </div>
                {
                    list.map(item => (
                        <div className='border-b w-[100%] p-1' key={item.id}>

                            <div className='flex w-[100%] justify-between '>
                                <div className=" bg-[#f4e4e4] flex justify-center items-center p-5 m-3">
                                    <LiaFileSolid size={20} color={'#E53E3E'} />
                                </div>
                                <div className='p-5 w-full space-y-2 block items-center'>
                                    <p className='text-[#0B82F8] font-bold text-sm'>{item?.materials}</p>
                                    <div className='flex'>
                                        <LiaFileSolid />
                                        <p className='text-[#040505] text-sm'>{item?.pages}</p>
                                    </div>
                                </div>
                                <div className='w-full flex items-center text-[#040505] text-sm'>
                                    <p  className='ml-[100px]'>{item?.date}</p>
                                </div>

                                <button
                                    className='text-sm text-blue-400 flex justify-end gap-2 w-full items-center mr-4'
                                >
                                    <MdOutlineDownload className='text-blue-400 w-3' />
                                    {item?.download}
                                </button>
                            </div>
                        </div>
                    ))
                }
                <Flex gap={'30px'} textAlign={'center'} >
                    <Box border={'1px solid #BEBEBE'} borderRadius={'20px'} mt={'50px'} mb={'10px'} p={'15px'} w={'50%'} >
                        <HStack cursor="pointer" justifyContent={'center'}>
                        <Center>
                        <Text fontSize={'14px'} >Show 5 more documents</Text>
                        </Center>
                            <MdArrowDropDown className='h-4 w-auto ' />
                        </HStack>
                    </Box>

                    <Box border={'1px solid #BEBEBE'} borderRadius={'20px'} mt={'50px'} mb={'10px'} p={'15px'} w={'50%'} >
                        <HStack cursor="pointer" justifyContent={'center'}>
                            <Text fontSize={'14px'} >Show all 8 documents</Text>
                            <MdArrowDropDown className='h-4 w-auto ' />
                        </HStack>
                    </Box>
                </Flex>
                <div className='border-b flex mt-20'>
                    <p className='text-sm text-[#E8AC28] font-bold'>Links</p>
                    <p className='text-sm text-[#0405057C] ml-[430px] font-bold'>Date Uploaded</p>
                </div>
                {
                    list2.map(item => (
                        <div className='border-b w-[100%] p-1' key={item.id}>

                            <div className='flex w-[100%] justify-between '>
                                <div className=" bg-[#E8AC2823] flex justify-center items-center p-5 m-3">
                                    <PiLinkSimpleHorizontalBold size={20} color={'#E8AC28'} />
                                </div>
                                <div className='p-5 w-full block items-center'>
                                    <p className='text-[#0B82F8] font-bold text-sm underline'>{item?.link}</p>
                                </div>
                                <div className='w-full flex justify-center items-center text-[#040505] text-sm'>
                                    <p className='ml-[43px]'>{item?.dateUploaded}</p>
                                </div>

                                <button
                                    className='text-sm text-blue-400 flex justify-end gap-2 w-full items-center mr-4'
                                >
                                    <BsBoxArrowUpRight className='text-blue-400 w-3' />
                                    {item?.copyLink}
                                </button>
                            </div>
                        </div>
                    ))
                }
                <div className='border-b flex  mt-20'>
                    <p className='text-sm text-[#D40E1F] font-bold'>Videos</p>
                    <p className='text-sm text-[#0405057C] ml-[420px] font-bold'>Date Uploaded</p>
                </div>
                {
                    list3.map(item => (
                        <div className='border-b w-[100%] p-1' key={item.id}>

                            <div className='flex w-[100%] justify-between '>
                                <div className=" bg-[#D40E1F16] flex justify-center items-center p-5 m-3 ">
                                    <BiPlay size={20} color={'#D40E1F'} />
                                </div>
                                <div className='p-5 w-full space-y-2 block items-center'>
                                    <p className='text-[#0B82F8] font-bold '>{item?.link}</p>
                                    <div className='flex'>
                                        <BiTimeFive />
                                        <p className='text-[#040505] text-sm'>{item?.length}</p>
                                    </div>
                                </div>
                                <div className='w-full flex items-center text-[#040505] text-sm'>
                                    <p className='ml-[98px]'>{item?.dateUploaded}</p>
                                </div>

                                <button
                                    className='text-sm text-blue-400 flex justify-end gap-2 w-full items-center mr-4'
                                >
                                    <MdOutlineDownload className='text-blue-400 w-3' />
                                    {item?.video}
                                </button>
                            </div>
                        </div>
                    ))
                }

            </div>

        </div>
    )
}

export default CourseResources