import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { reportSchema } from "../utils/formValidationSchema";
import { fetchProgrammes, fetchSessions } from "../redux/slices/programmeSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses } from "../redux/slices/courseSlice";
import { fetchGeneralReport, fetchGradeReport } from "../redux/slices/reportSlice";
import toast from "react-hot-toast";

const Report = () => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableGrade, setTableGrade] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const sessions = useSelector((state) => state.programmes);
  const sessionList = sessions?.sessions?.data
  const programmes = useSelector((state) => state.programmes);

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch, sessions.sessionParams]);

  const initialValues = {
    category: '',
    sessionId: 'default',
    ProgrammeId: 'default',
    CourseId: 'default',
    StartDate: '',
    EndDate: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: reportSchema,
    validateOnMount: true,
    onSubmit: async (values, onSubmitProps) => {
      setTableData([]);
      setTableGrade([]);
      setStartDate(values.StartDate);
      setEndDate(values.EndDate);
      if(values.category === "Grades"){
        const res =  await dispatch(fetchGradeReport({
          StartDate: values.StartDate, 
          EndDate: values.EndDate, 
          ProgrammeId: values.ProgrammeId, 
          CourseId: values.CourseId})
        )
        if(res?.payload.length === 0){
          toast.success("No report found!")
        }else{
          setTableGrade(res?.payload)
        }
      }
      if(values.category === "General Report"){
        const res2 = await dispatch(fetchGeneralReport({
          startDate: values.StartDate, 
          endDate: values.EndDate,
        }))
        if(res2?.payload.length === 0){
          toast.success("No report found!")
        }else{
          setTableData(res2?.payload)
        }
      }
      // setLoading(true)
      // const res = await addCourseReview(formData);
      // if(res.status === true){
      //   toast.success(res.message, {duration: 10000}); 
      //   dispatch(fetchCourseReview(data))
      //   dispatch(setFormModal({status: false}));
      // }
    }
  })

  useEffect(() => {
    if(formik.values.category === "General Report"){
      setShow(false)
    }else{
      setShow(true)
    }
  },[formik.values.category])

  useEffect(() => {
    if(formik.values.sessionId)
    dispatch(fetchProgrammes(formik.values.sessionId))
  }, [programmes?.programmeParams, formik.values.sessionId, dispatch])

  const programmelist = programmes?.programmes?.data;
  const courses = useSelector((state) => state.courses);

  useEffect(() => {
    if(formik.values.ProgrammeId){
      dispatch(fetchCourses(formik.values.ProgrammeId))
    }
  }, [courses?.courseParam, formik.values.ProgrammeId, dispatch])

  const courseList = courses?.courses?.data;

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <PageTitle title={"Report"} />
        <form onSubmit={formik.handleSubmit}>
          <Box my={2} bg={"white"} w="full">
            <Text p={4} color={"#9361ea"} fontWeight={"bold"}>
              Select range to generate your report
            </Text>
            <Flex
              wrap={"wrap"}
              sx={{
                "&  > div": {
                  flex: ["100%", "1 1 calc(100% / 2)", "1 1 calc(100% / 3)"],
                  p: 4,
                  " select, input": { bg: "#E2E2E280", color: "#808181",  borderRadius: 0 },
                },
              }}
            >
              <Box>
                <FormLabel>Select Category</FormLabel>
                <Select 
                placeholder="Choose here"
                name="category"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                >
                  <option>Grades</option>
                  <option>General Report</option>
                  <option>Attendance</option>
                </Select>
                {formik.touched.category && formik.errors.category && (
                  <p className="text-sm text-red-500 mt-1">{formik.errors.category}</p>
                )}
              </Box>
              {
                show && <>
                  <Box>
                    <FormLabel>Session</FormLabel>
                    <Select 
                    name="sessionId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Choose here">
                      {
                        sessionList?.map(({id, year}) => (
                          <option key={id} value={id}>{year}</option>
                        ))
                      }
                    </Select>
                    {formik.touched.sessionId && formik.errors.sessionId && (
                      <p className="text-sm text-red-500 mt-1">{formik.errors.sessionId}</p>
                    )}
                  </Box>
                  <Box>
                    <FormLabel>Programme</FormLabel>
                    <Select 
                    name="ProgrammeId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Choose here">
                    {
                      programmelist?.map(({id, name}) => (
                        <option key={id} value={id}>{name}</option>
                      ))
                    }
                    </Select>
                    {formik.touched.ProgrammeId && formik.errors.ProgrammeId && (
                      <p className="text-sm text-red-500 mt-1">{formik.errors.ProgrammeId}</p>
                    )}
                  </Box>
                  <Box>
                    <FormLabel>Courses</FormLabel>
                    <Select 
                    name="CourseId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Choose here">
                      {
                      courseList?.map(({id, courseName}) => (
                        <option key={id} value={id}>{courseName}</option>
                      ))
                    }
                    </Select>
                    {formik.touched.CourseId && formik.errors.CourseId && (
                      <p className="text-sm text-red-500 mt-1">{formik.errors.CourseId}</p>
                    )}
                  </Box>
                </>
              }
              <Box flexGrow={"0 !important"}>
                <FormLabel>Start Date</FormLabel>
                <Input
                  as={"input"}
                  type="text"
                  name="StartDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Pick a Date"
                  onFocus={(e) => (e.target.type = "date")}
                />
                {formik.touched.StartDate && formik.errors.StartDate && (
                  <p className="text-sm text-red-500 mt-1">{formik.errors.StartDate}</p>
                )}
              </Box>
              <Box flexGrow={"0 !important"}>
                <FormLabel>End date</FormLabel>
                <Input
                  as={"input"}
                  type="text"
                  name="EndDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Pick a Date"
                  onFocus={(e) => (e.target.type = "date")}
                />
                {formik.touched.EndDate && formik.errors.EndDate && (
                  <p className="text-sm text-red-500 mt-1">{formik.errors.EndDate}</p>
                )}
              </Box>
            </Flex>
            
            <Flex justifyContent={"end"} p={4}>
            <Button
            w="12rem"
            my={4}
            borderRadius={"3px"}
            color={"white"}
            background={"#9361ea"}
            mr={3}
            _hover={{background: "#9361ea"}}
            onClick={handlePrint}
            >
                Print
              </Button>
              <Button
                type="submit"
                // bg={"#5CBF22"}
                background={"#9361ea"}
                _hover={{background: "#9361ea"}}
                color={"white"}
                w="13rem"
                borderRadius={"3px"}
                my={4}
              >
                Generate Report
              </Button>
             
            </Flex>
          </Box>
        </form>
        {
          tableData.length > 0 && 
          <TableContainer mt={10}>
            <Table variant='simple'>
              <TableCaption>{`REPORT FOR ${startDate} - ${endDate}`}</TableCaption>
              <Thead>
                <Tr>
                  <Th>Programme Name</Th>
                  <Th>Best Student</Th>
                  <Th>Highest Score</Th>
                  <Th >Total Students</Th>
                  <Th>Suspended Students</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  tableData.length >= 1 && 
                  tableData.map(({id, programmeName, highestPerformingStudent, highestScore, suspendedStudentCount, totalStudentCount}) => (
                    <Tr key={id}>
                      <Td>{programmeName}</Td>
                      <Td>{highestPerformingStudent}</Td>
                      <Td>{highestScore || 0}</Td>
                      <Td>{totalStudentCount}</Td>
                      <Td>{suspendedStudentCount}</Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        }

        {
          tableGrade.length > 0 && 
          <TableContainer>
            <Table variant='simple'>
              <TableCaption>{`REPORT FOR ${startDate} - ${endDate}`}</TableCaption>
              <Thead>
                <Tr>
                  <Th>Student Name</Th>
                  <Th>RegNumber</Th>
                  <Th>Programme</Th>
                  <Th>Course</Th>
                  <Th>Pass mark</Th>
                  <Th>Student grade</Th>
                  <Th>Grade Percentage</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  tableGrade.length >= 1 && tableGrade.map(({id, studentName, studentRegNum, programme, course, passMark, studentGrade, gradePercentage}) => (
                    <Tr key={id}>
                      <Td>{studentName}</Td>
                      <Td>{studentRegNum}</Td>
                      <Td>{programme}</Td>
                      <Td>{course}</Td>
                      <Td>{passMark}</Td>
                      <Td>{studentGrade}</Td>
                      <Td>{gradePercentage}</Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        }
    </>
  );
};

export default Report;
