import { Button, FormControl, FormLabel, Select, Textarea } from '@chakra-ui/react'
import React from 'react'

const FeedbackModal = () => {
  return (
    <div>
       <FormControl>
            <FormLabel fontSize={"small"}>Status</FormLabel>
            <Select bg={"gray.100"} fontSize={14} placeholder='Select option'>
            <option value='option1'>Passed</option>
            <option value='option2'>Failed</option>
            </Select>
        </FormControl>
        <FormControl mt={4}>
            <FormLabel fontSize={"small"}>Feedback</FormLabel>
            <Textarea placeholder='Enter Here' fontSize={"small"} bg={"gray.100"} />
        </FormControl> 
        <Button width={"100%"} mt={6} background={"#5CBF22"} _hover={{background: "#D40E1F"}} color={"whiteAlpha.900"}>
            Save
        </Button>
    </div>
  )
}

export default FeedbackModal