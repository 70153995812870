import { BiMinusCircle, BiPlus, BiPlusCircle } from "react-icons/bi";
import { numberToAlphabet } from "../../utils/func";
import { v4 as uuidv4 } from "uuid";

const DynamicInput = ({
  question,
  setQuestion,
  options,
  updateSavedOption,
  removeOption,
  choice,
  setChoice,
  correctAnswer,
  handleAnswer,
  optionsLength,
  addQuestion,
  updateOption
}) => {
  const uuid = uuidv4();
  return (
    <>
      <div className="mb-3">
        <h3 className="mb-1">Question</h3>
        <textarea
          placeholder="Type question here"
          className="p-2 h-32 border rounded w-full bg-gray-100 text-sm focus:ring-0 focus:outline-0"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          required
        />

        {options?.length > 0 && (
          <>
            {options?.map((option, index) => (
              <div className="flex items-center gap-x-2 mt-2">
                <div
                  className="w-full px-1.5 rounded-md text-nomral border border-[#d1d1d1]"
                  style={{
                    backgroundColor: option?.isCorrect
                      ? "#5CBF22"
                      : "transparent",
                    color: option?.isCorrect ? "#FFFFFF" : "#000000",
                  }}
                >
                  <div className="flex items-center">
                    <p className="p-3 border-r">
                      {numberToAlphabet(index + 1)}
                    </p>
                    <input
                      className={
                        "w-full ml-2 bg-white border-0 outline-0 text-sm sm:text-base text-slate-600 focus:outline-0 focus:ring-0"
                      }
                      type="text"
                      value={option?.answerText}
                      onChange={(e) => updateSavedOption(e, option?.choiceId)}
                      placeholder="Write an Option"
                      required
                      style={{
                        backgroundColor: option?.isCorrect
                          ? "#5CBF22"
                          : "transparent",
                        color: option?.isCorrect ? "#FFFFFF" : "#000000",
                      }}
                    />
                  </div>
                </div>
                <button>
                  <BiMinusCircle
                    className="h-5 w-auto text-gray-500"
                    onClick={() => removeOption(option?.choiceId)}
                  />
                </button>
              </div>
            ))}
          </>
        )}

        <form onSubmit={updateOption}>
          <div className="flex items-center gap-x-2 mt-2">
            <div className="w-full px-1.5 rounded-md text-nomral bg-white border border-[#d1d1d1]">
              <div className="flex items-center">
                <p className="p-3 border-r">
                  {numberToAlphabet(options?.length + 1)}
                </p>
                <input
                  className={
                    "w-full ml-2 bg-white border-0 outline-0 text-sm sm:text-base text-slate-600 focus:outline-0 focus:ring-0"
                  }
                  type="text"
                  value={choice?.answerText}
                  onChange={(e) =>
                    setChoice({
                      choiceId: uuid,
                      answerText: e.target.value,
                      isCorrect: false,
                    })
                  }
                  placeholder="Write an Option"
                  required
                />
              </div>
            </div>
            <button type="submit">
              <BiPlusCircle className="h-5 w-auto text-gray-500" />
            </button>
          </div>
        </form>

        {/* <button className="flex items-center gap-2 text-[#5CBF22] text-sm my-5">
        <BiPlus className="text-[#5CBF22] h-5" />
        Add option
      </button> */}

        <select className="mt-3 border p-3 rounded" onChange={handleAnswer}>
          <option value="" selected={correctAnswer === ""}>
            {options?.length > 0 ? "Choose Correct Answer" : "No Options"}
          </option>

          {options?.length > 0 && (
            <>
              {optionsLength?.map((index) => (
                <option
                  key={index}
                  value={index}
                  selected={correctAnswer === index}
                >
                  {numberToAlphabet(index)}
                </option>
              ))}
            </>
          )}
        </select>
      </div>

      <button
        className="w-full flex items-center gap-x-2 justify-center bg-primary p-3 rounded border bg-[#E8AC286B] font-semibold"
        onClick={addQuestion}
      >
        <BiPlus className="h-5 w-auto text-gray-500" />
        <span className="text-sm text-gray-600">Add Question</span>
      </button>
    </>
  );
};

export default DynamicInput;
