import { Box, Button, Flex, FormLabel, Input, Text } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";

const CreateEvent = () => {
  const dispatch = useDispatch();

  return (
    <Flex
      direction={"column"}
      gap={8}
      sx={{
        "& .chakra-form__label": { mb: 2, fontWeight: "bold" },
        "& .chakra-input": { bg: "#BEBEBE26", border: "none" },
      }}
      mt={6}
    >
      <Box>
        <FormLabel>Event Name</FormLabel>
        <Input placeholder="Enter Event Name" />
      </Box>
      <Flex direction={"column"}>
        <Flex gap={2}>
          <Box>
            <FormLabel>Date</FormLabel>
            <Input type="date" />
          </Box>
          <Box>
            <FormLabel>Time</FormLabel>
            <Input type="time" />
          </Box>
          <Box>
            <FormLabel>Duration</FormLabel>
            <Input />
          </Box>
        </Flex>
        <Text color="#E8AC28" fontSize={"sm"} my={2}>
          This event will take place on the 18th of may 2023 at 8:00am
        </Text>
      </Flex>
      <Box>
        <FormLabel>Host</FormLabel>
        <Input width={"17rem"} />
      </Box>
      <Box>
        <FormLabel>Online Link (Optional)</FormLabel>
        <Input />
      </Box>
      <Flex
        justify={"end"}
        gap={4}
        sx={{ "& > button": { flexBasis: "15rem" } }}
      >
        <Button
          border={"1px solid #9361ea"}
          bg={"white"}
          onClick={() =>
            dispatch(
              setFormModal({
                status: false,
              })
            )
          }
        >
          Cancel
        </Button>
        <Button bg={"#9361ea"}>Save Event</Button>
      </Flex>
    </Flex>
  );
};

export default CreateEvent;
