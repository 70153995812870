import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";


export const signIn = async (payload) => {
    return await httpRequest(`${BASE_URL}/tokens?tenant=root`, "post", payload);
};

  export const confirmEmail = async (tenant, userId, code) => {
    return await httpRequest(`${BASE_URL}/users/confirm-email?tenant=${tenant}&userId=${userId}&code=${code}`);
  };

  export const resetPassword = async (userId, password, tenant) => {
    return await httpRequest(`${BASE_URL}/users/change-password?id=${userId}&password=${password}&tenant=${tenant}`, "post");
  };

  export const forgotPassword = async (payload) => {
    return await httpRequest(`${BASE_URL}/users/forgot-password?tenant=${'root'}`, "post", payload);
  };