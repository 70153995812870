import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  courseDetails: null,
  loading: false,
  error: null,
};

export const fetchCourseDetail= createAsyncThunk(
  "coursedetail/coursedetail",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/course/${arg}`,
      "get"
    );
  }
);

const courseDetailSlice = createSlice({
  name: "coursedetail",
  initialState,
  extraReducers: (builder) => {

    builder.addCase(fetchCourseDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courseDetails = payload?.data;
    });
    builder.addCase(fetchCourseDetail.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default courseDetailSlice.reducer;
