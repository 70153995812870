import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  courseReview: null,
  loading: false,
  error: null,
  courseParams: {
    pageSize: 20,
    pageNumber: 1,
    isActive: true
  },
};

export const fetchCourseReview= createAsyncThunk(
  "coursereview/coursereview",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/coursereview/getcoursereviewsbycourseid`,
      "post",
      {...getState()?.courseReview?.courseParams, courseId : arg}
    );
  }
);

const courseReviewSlice = createSlice({
  name: "coursereview",
  initialState,
  extraReducers: (builder) => {

    builder.addCase(fetchCourseReview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseReview.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courseReview = payload?.data;
    });
    builder.addCase(fetchCourseReview.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default courseReviewSlice.reducer;
