import { useState } from "react";
import {  Flex, Stack, HStack } from "@chakra-ui/react";
import { updateIscorrect } from "../../utils/func";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, previousStep, updateQuizPayload } from "../../redux/slices/assessmentSlice";
import DynamicInput from "./DynamicInput";
import QuestionPreview from "./QuestionPreview";

const QuizAndAnswer = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [choice, setChoice] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");

  


  const uuid = uuidv4();
  const dispatch = useDispatch();

  const questionsPayload = useSelector(
    (state) => state.assesments.questionsPayload
  );

  console.log("questionsPayload", questionsPayload);

  const updateOption = (e) => {
    e.preventDefault();
    let opt = options;
    setOptions([...opt, choice]);
    setChoice({ choiceId: "", answerText: "", isCorrect: "" });
  };

  let choiceLength = choice?.answerText ? options?.length + 1 : options?.length;

  const optionsLength = Array.from(
    { length: choiceLength },
    (_, index) => index + 1
  );

  const removeOption = (optionId) => {
    const newOptions = options?.filter(
      (option) => option?.choiceId !== optionId
    );
    const renewedOption = updateIscorrect(newOptions);
    setOptions(renewedOption);
    setCorrectAnswer("");
  };

  const handleAnswer = (e) => {
    let theOptions = choice?.answerText
      ? [
          ...options,
          { choiceId: uuid, answerText: choice?.answerText, isCorrect: false },
        ]
      : options;
    setChoice({ choiceId: "", answerText: "", isCorrect: "" });
    let freshOptions = updateIscorrect(theOptions);
    freshOptions[e.target.value - 1].isCorrect = true;
    setOptions(freshOptions);
    setCorrectAnswer(e.target.value);
  };

  const updateSavedOption = () => {
    //
  };

  const addQuestion = () => {
    if (!question) {
      return alert("Question can't be blank");
    }
    if (options?.length < 1) {
      return alert("Add Options for candidate to select from");
    }

    if (!correctAnswer) {
      return alert("Kindly select the correct answer");
    }

    const payload = {
      questionId: uuid,
      questionText: question,
      answersPayloads: options,
    };

    dispatch(updateQuizPayload(payload));
    setQuestion("");
    setOptions("");
    setChoice("");
    setCorrectAnswer("");
  };

  return (
    <>
    <Stack spacing={6} maxW={"600px"} mb={10}>
      <Stack>
        <Stack>
          <QuestionPreview questionsPayload={questionsPayload} remove={true}/>
          <DynamicInput
            question={question}
            setQuestion={setQuestion}
            options={options}
            updateSavedOption={updateSavedOption}
            removeOption={removeOption}
            choice={choice}
            setChoice={setChoice}
            correctAnswer={correctAnswer}
            handleAnswer={handleAnswer}
            optionsLength={optionsLength}
            addQuestion={addQuestion}
            updateOption={updateOption}
          />
        </Stack>
      </Stack>
    </Stack>

    
   
    <Flex
        background="white"
        justifyContent="space-between"
        my={2}
        padding={2}
      >
        <span
          className="flex items-center gap-2 cursor-pointer"

        >

        </span>
        <HStack cursor="pointer">
          <button
            onClick={() => dispatch(previousStep())}
            className="text-[13px] font-semibold bg-[#E8AC286B] px-6 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Previous
          </button>
          <button
            onClick={() => dispatch(nextStep())}
            to="/upload-questions"
            className="text-[13px] font-semibold bg-[#5CBF22] w-[98px] px-3 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Next
          </button>
        </HStack>
      </Flex>
    </>
  );
};

export default QuizAndAnswer;
