import {useEffect, useState} from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Input, Button, Flex, Box, Text, HStack }from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateProgrammeKeyword, updateSessionKeyword } from "../../redux/slices/programmeSlice";
import { updateCoursesKeyword } from "../../redux/slices/courseSlice";
import { updateAllStudentsKeyword, updateKeyword } from "../../redux/slices/UsersSlice";
import { updateAnnouncementsKeyword } from "../../redux/slices/announcementSlice";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const SearchStrip = ({ search_placeholder, backBTN, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [keyword, setKeyword] = useState(null)

  useEffect(() => {
    if(keyword === '' && type === 'session'){
      dispatch(updateSessionKeyword(null))
    }
    if(keyword === '' && type === 'programme'){
      dispatch(updateProgrammeKeyword(null))
    }
    if(keyword === '' && type === 'courses'){
      dispatch(updateCoursesKeyword(null))
    }
    if(keyword === '' && type === 'users'){
      dispatch(updateKeyword(''))
    }
    if(keyword === '' && type === 'student'){
      dispatch(updateAllStudentsKeyword(''))
    }
    if(keyword === '' && type === 'announcement'){
      dispatch(updateAnnouncementsKeyword(''))
    }
  },[keyword, dispatch, type])

  const search = (e) => {
    console.log(keyword, type)
    e.preventDefault()
    if(type === "session" ) {
      dispatch(updateSessionKeyword(keyword))
    }
    if(type === "programme" ) {
      dispatch(updateProgrammeKeyword(keyword))
    }
    if(type === "courses" ) {
      dispatch(updateCoursesKeyword(keyword))
    }
    if(type === "users" ) {
      dispatch(updateKeyword(keyword))
    }
    if(type === "student") {
      dispatch(updateAllStudentsKeyword(keyword))
    }
    if(type === "announcement" ) {
      dispatch(updateAnnouncementsKeyword(keyword))
    }
  }
  return (
   
    <Flex bg={'white'} justifyContent={backBTN ? 'space-between' : 'flex-end'} alignItems={'center'} p={3} gap={4} >
    { backBTN &&
      <HStack cursor={'pointer'} onClick={() => navigate(-1)}>
          <AiOutlineArrowLeft style={{color: '#040505', width:'18px', height:'18px' }} />
          <Text color={'#040505'} fontSize={'14px'} letterSpacing={'0.29px'} >Back</Text>
      </HStack>
    }
    <Box >
      <form onSubmit={search} >
      <InputGroup border={"1px solid #BEBEBE"} borderRadius={"5px"}>
        <Input
          type="search"
          required
          borderRadius={"0"}
          border={"none"}
          focusBorderColor="#BEBEBE"
          color={"#BEBEBE"}
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
          placeholder={
            search_placeholder ? search_placeholder : "search ..."
          }
        />
        <Button
          className="bg-altasColor"
          // bg={"#5CBF22"}
          borderRadius={"3px"}
          _hover={{ backgroundColor: "red.500" }}
          type="submit"
        >
          <AiOutlineSearch
            style={{ color: "#FFFFFF", width: "18px", height: "18px" }}
          />
        </Button>
      </InputGroup>
      </form>
    </Box>
  </Flex>
 
  );
};

export default SearchStrip;
