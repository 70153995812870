import { useState } from "react";
import { Button, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import { addAcademicSession } from "../../services/ProgrammesService";
import { useDispatch } from "react-redux";
import { fetchSessions } from "../../redux/slices/programmeSlice";
import { setFormModal } from "../../redux/slices/modalSlice";
import toast from "react-hot-toast";

const AddAcademics = () => {
  const [pickyear, setpickYear] = useState("");
  const [highlightColor, setHighlightColor] = useState("#0B82F8");
  const [loading, setLoading] = useState(false);

  const currentYear = new Date().getFullYear().toString();
  const dispatch = useDispatch();

  const saveAcademicSession = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    let now = new Date(pickyear);
    const year = now.getFullYear().toString();

    const res = await addAcademicSession({ year, highlightColor });
    if (res?.status) {
      dispatch(fetchSessions());
      toast.success(res?.message, {duration: 10000})
    } else {
      toast.error(res?.message, {duration: 10000})
    }
    setLoading(false);
    dispatch(setFormModal({ status: false }));
  };

  return (
    <div>
      <FormControl>
        <FormLabel fontSize={"small"}>Choose Year</FormLabel>
        <ReactDatePicker
          selected={pickyear}
          onChange={(date) => setpickYear(date)}
          showYearPicker
          minDate={new Date(currentYear, 0, 1)}
          dateFormat="yyyy"
          placeholderText="Choose here"
          required
          form="academic-form"
        />
      </FormControl>
      <FormControl className="mt-4">
        <FormLabel fontSize={"small"}>Select Hightlight Color</FormLabel>
        <HStack>
          {/* The default icon size is 1em (16px) */}
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#0B82F8" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#0B82F8"
            onClick={() => setHighlightColor("#0B82F8")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#E8AC28" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#E8AC28"
            onClick={() => setHighlightColor("#E8AC28")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#44821F" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#44821F"
            onClick={() => setHighlightColor("#44821F")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#d40e1f" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#d40e1f"
            onClick={() => setHighlightColor("#d40e1f")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#df15e6" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#df15e6"
            onClick={() => setHighlightColor("#df15e6")}
          />
        </HStack>
      </FormControl>
      <form id="academic-form" 
      onSubmit={saveAcademicSession}
      >
        <Button
          type="submit"
          width={"100%"}
          // background={"#5CBF22"}
          background={"#9361ea"}
          _hover={{ background: "#D40E1F" }}
          color={"whiteAlpha.900"}
          mt={10}
          isLoading={loading}
          loadingText="Saving ..."
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default AddAcademics;
