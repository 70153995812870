import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/layout";
import { saveCsvData } from "../../redux/slices/bulkUploadSlice";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/alert";
import { Link } from "react-router-dom";
import { compareTwoArrays, sanitizeArray } from "../../utils/func";
import { Button } from "@chakra-ui/button";
import { bulkUploadProgramme } from "../../services/ProgrammesService";
import { fetchProgrammes } from "../../redux/slices/programmeSlice";
import { setFormModal } from "../../redux/slices/modalSlice";
import { bulkUploadStudent, bulkUploadUser } from "../../services/usersService";
import toast from "react-hot-toast";

const BulkUpload = ({ data }) => {
  const dispatch = useDispatch();
  const csvData = useSelector((state) => state.bulkUpload.csvData);
  const [validationError, setValidationError] = useState(null);
  const { uploadType, expectedCsvHeader, bulkProps } = data;
  const bulkData = useSelector((state) => state.bulkUpload.csvData);
  const [loading, setLoading] = useState(false);
  const [studentBase64, setStudentBase64] = useState('');

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setStudentBase64(reader.result);
    };
    reader.onError = (error) => {
      console.log('Error: ', error);
    };
  };
 
  const handleDrop = (acceptedFiles) => {
    setValidationError(null);
    const file = acceptedFiles[0];
    if (!file.name.endsWith(".csv")) {
      setValidationError("Only CSV files are allowed.");
      return;
    }
    getBase64(file);

    Papa.parse(file, {
      download: true,
      header: true,
      complete: (result) => {
        const data = sanitizeArray(result.data);
        console.log(data);
        if (data?.length < 2) {
          setValidationError("CSV file must have at least two Valid records.");
          return;
        }

        const dataHead = Object.keys(data[0]);
        const checkFileHead = compareTwoArrays(dataHead, expectedCsvHeader);
        if (checkFileHead) {
          dispatch(saveCsvData(data));
        } else {
          setValidationError(
            "File header do not match request. Download sample file for correct header"
          );
        }
      },
    });
  };

  const upload = async () => {
    setLoading(true);
    let res;
    if (uploadType === "programmes") {
      const programmeData = bulkData.map((obj) => ({
        ...obj,
        sessionId: bulkProps?.sessionId,
      }));
      console.log("programmeData", programmeData);
      res = await bulkUploadProgramme(programmeData);
      dispatch(fetchProgrammes(bulkProps?.sessionId));
    }

    if (uploadType === "users") {
      const userData = bulkData.map((obj) => ({
        ...obj,
        role: bulkProps?.role,
      }));
      console.log("userData", userData);
      res = await bulkUploadUser(userData);
    }

    if (uploadType === "student") {
      const formData = {
        "excelBase64": studentBase64
      }
      console.log("userData", formData);
      res = await bulkUploadStudent(formData);
    }

    if (Array.isArray(res)) {
      let count = res.filter((item) => item.status === true).length;
      toast.success(`${count} record(s) uploaded sucessfully`, {duration: 10000});
    } else {
      if (res?.status === true) {
        toast.success("Upload successful", {duration: 10000});
        dispatch(setFormModal({ status: false }));
      } else {
        toast.error("Upload failed. Please check CSV data", {duration: 10000});
      }
    }

    dispatch(setFormModal({status: false}))
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: ".csv",
    onDrop: handleDrop,
  });
  return (
    <>
      <div className="text-center mb-2">
        <Link
          to={require(`../../files/uploadSamples/${uploadType}.csv`)}
          className="text-blue-500 text-center"
          target="_blank"
        >
          Download Sample CSV
        </Link>
      </div>

      <Box
        className="dropzone text-center"
        {...getRootProps()}
        borderStyle={"dashed"}
        borderWidth="3px"
        borderColor="#FED7AA"
        p={5}
        bg="gray.200"
      >
        <input {...getInputProps()} />
        <p>Drag, Drop or select CSV file</p>
      </Box>
      {validationError && (
        <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{validationError}</AlertTitle>
        </Alert>
      )}

      {csvData?.length > 1 && (
        <div className="mt-3 text-center">
          <p>
            <strong>{csvData?.length} records are ready for bulk upload</strong>
          </p>

          <Button
            className="mt-3"
            // rightIcon={<ArrowForwardIcon />}
            onClick={upload}
            colorScheme="orange"
            isLoading={loading}
            loadingText="Processing..."
          >
            Proceed
          </Button>
        </div>
      )}
    </>
  );
};

export default BulkUpload;
