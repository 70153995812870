import { Flex, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { extendAssesmentAssessmentPayload, previousStep, updateAssesmentId } from "../../redux/slices/assessmentSlice";
import QuestionPreview from "./QuestionPreview";
import { calculateTimeDifference } from "../../utils/func";
import { setConfirmModal } from "../../redux/slices/modalSlice";
import { useParams } from "react-router-dom";

const Preview = () => {

  const dispatch = useDispatch();
  const assesments = useSelector((state) => state.assesments);
  let param = useParams();
  let courseId = param?.courseId;
  let assesmentId = param?.assesmentId;


  const submitAssesment = (isComplete) => {
    dispatch(extendAssesmentAssessmentPayload(isComplete))
    if(assesmentId) {
      updateAssesmentId(assesmentId)
    }
    dispatch(
      setConfirmModal({
        status: true,
        type: "assessment",
        buttonText: "YES, CONTINUE",
        desc: isComplete ? "Are you sure you want to publish this assesment?" : "Are you sure you want to save this assesmment as draft?",
        payload: courseId
      })
    );
  };


  return (
    <>
      <Stack maxWidth={"900px"} mb={10}>
        <Text fontWeight={"semibold"}>Instruction</Text>
        <Text fontSize={"sm"} color={"#707070"} className="mb-3">
         {assesments?.assessmentPayload?.instructions}
        </Text>

        <Flex justifyContent={"space-between"}>
          <Stack>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"#040505C7"}>
              Exam Date
            </Text>
            <Text fontSize={"sm"} color={"#0405057C"}>
            {assesments?.assessmentPayload?.examDate}
            </Text>
          </Stack>
          <Stack>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"#040505C7"}>
              Duration of exam
            </Text>
            <Text fontSize={"sm"} color={"#0405057C"}>
             {calculateTimeDifference(assesments?.assessmentPayload?.startTime, assesments?.assessmentPayload?.endTime)}
            </Text>
          </Stack>
          <Stack>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"#040505C7"}>
              Pass Mark
            </Text>
            <Text fontSize={"sm"} color={"#0405057C"}>
            {assesments?.assessmentPayload?.passMark}
            </Text>
          </Stack>
          <Stack>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"#040505C7"}>
              Number of question
            </Text>
            <Text fontSize={"sm"} color={"#0405057C"}>
              {assesments?.questionsPayload?.length}
            </Text>
          </Stack>
        </Flex>
        <Stack mt={6} spacing={8}>
          <QuestionPreview questionsPayload={assesments?.questionsPayload} />
        </Stack>
      </Stack>

      <Flex
        background="white"
        justifyContent="space-between"
        my={2}
        padding={2}
      >
            <button
            onClick={() => dispatch(previousStep())}
            className="text-[13px] font-semibold bg-[#E8AC286B] px-6 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Back
          </button>
        <HStack cursor="pointer">
          <button
            onClick={() => submitAssesment(false)}
            className="text-[13px] font-semibold bg-[#000]  px-6 py-1.5 text-white hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Save as draft
          </button>
          <button
            onClick={() => submitAssesment(true)}
            to="/upload-questions"
            className="text-[13px] font-semibold bg-[#5CBF22] w-[98px] px-3 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Publish
          </button>
        </HStack>
      </Flex>
    </>
  );
};

export default Preview;
