import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import StudentQuestions from "./StudentQuestions";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { useParams } from "react-router-dom";
import { postExamAnswers } from "../services/examService";
import toast from "react-hot-toast";
import CountdownTimer from "./CountDownTimer";
import { updateExamSubmitting } from "../redux/slices/assessmentSlice";

const AssesmentQuestions = ({ assessmentQuestion, title }) => {
  const dispatch = useDispatch();
  const query = useParams();
  const user = useSelector((state) => state?.auth?.user);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const currentQuestion = assessmentQuestion[currentQuestionIndex];

  const handleNextQuestion = () => {
    if (currentQuestionIndex < assessmentQuestion?.length - 1) {
      if (selectedAnswers[currentQuestionIndex] !== undefined) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        alert("Please select an option before moving to the next question.");
      }
    } else {
      if (Object.keys(selectedAnswers)?.length === assessmentQuestion?.length) {
        handleShowSelectedAnswers();
      } else {
        alert("Please answer all questions before submitting.");
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleAnswerSelection = (option, answerText, item) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [currentQuestionIndex]: {
        questionId: assessmentQuestion[currentQuestionIndex]?.questionId,
        answerText: answerText,
        item: item,
        option,
      },
    }));
  };

  const timeSpent = useSelector((state) => state.assesments.timeSpent);

  const handleShowSelectedAnswers = async () => {
    const answerPayload = Object.entries(selectedAnswers).map(
      ([questionIndex, { questionId, answerText }]) => {
        return {
          questionId: questionId,
          answerText,
        };
      }
    );
    const correctAnswersCount = Object.entries(selectedAnswers).filter(
      ([questionIndex, { questionId, option }]) => option === true
    );
    const formPayload = {
      questionAnswer: answerPayload,
      timeSpent,
      studentId: user?.id,
      courseId: query?.courseId,
    };

    dispatch(updateExamSubmitting(true));
    let res = await postExamAnswers(formPayload);
    if (res?.status === true) {
      toast.success(res.message, { duration: 10000 });
      dispatch(
        setFormModal({
          status: true,
          type: "assesment",
          data: {
            selectedAnswersRatio: correctAnswersCount?.length,
            length: assessmentQuestion.length,
          },
        })
      );
    }
    dispatch(updateExamSubmitting(false));
    // const selectedAnswerEntries = Object.entries(selectedAnswers).map(([questionIndex, { option, answerText }]) =>
    //   `Question ${parseInt(questionIndex, 10) + 1}: Option ${option}, Answer: ${answerText}`
    // );
    // console.log('Selected Answers:\n' + selectedAnswerEntries.join('\n'));
  };

  // const getSelectedAnswersRatio = () => {
  //     const selectedAnswersCount = Object.keys(selectedAnswers).length;
  //     return selectedAnswersCount;
  // };
  // const selectedAnswersRatio = getSelectedAnswersRatio();

  const questionProps = {
    currentQuestionIndex,
    currentQuestion,
    handleAnswerSelection,
    selectedAnswers,
    handlePreviousQuestion,
    assessmentQuestion,
    handleNextQuestion,
    title,
  };

  return (
    <>
      <Box
        mb={"30px"}
        bg={"#F3FBEF"}
        borderRadius={"20px 0px 28px 0px"}
        py={"30px"}
        px={"30px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <p className="font-semibold text-2xl text-slate-800">{title}</p>
        <CountdownTimer />
      </Box>
      <StudentQuestions {...questionProps} />
    </>
  );
};

export default AssesmentQuestions;
