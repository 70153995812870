import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";


export const addAnnouncement = async (payload) => {
    return await httpRequest(`${BASE_URL}/announcements`, "post", payload);
  };

  export const updateAnnouncement = async (payload) => {
    return await httpRequest(`${BASE_URL}/announcements/${payload.id}`, "put", payload);
  };

  export const deleteAnnouncement = async (payload) => {
    return await httpRequest(`${BASE_URL}/announcements/${payload}`, "delete");
  };