import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Box,
  Flex
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { liveClassSchema } from "../../utils/formValidationSchema";
import { useState } from "react";
import { setFormModal } from "../../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { scheduleLiveClass, updateLiveClass } from "../../services/courseService";
import { formatTime, simpleDateString } from "../../utils/func";
import { fetchLiveClass } from "../../redux/slices/LiveClassSlice";

const CreateLiveClass = ({data}) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state?.auth?.user);

  console.log(data);
  
  const initialValues = {
    topic: data?.topic,
    description: data?.description,
    scheduleDate: data?.scheduleDate,
    scheduleTime: data?.scheduleTime,
    endTime: data?.endTime,
  };

  const handleSubmit = async (values) => {
    values.scheduleTime = formatTime(values.scheduleTime);
    values.endTime = formatTime(values.endTime)
    let res;
    setLoading(true)
    if(data.id){
      values.id = data.id
      res = await (updateLiveClass(values))
    }else{
      values.courseId = data.courseId
      values.lecturerId = user?.id
      res = await scheduleLiveClass(values)
    }
  
    console.log("res", res);
    if(res?.status === true) {
      dispatch(fetchLiveClass(data?.courseId))
      toast.success(res?.message, {duration: 10000})
    }else {
      toast.error(res?.data?.messages[0], {duration: 10000})
    }
    setLoading(false)
    dispatch(setFormModal({ status: false }));
  };


  return (
    <Flex
      direction={"column"}
      gap={8}
      sx={{
        "& .chakra-form__label": { mb: 2, fontWeight: "bold" },
        "& .chakra-input": { bg: "#BEBEBE26", border: "none" },
      }}
      mt={6}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={liveClassSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>
            <Box>
            <FormControl  isInvalid={errors.topic && touched.topic} color="black">
              <FormLabel>Topic</FormLabel>
              <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="topic"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.topic}
            />
                {touched.topic && errors.topic && (
              <FormErrorMessage>{errors.topic}</FormErrorMessage>
            )}
              </FormControl>
              {/* <Flex
                gap={1}
                color="#0B82F8"
                align={"center"}
                fontWeight={"bold"}
              >
                <BiPlus size={".7rem"} />
                <Text fontSize={"sm"} my={2}>
                  Add Description
                </Text>
              </Flex> */}
            </Box>
            <FormControl
              mt={4}
              isInvalid={errors.description && touched.description}
            >
              <FormLabel fontSize={"small"}>Description</FormLabel>
              <Textarea
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="description"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.description}
              />
              {touched.description && errors.description && (
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              )}
            </FormControl>
            <Flex gap={2}>
              <Box flexGrow={1}>
              <FormControl
                mt={4}
                isInvalid={errors.scheduleDate && touched.scheduleDate}
              >
                <FormLabel fontSize={"small"}>Scheduled Date</FormLabel>
                <Input
                  type="date"
                  placeholder="Choose Date"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="scheduleDate"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={simpleDateString(values?.scheduleDate)}
                />
                {touched.scheduleDate && errors.scheduleDate && (
                  <FormErrorMessage>{errors.scheduleDate}</FormErrorMessage>
                )}
              </FormControl>
              </Box>
              <Box flexGrow={1}>
              <FormControl
                mt={4}
                isInvalid={errors.scheduleTime && touched.description}
              >
                <FormLabel fontSize={"small"}>Start Time</FormLabel>
                <Input
                  type="time"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="scheduleTime"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.scheduleTime}
                />
                {touched.scheduleTime && errors.scheduleTime && (
                  <FormErrorMessage>{errors.scheduleTime}</FormErrorMessage>
                )}
              </FormControl>
              </Box>
            </Flex>
            <Box width={["100%", "100%", "50%"]} flexGrow={0} className="mt-4">
            <FormControl  isInvalid={errors.endTime && touched.endTime} color="black">
              <FormLabel>End Time</FormLabel>
              <Input
                  type="time"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="endTime"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.endTime}
                />
                {touched.endTime && errors.endTime && (
              <FormErrorMessage>{errors.endTime}</FormErrorMessage>
            )}
              </FormControl>
            </Box>

            <Button
            type="submit"
              mt={10}
              alignSelf={"center"}
              width={"10rem"}
              bg={"#E8AC286C"}
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default CreateLiveClass;
