import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const postExamAnswers = async (payload) => {
  console.log("payloaddddddddddddd", payload);
  return await httpRequest(
    `${BASE_URL}/exam/submitexam?courseId=${payload.courseId}&studentId=${payload.studentId}&timeSpent=${payload.timeSpent}`,
    "post",
    payload
  );
};

// export const postExamAnswers = createAsyncThunk(
//   "exam/submit",
//   async (arg, { getState }) => {
//     console.log('what is the arg again', arg)
//     return httpRequest(
//       `${BASE_URL}/exam/submit?courseId=${arg.courseId}&studentId=${arg.studentId}&timeSpent=${arg.timeSpent}`,
//       "post"
//     );
//   }
// );
