import React from "react";
import Sidebar from "./Sidebar";
import { Flex, useDisclosure } from "@chakra-ui/react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import MobileSidebar from "./MobileSidebar";
import MobileHeader from "./MobileHeader";

const PageWrapper = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex position={"relative"} minH={"100vh"}>
      {/* <BreadCrumbs breadcrumb={breadcrumb} /> */}
      <Flex
        w={[0, 0, "15%", "15%"]}
        position={"fixed"}
        top={0}
        zIndex={10}
        h={"100vh"}
        display={["none", "none", "none", "flex"]}
      >
        <Sidebar />
      </Flex>
      <MobileSidebar isOpen={isOpen} onClose={onClose} />

      <Flex
        direction={"column"}
        grow={1}
        pl={[0, 0, 0, "15%"]}
        maxW={["100%", "100%", "100%", "100%"]}
      >
        <MobileHeader onOpen={onOpen} />
        <Header />

        <Flex
          px={[2, 2, 4, 4]}
          py={2}
          bg={"#F4F3F3"}
          grow={"1"}
          direction={"column"}
        >
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageWrapper;
