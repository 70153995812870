import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaUpload } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";

const PageHeaderStrip = ({
  headerTitle,
  headerTitleColor,
  otherBtn,
  otherBtnText,
  otherBtnAction,
  expectedCsvHeader,
  uploadType,
  bulkProps,
}) => {
  const dispatch = useDispatch();


  return (
    <div className="bg-white px-3 py-2 mt-1.5 font-bold flex justify-between">
      <h3 style={{color: headerTitleColor? headerTitleColor: ""}}>{headerTitle}</h3>
      <div className="flex gap-3">
        {expectedCsvHeader && uploadType && (
          <button
            className="text-xs font-semibold border px-3 py-1.5 bg-orange-200 text-black flex items-center gap-x-1.5"
            onClick={() =>
              dispatch(
                setFormModal({
                  status: true,
                  title: "Bulk Upload",
                  type: "bulkUpload",
                  data: {
                    expectedCsvHeader: expectedCsvHeader,
                    uploadType: uploadType,
                    bulkProps: bulkProps
                  },  
                })
              )
            }
          >
            <FaUpload />
            Bulk Upload
          </button>
        )}

        {otherBtn && (
          <button
            className="text-xs font-semibold border px-3 py-1.5 bg-[#fabe25] text-orange hover:bg-red-500 hover:text-white flex items-center gap-x-1.5"
            onClick={otherBtnAction}
          >
            <AiOutlinePlusCircle />
            {otherBtnText}
          </button>
        )}
      </div>
    </div>
  );
};

export default PageHeaderStrip;
