import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AssesmentQuestions from "./AssesmentQuestions";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessementDetail } from "../redux/slices/assessmentByCourseSlice";

const AsessmentTestTab = ({ assessment }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [preview, showPreview] = useState(true);
  const assessmentDetails = useSelector((state) => state?.assessmentByCourse);
  console.log("here is the assessmentDetails o", assessmentDetails);
  const timeSpent = useSelector((state) => state?.assessment?.timeSpent);
  console.log("here is time spent", timeSpent);

  useEffect(() => {
    dispatch(
      fetchAssessementDetail({ id: assessment?.id, studentId: user?.id })
    );
  }, [assessment?.id, dispatch, user?.id]);

  const assesssmentInfo = assessmentDetails?.assessmentDetail;

  return (
    <>
      {preview ? (
        <>
          <Box
            mb={"30px"}
            bg={"#F3FBEF"}
            borderRadius={"20px 0px 28px 0px"}
            py={"30px"}
            px={"30px"}
          >
            <p className="font-semibold text-2xl text-slate-800">
              {assessment?.title}
            </p>
          </Box>
          <Flex
            borderBottom={"1px solid #F2F2F2"}
            justifyContent={"space-between"}
          >
            <h3 className="text-[#040505] font-bold">Test Assessment</h3>
            <div className="flex gap-2">
              <p className="text-[#E8AC28]">Course code:</p>
              <p className="text-[#0405057C]">MBA310</p>
            </div>
          </Flex>
          <div className="">
            <p className="mb-4 text-lg font-light text-gray-500 leading-6 mt-7">
              Read the questions carefully and confine your responses to an
              analysis of the questions as written. Do not assume any facts not
              set forth in the questions.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 leading-6">
              {assessment?.instructions}
            </p>

            <div className="mt-3">
              {/* <div className=''>
                                <div className='flex gap-[15px] items-center '>
                                    <p className='text-[#E8AC28] font-medium'>Start Date:</p>
                                    <p className='text-gray-600 font-medium'>{renderDate}</p>
                                </div>
                            </div> */}
              <div className="">
                <div className="flex gap-[15px] items-center ">
                  <p className="text-[#E8AC28] font-medium">Start Time:</p>
                  <p className="text-gray-600 font-medium">
                    {assessment?.startTime}
                  </p>
                </div>
              </div>
              {/* <div className='mt-3'>
                                <div className='flex gap-[15px] items-center '>
                                    <p className='text-[#E8AC28] font-medium'>Finish Date:</p>
                                    <p className='text-gray-600 font-medium'>{renderDate}</p>
                                </div>
                            </div> */}
              <div className="">
                <div className="flex gap-[15px] items-center ">
                  <p className="font-normal text-[#E8AC28] ">Finish Time:</p>
                  <p className="text-gray-600 font-medium">
                    {assessment?.endTime}
                  </p>
                </div>
              </div>
              <div className="">
                <div className="flex gap-[15px] items-center ">
                  <p className="text-[#E8AC28] font-medium">Pass Mark:</p>
                  <p className="text-gray-600 font-medium">
                    {assessment?.passMark}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => showPreview(false)}
            className="bg-[#E8AC286C] hover:bg-[#5CBF22] hover:text-white mt-20 p-4 w-[30%] rounded-md text-[#040505]"
          >
            Start Test
          </button>
        </>
      ) : (
        <AssesmentQuestions
          title={assessment?.title}
          assessmentQuestion={assesssmentInfo}
        />
      )}
    </>
  );
};

export default AsessmentTestTab;
