import {useEffect} from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import {RiAlarmWarningFill} from 'react-icons/ri';
import CustomTable2 from '../components/CustomTable2';
import { useNavigate } from 'react-router-dom';
import { fetchStudentsByCourse, updateStudentsPagination } from '../redux/slices/studentSslice';
import { useDispatch, useSelector } from 'react-redux';
import { simpleDateString } from '../utils/func';
import ResponsivePagination from "react-responsive-pagination";

const StudentsEnrolled = ({courseId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const students = useSelector((state) => state.students);
  const studentList = students?.students?.data;

  useEffect(() => {
   dispatch(fetchStudentsByCourse(courseId))
  }, [dispatch, courseId,students?.params])
 
  const tableProps = {
      data: studentList,
      loading: students?.loading,
      title: {
        studentName: "student Name",
        regNumber: "Student Number",
        email: "Email",
        course: "Course",
        dateEnrolled: "Date Enrolled",
    
      },
      dataKeys: [
        "studentName",
        "regNumber",
        "email",
        "dateEnrolled",
    
      ],
      search:false,
      headerStrip:false,
      transformRow: (item) => ({
        studentName: `${item?.firstName} ${item?.lastName}`,
        regNumber: item.regNumber,
        email: item.email,
        dateEnrolled: simpleDateString(item.dateEnrolled),
        // addCourse: (
        //   <Text fontWeight={'bold'} fontSize={'18px'}>...</Text>
        // ),
      }),
  };

  return (

    <Box mb={'20px'}>
        <Flex bg={'red.50'} direction={['column', 'row', 'row']} justifyContent={'space-between'} alignItems={'center'} p={'20px'} mt={'10px'} mb={'20px'} gap={['20px', null, null]}  >
            <Button size={'sm'} leftIcon={<RiAlarmWarningFill style={{color:'#E53E3E', width:'21px', height:'21px'}} />}
                fontSize={'16px'} letterSpacing={'0.32px'} color={' #040505'} fontWeight={'normal'} bg={''} _hover={{bgColor:''}} 
            >
            Reach out to students
            </Button>
            <Button size={'sm'} color={'red.400'} letterSpacing={'0.25px'} fontSize={'14px'} fontWeight={'600'} bg={'#FFFFFF'}
                border={'1px solid #E53E3E'} _hover={{bgColor:''}} borderRadius={'0'}
                onClick={() => navigate(`/course-management/email-reminder/${courseId}`) }
            >
             Send email reminder to student
            </Button>
        </Flex>

        <CustomTable2 {...tableProps} />
        <div className="mt-5">
        <ResponsivePagination
          current={students.students?.pageNumber}
          total={students?.students?.totalPages}
          onPageChange={(page) => dispatch(updateStudentsPagination(page))}
        />
      </div>

    </Box>
    
  )
}

export default StudentsEnrolled;