import React from 'react';
import { Box, HStack, Text, Flex } from '@chakra-ui/react';
import EmailTemplate from '../components/layout/EmailTemplate';
import PageTitle from '../components/PageTitle';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';


const CreateNewEmail = () => {
    const navigate = useNavigate();

    const params = useParams()
    const courseId = params?.courseId

    // console.log("kdkdk", courseId);

    const templateProps = {
        headText: 'Create new email template',
        inputLabel: 'Title',
        btnText:'Save template',
        isSelect: false,
        headTextColor:'#5CBF22',
        courseId
    }

  return (
    <>
      <PageTitle title='Email' />
      <Flex background="white" justifyContent="space-between" mt={2} mb={5} py={3} px={5}>
        <HStack cursor={'pointer'} onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft />
            <Text color={'#374B43'} fontSize={'14px'} >Back</Text>
        </HStack>
      </Flex>
    
      <Box px={[3,6,10]}>
        <EmailTemplate {...templateProps} />
      </Box>
    </>
  )
}

export default CreateNewEmail;