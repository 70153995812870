import { useEffect, useState } from "react";
import CustomTable2 from "./CustomTable2";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAssessment } from "../redux/slices/assessmentSlice";
import { dateToAgoFormat } from "../utils/func";
import { assesmentCategory, assesmentType } from "../data/mock";
import { fetchLecturerCourseAssesments } from "../redux/slices/lecturerAssesmentSlice";
import ViewSubmissions from "../modals/ViewSubmissionsModal";
import { Modal } from "@chakra-ui/react";

const ManageAsssement = ({ courseId }) => {
  const [viewSubmission, setViewSubmission] = useState(false);
  const dispatch = useDispatch();
  const assesments = useSelector((state) => state.assesments);

  useEffect(() => {
    dispatch(fetchLecturerCourseAssesments(courseId));
  }, [courseId, dispatch]);

  const navigate = useNavigate();

  const uploadQuestions = () => {
    dispatch(resetAssessment());
    navigate(`/upload-questions/${courseId}`);
  };

  const handleViewSubmissions = () => {
    setViewSubmission(true);
  };

  const handleCloseModal = () => {
    setViewSubmission(false);
  };

  const tablePublishProps = {
    data: assesments?.assesments?.data,
    title: {
      title: "Title",
      category: "Category",
      assessmentType: "Type",
      status: "Status",
      action: "",
    },
    dataKeys: ["title", "category", "assessmentType", "status", "action"],
    otherBtn: true,
    otherBtnText: "Upload Question",
    otherBtnAction: uploadQuestions,
    headerTitle: "",
    uploadType: "users",
    search: false,
    transformRow: (item) => ({
      title: (
        <div>
          <p className="text-base">{item.title}</p>
          <span className="text-[#0405057C] text-xs">
            Uploaded {dateToAgoFormat(item?.createdTime)}
          </span>
        </div>
      ),
      category: assesmentCategory[item.category]?.value,
      assessmentType: assesmentType[item.assessmentType]?.value,
      status: (
        <span
          className={
            item.stringStatus === "Completed"
              ? "text-green-500"
              : item.stringStatus === "Ongoing"
              ? "text-gray-500"
              : "text-orange-500"
          }
        >
          {item.stringStatus}
        </span>
      ),
      action: (
        <>
          {item.stringStatus === "Unpublished" ||
          item.stringStatus === "Upcoming" ? (
            <Link
              className="text-blue-500 underline"
              to={`/continue-upload-questions/${courseId}/${item?.id}`}
            >
              Continue Edit
            </Link>
          ) : item.stringStatus === "Ongoing" || "Completed" ? (
            <Link
              className="text-blue-500 underline"
              onClick={handleViewSubmissions}
            >
              View Submissions
            </Link>
          ) : (
            <Link
              className="text-blue-500 underline"
              to={`/continue-upload-questions/${courseId}/${item?.id}`}
            >
              Continue Edit
            </Link>
          )}
        </>
      ),
    }),
  };

  return (
    <>
      <CustomTable2 {...tablePublishProps} />
      <Modal isOpen={viewSubmission} onClose={handleCloseModal}>
        <ViewSubmissions
          courseId={courseId}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      {/* { showPublish ? 
    <Box>
        <Flex justifyContent={"flex-end"}>
            <Link to="/upload-questions" className='text-[13px] font-semibold bg-[#E8AC286B] px-3 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md'>
                Upload Questions
            </Link>
        </Flex>
        <CustomTable2 {...tablePublishProps}/>
    </Box> : 
    <Box>
        <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
            <Stack spacing={"-0.5"}>
                <Text fontSize={"2xl"} fontWeight={"semibold"}>Introduction to Mathematics</Text>
                <Text color={"#0405057C"} fontSize={"sm"} fontWeight={"semibold"}>Theory</Text>
                <Text color={"#0405057C"} fontSize={"sm"} fontWeight={"semibold"}>20 questions</Text>
            </Stack>
            <HStack>
                <Button fontSize={"13px"} size={"sm"} color={"#0405057C"} background={"none"} border={"1px solid #BEBEBE80"}>
                    <BiTime className='mr-1 text-[#5CBF22] text-lg' />
                    4 Hours
                </Button>
                <Button onClick={() => setPublish(true)} fontSize={"13px"} size={"sm"} background='#E8AC286B' width={"98px"} color={"white"} _hover = {{ background: "#D40E1F"}}>
                    Publish
                </Button>
            </HStack>
        </HStack>
        <Grid marginTop={"1rem"} templateColumns='repeat(3, 1fr)' gap={6}>
            <GridItem paddingX={"2rem"} paddingY={"1rem"} borderRadius={"5px"} border={"1px solid #BEBEBE80"}>
                <Flex>
                    <Box padding={"6px"} paddingX={"8px"} marginRight={"14px"} borderRadius={"50%"} background={"#F3FBEF"}>
                        <BsFillPersonFill className='mt-1 text-[#5CBF22] text-xl' />
                    </Box>
                    <Box>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>Total Students</Text>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>30</Text>
                    </Box>
                </Flex>
            </GridItem>
            <GridItem paddingX={"2rem"} paddingY={"1rem"} borderRadius={"5px"} border={"1px solid #BEBEBE80"}>
                <Flex>
                    <Box padding={"6px"} paddingX={"8px"} marginRight={"14px"} borderRadius={"50%"} background={"#E8AC2823"}>
                        <TiTick className='mt-1 text-[#E8AC28] text-xl' />
                    </Box>
                    <Box>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>Total Present Students</Text>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>10</Text>
                    </Box>
                </Flex>
            </GridItem>
            <GridItem paddingX={"2rem"} paddingY={"1rem"} borderRadius={"5px"} border={"1px solid #BEBEBE80"}>
                <Flex>
                    <Box padding={"6px"} paddingX={"8px"} marginRight={"14px"} borderRadius={"50%"} background={"#D40E1F16"}>
                        <FaExclamation className='mt-1 text-[#D40E1F] text-lg' />
                    </Box>
                    <Box>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>Total Absent Students</Text>
                        <Text color={"#0405057C"} fontWeight={"semibold"}>3</Text>
                    </Box>
                </Flex>
            </GridItem>
        </Grid>
        <CustomTable2 {...tableProps} />
    </Box>
    } */}
    </>
  );
};

export default ManageAsssement;
