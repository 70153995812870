import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PageWrapper from "./layout/PageWrapper";
import PageNotFound from "../screens/404";


const ProtectedRoute = ({roles}) => {
  const user = useSelector((state) => state?.auth?.user);

  if (!user?.token) {
    return <Navigate to="/" replace />;
  }

  // if(user?.firstLogin){
  //   return <div>Change Password</div>
  // }

  if(!roles?.includes(user?.role)) {
    return (
    
      <PageNotFound 
      errorCode="419" 
      title="You shouldn't be here" 
      desc="You are not authorize or do not have sufficient permission to view this resource."/>
      
    )
}

  return <PageWrapper><Outlet /></PageWrapper>;
};

export default ProtectedRoute;
