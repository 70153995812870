import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import CustomTable2 from "../components/CustomTable2";
import { Flex, Text, Modal } from "@chakra-ui/react";
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseByStudent } from "../redux/slices/studentCourseSlice";
import ViewGradesModal from "../modals/ViewGradesModal";

const StudentGrades = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state?.studentCourse);
  const [viewGrades, setViewGrades] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  useEffect(() => {
    dispatch(fetchCourseByStudent(user?.id));
  }, [data?.params, dispatch, user?.id]);

  const courselist = data?.studentCourse?.data;
  console.log("here is the courseid", courselist);
  const handleViewGrades = (courseId) => {
    setSelectedCourseId(courseId);
    setViewGrades(true);
  };

  const handleCloseModal = () => {
    setViewGrades(false);
  };

  const tableProps = {
    data: courselist,
    title: {
      code: "code",
      courseName: "courseName",
      unit: "unit",
      viewGrades: "",
    },
    dataKeys: ["code", "courseName", "unit", "viewGrades"],
    headerTitle: "All Courses (17)",
    otherBtn: false,
    transformRow: (item) => ({
      code: item.code,
      courseName: (
        <Flex gap={2}>
          <img
            src={`http://efccscholarroom.vatebra.org/${item.imagePath}`}
            className="w-8 h-auto"
            alt=""
          />
          <span>{item.courseName}</span>
        </Flex>
      ),
      unit: item.unit,
      viewGrades: (
        <Text
          color={"#0B82F8"}
          letterSpacing={"0.32px"}
          cursor={"pointer"}
          // onClick={() => navigate(`/grade/subject/${item.id}`)}
          onClick={() => handleViewGrades(item.id)}
        >
          View Grades
        </Text>
      ),
    }),
  };

  return (
    <>
      <PageTitle title="Grades" />
      <CustomTable2 {...tableProps} />
      <Modal isOpen={viewGrades} onClose={handleCloseModal}>
        <ViewGradesModal
          courseId={selectedCourseId}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default StudentGrades;
