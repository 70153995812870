import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const addBookToLibrary = async (payload) => {
  return await httpRequest(`${BASE_URL}/librarymaterial`, "post", payload);
};

export const removeBookFromLibrary = async (payload) => {
  return await httpRequest(`${BASE_URL}/librarymaterial/delete-multiple`, "delete", payload);
};
