import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthWrapper from "../components/layout/AuthWrapper";
import LoginForm from "../components/forms/LoginForm";

const Login = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.token) {
      return navigate("/dashboard");
    }
  }, [auth?.user?.token, navigate]);

  return (
    <>
      <AuthWrapper title="Login">
        <LoginForm />
      </AuthWrapper>
    </>
  );
};

export default Login;
