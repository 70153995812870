import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ActiveCourses from './ActiveCourses'
import AllCourses from './AllCourses'
import CompletedCourses from './CompletedCourses'
import { fetchCourseByStudent } from '../redux/slices/studentCourseSlice'
import { fetchCourseStudentByStatus } from '../redux/slices/courseByStatusSlice'

const StudentCourseTab = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state?.studentCourse);
    const activeCourses = useSelector((state) => state.courseStatus);
    const completedCourses = useSelector((state) => state.courseStatus);

    useEffect(() => {
      dispatch(fetchCourseByStudent(user?.id))
      dispatch(fetchCourseStudentByStatus({id : user?.id, status: true}))
      dispatch(fetchCourseStudentByStatus({id : user?.id, status: false}))
    }, [data?.params, activeCourses?.params, dispatch, user?.id])

    const courseLists = data?.studentCourse;
    const activeCoursesList = activeCourses?.courses;
    const completedCoursesList = completedCourses?.courses;

  return (
    <div className='rounded-xl border'>
        <Tabs>
            <div className='border-b flex justify-between'>
                <TabList >
                    <Tab _selected={{ color: 'green.400', borderBottom: "2px solid green" }}>All</Tab>
                    <Tab _selected={{ color: 'green.400', borderBottom: "2px solid green"  }}>Active</Tab>
                    <Tab _selected={{ color: 'green.400', borderBottom: "2px solid green"  }}>Completed</Tab>
                </TabList>
            </div>
            <TabPanels paddingY={"20px"}>
                <TabPanel>
                    <AllCourses 
                    styling={'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-8'}
                    courseLists={courseLists}
                    data={data}
                    />
                </TabPanel>
                <TabPanel>
                    <ActiveCourses 
                    styling={'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-8'}
                    courseLists={activeCoursesList}
                    data={activeCourses}
                    />
                </TabPanel>
                <TabPanel>
                    <CompletedCourses 
                    styling={'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-8'}
                    courseLists={completedCoursesList}
                    data={completedCourses}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default StudentCourseTab