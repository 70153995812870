import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  file: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: ""
  },
};

export const fetchStudentFiles = createAsyncThunk(
  "module/module",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/studentfile/getfilesbystudentid`,
      "post",
      {...getState()?.module?.params, courseId: arg}
    );
  }
);

const fileSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    updateFilePagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateFileKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //Student Files
    builder.addCase(fetchStudentFiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStudentFiles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.file = payload?.data;
    });
    builder.addCase(fetchStudentFiles.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default fileSlice.reducer;
export const { updateFileKeyword, updateFilePagination } =
  fileSlice.actions;
