import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  sessions: null,
  programmes: [],
  loading: false,
  error: null,
  sessionParams: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
  programmeParams: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchSessions = createAsyncThunk("sessions/sessions", async (arg, { getState }) => {
  return httpRequest(`${BASE_URL}/sessions/getall`, "post", getState()?.programmes?.sessionParams);
});

export const fetchProgrammes = createAsyncThunk(
  "programmes/programmes",
  async (arg, { getState }) => {
    return httpRequest(`${BASE_URL}/programme/getprogrammebysessionid`, "post",{...getState()?.programmes?.programmeParams, id: arg});
  }
);

const programmeSlice = createSlice({
  name: "programmes",
  initialState,
  reducers: {
    updateSessionPagination: (state, {payload}) => {
      state.sessionParams.pageNumber = payload;
      state.loading = true
    },
    updateProgrammePagination: (state, {payload}) => {
      state.programmeParams.pageNumber = payload;
      state.loading = true
    },
    updateSessionKeyword: (state, {payload}) => {
      state.sessionParams.keyword = payload;
    },
    updateProgrammeKeyword: (state, {payload}) => {
      state.programmeParams.keyword = payload;
    },
    clearProgrammes: (state) => {
      state.programmes = null;
    },
  },
  extraReducers: (builder) => {
    //sessions
    builder.addCase(fetchSessions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSessions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sessions = payload?.data;
    });
    builder.addCase(fetchSessions.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //Programmes
    builder.addCase(fetchProgrammes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProgrammes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.programmes = payload?.data;
    });
    builder.addCase(fetchProgrammes.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

  },
});

export default programmeSlice.reducer;
export const {
  updateSessionPagination,
  updateProgrammePagination,
  updateSessionKeyword,
  updateProgrammeKeyword,
  clearProgrammes
} = programmeSlice.actions;
