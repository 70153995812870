import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const AssesmentModal = ({ data, closeModal }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"400px"}
      >
        <Text
          color={"#040505"}
          fontSize={"25px"}
          letterSpacing={"0.45px"}
          textAlign={"center"}
        >
          You have come to the end of this assessment
        </Text>
        <Text
          color={"#040505"}
          letterSpacing={"0.54px"}
          fontSize={"30px"}
          fontWeight={"bold"}
          py={5}
        >
          Your score is {data.selectedAnswersRatio}/{data.length}
        </Text>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          pt={5}
        >
          <Button
            width={"200px"}
            bg={"#E8AC286C"}
            borderRadius={"5px"}
            color={" #040505"}
            letterSpacing={"0.65px"}
            fontSize={"18px"}
            fontWeight={"medium"}
            _hover={{ backgroundColor: "" }}
            onClick={() => {
              closeModal();
              navigate("dashboard");
            }}
          >
            Go to dashboard
          </Button>
          {/* <Button width={'200px'} bg={'#5CBF22'} borderRadius={'5px'} color={' #040505'} letterSpacing={'0.65px'}
                    fontSize={'18px'} fontWeight={'medium'} _hover={{backgroundColor:''}}
                >Back</Button> */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default AssesmentModal;
