import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const addAcademicSession = async (payload) => {
  return await httpRequest(`${BASE_URL}/sessions`, "post", payload);
};

export const editAcademicSession = async (payload) => {
  return await httpRequest(`${BASE_URL}/sessions/updatesession?id=${payload.id}`, "put", payload);
};

export const deleteAcademicSession = async (id) => {
  return await httpRequest(`${BASE_URL}/sessions/${id}`, "delete");
};

export const addProgramme = async (payload) => {
  return await httpRequest(`${BASE_URL}/programme`, "post", payload);
};

export const editProgramme = async (payload, id) => {
  return await httpRequest(`${BASE_URL}/programme/${id}`, "put", payload);
};

export const bulkUploadProgramme = async (payload) => {
  return await httpRequest(`${BASE_URL}/programme/bulk-upload`, "post", payload);
};

export const deleteProgramme = async (id) => {
  return await httpRequest(`${BASE_URL}/programme/${id}`, "delete");
};

export const addCourse = async (payload) => {
  return await httpRequest(`${BASE_URL}/course`, "post", payload);
};
export const editCourse = async (payload, id) => {
  return await httpRequest(`${BASE_URL}/course/${id}`, "put", payload);
};
export const deleteCourse = async (id) => {
  return await httpRequest(`${BASE_URL}/course/${id}`, "delete");
};

