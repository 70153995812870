import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  allStudents: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const fetchAllStudents= createAsyncThunk(
  "allstudents/allstudents",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/student/get-all`,
      "post",
      {...getState()?.allStudents?.params}
    );
  }
);

const getAllStudentSlice = createSlice({
  name: "allstudents",
  initialState,
  reducers: {
    updateAllStudentsPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateAllStudentsKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //students
    builder.addCase(fetchAllStudents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStudents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allStudents = payload?.data;
    });
    builder.addCase(fetchAllStudents.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default getAllStudentSlice.reducer;
export const { updateAllStudentsKeyword, updateAllStudentsPagination } =
  getAllStudentSlice.actions;
