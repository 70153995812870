import React from 'react';
import PageTitle from '../components/PageTitle';
import CustomTable2 from '../components/CustomTable2';
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { studentData } from "../data/mock";
import { Text } from '@chakra-ui/react';



const StudentManagement = () => {

  const dispatch = useDispatch();

  const tableProps = {
    data: studentData,
    title: {
      firstName: "First Name",
      lastName: "Last Name",
      studentNumber: "Student Number",
      email: "Email",
      programme: "Programme",
      dateEnrolled: "Date Enrolled",
      addCourse: "",
    },
    dataKeys: [
      "firstName",
      "lastName",
      "studentNumber",
      "email",
      "programme",
      "dateEnrolled",
      "addCourse",
    ],
    otherBtn:true,
    otherBtnText:'Add Student',
    headerTitle:'All Students (0)',
    search_placeholder:'Search Programme',
    expectedCsvHeader: ["firstName", "lastName","studentNumber", "email", "programme", "dateEnrolled"],
    uploadType: "students",
    otherBtnAction: () => dispatch(setFormModal({status: true, title: 'Add New Student', type: 'addstudent'})),
    transformRow: (item) => ({
      firstName: item.firstName,
      lastName: item.lastName,
      studentNumber: item.studentNumber,
      email: item.email,
      programme: item.programme,
      dateEnrolled: item.dateEnrolled,
      addCourse: (
        <Text fontWeight={'bold'} fontSize={'18px'}>...</Text>
      ),
    }),
  };
  return (
    <>
      <PageTitle title='Student management' />
      <CustomTable2 {...tableProps} />
    </>
  )
}

export default StudentManagement