import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Box, Grid, GridItem } from '@chakra-ui/layout';
import { ModalFooter } from '@chakra-ui/modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { manageSelectedStudent } from '../../redux/slices/emailTempateSlice';
import { setFormModal } from '../../redux/slices/modalSlice';

const EnrolledStudent = () => {
    const dispatch = useDispatch()
    const students = useSelector((state) => state.students?.students?.data);
    const selectedStudents = useSelector((state) => state.emailTemplate?.selectedStudents);
    return (
        <Box mt={4}>
        <Grid
            templateColumns="repeat(2, 1fr)"
            gap={["10px", "20px", "20px"]}
          >
            {students?.map(({ id, firstName, lastName }) => {
           
              return(
              <GridItem key={id}> 
                <Checkbox
                  value={id}
                  defaultChecked={selectedStudents?.includes(id)}
                  iconColor="#5CBF22"
                  onChange={() => dispatch(manageSelectedStudent(id))}
                >
                  {firstName}  {lastName}
                </Checkbox>
              </GridItem>
            )})}
          </Grid>

          <ModalFooter mb={2}>
          <Button
            type="submit"
            width={"100%"}
            // background={"#5CBF22"}
            background={"red.300"}
            _hover={{ background: "#D40E1F" }}
            color={"whiteAlpha.900"}
            className="mt-5"
            onClick={() => dispatch(setFormModal({staus: false}))}
          
          >
            Confirm selection
          </Button>
        </ModalFooter>
      </Box>
    );
};

export default EnrolledStudent;