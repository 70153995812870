import React from "react";
import PageTitle from "../components/PageTitle";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { BsClockFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";

const Event = ({ day = "Wed", date = "18th" }) => {
  return (
    <Flex alignItems={"center"} gap={"1.5rem"} bg="#BEBEBE0D" p={[1, 1, 2]}>
      <Flex
        direction={"column"}
        minW={"4rem"}
        maxW={"7rem"}
        w="7rem"
        h="6rem"
        justify={"center"}
        align={"center"}
        border={"1px solid #70707030"}
        borderRadius={"10px"}
        fontWeight={"bold"}
        color={"#0405057C"}
      >
        <Text>{day}</Text>
        <Text>{date}</Text>
      </Flex>

      <Flex direction={"column"} color={"#0405057C"}>
        <Flex gap={2} alignItems={"center"}>
          <BsClockFill size={"1.5rem"} style={{ padding: "2px" }} />{" "}
          <Text>08:00-09:30</Text>
        </Flex>
        <Flex gap={2} alignItems={"center"}>
          <MdLocationPin size={"1.5rem"} /> <Text>Online</Text>
        </Flex>
      </Flex>
      <Text fontWeight={"bold"} color={"#040505BF"}>
        Fundamentals of Marketing
      </Text>
      <Flex ml={"auto"}>
        <Button variant={"ghost"} size={"sm"} color={"#0405057C"}>
          Edit
        </Button>
        <Button variant={"ghost"} size={"sm"} color={"#D40E1F"}>
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

const EventMonth = ({ month = "May" }) => {
  return (
    <Box
      p={[2, 2, 4]}
      maxH={"26rem"}
      overflow={"auto"}
      border={"1px solid #70707030"}
      borderRadius={"10px"}
    >
      <Text color={"#5CBF22"} fontWeight={"bold"}>
        {month}
      </Text>
      <Flex direction={"column"} gap={2}>
        <Event />
        <Event />
        <Event />
      </Flex>
    </Box>
  );
};
const EventsList = ({ month = "May" }) => {
  return (
    <Flex direction={"column"} gap={4}>
      <EventMonth month="May" />
      <EventMonth month="June" />
    </Flex>
  );
};

const Events = () => {
  return (
    <>
      <PageTitle title={"Events"} />;
      <Flex direction="column" bg={"white"} py={4}>
        <Tabs variant={"unstyled"}>
          <TabList color="#BEBEBE" borderBottom={"1px solid #70707030"}>
            <Tab _selected={{ color: "#5CBF22", fontWeight: "bold" }}>
              Upcoming Events
            </Tab>
            <Tab _selected={{ color: "#5CBF22", fontWeight: "bold" }}>
              Pending Events
            </Tab>
            <Tab _selected={{ color: "#5CBF22", fontWeight: "bold" }}>
              Cancelled Events
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="#5CBF22"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <EventsList />
            </TabPanel>
            <TabPanel>
              <EventsList />
            </TabPanel>
            <TabPanel>
              <EventsList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};

export default Events;
