import { useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLecturerCourses,
  updateLecturerCourseStatus,
} from "../redux/slices/lecturerCoursesSlice";
import DotLoader from "./loaders/DotLoader";
import EmptyState from "./EmptyState";
import Courses from "./Courses";

const ClassList = () => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.lecturerCourse);
  const user = useSelector((state) => state?.auth?.user);
  const courseList = courses?.courses;

  const activeCourses = courseList?.filter((course) => course?.isActive === true)
  const inActiveCourses = courseList?.filter((course) => course?.isActive === false)

  useEffect(() => {
    dispatch(fetchLecturerCourses(user?.id));
  }, [dispatch, user?.id,]);

  return (
    <div className="w-full bg-white min-h-screen">
      <Tabs colorScheme="red">
        <TabList>
          <Tab
            fontWeight="semibold"
            onClick={() => dispatch(updateLecturerCourseStatus(true))}
          >
            Active Classes
          </Tab>
          <Tab
            fontWeight="semibold"
            onClick={() => dispatch(updateLecturerCourseStatus(false))}
          >
            Closed Classes
          </Tab>
        </TabList>
        {courses?.loading ? (
          <div
            className="text-center h-[700px] flex flex-col justify-center items-center"
            style={{ width: "100%" }}
          >
            <DotLoader />
          </div>
        ) : !courses?.loading && courseList?.length < 1 ? (
          <EmptyState />
        ) : (
          <TabPanels>
            <TabPanel>
              <Courses courseList={activeCourses} />
            </TabPanel>
            <TabPanel>
              <Courses courseList={inActiveCourses} />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </div>
  );
};

export default ClassList;
