import { Button, Flex, FormControl, FormLabel, Text, Textarea } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
import { StarIcon } from "@chakra-ui/icons";
import { ratingSchema } from "../../utils/formValidationSchema";
import { addCourseReview } from "../../services/courseService";
import toast from "react-hot-toast";
import { fetchCourseReview } from "../../redux/slices/courseReviewSlice";


const ratingCount = [
  {rating : 1},
  {rating : 2},
  {rating : 3},
  {rating : 4},
  {rating : 5}
]

const AddReview = ({data}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [ratings, setRatings] = useState(0);
  const [loading, setLoading] = useState(false)

  console.log(loading);

  const initialValues = {
    comment: '',
    rating: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ratingSchema,
    validateOnMount: true,
    onSubmit: async (values, onSubmitProps) => {
      const formData = {
        review : values.comment,
        rating: values.rating,
        courseId: data,
        studentId: user?.id,
      }
      setLoading(true)
      const res = await addCourseReview(formData);
      console.log(res);
      if(res.status === true){
        toast.success(res.message, {duration: 10000}); 
        dispatch(fetchCourseReview(data))
        dispatch(setFormModal({status: false}));
      }
    }
  })

  const handleRating = (count) => {
    setRatings(count)
    formik.setFieldValue("rating", count);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex
        direction={"column"}
        gap={8}
        sx={{
          "& .chakra-form__label": { mb: 2, fontWeight: "bold" },
          "& .chakra-input": { bg: "#BEBEBE26", border: "none" },
        }}
        mt={6}
      >
        <FormControl>
          <FormLabel fontSize={"20px"}>Comment</FormLabel>
          <Textarea 
          name="comment"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          defaultValue={formik.values.comment}
          placeholder="Enter Message here"
           background={"#BEBEBE26"} 
           outline={"none"} 
           _focus={{outline: "none"}}
          />
          {formik.touched.comment && formik.errors.comment && (
            <p className="text-sm text-red-500 mt-1">{formik.errors.comment}</p>
          )}
        </FormControl>
        
        <FormControl mb={"16px"}>
          <FormLabel fontSize={"20px"}>Rate Course</FormLabel>
          <Text color={"gray.500"} mb={"16px"}>How would you rate this course?</Text>
          <Flex gap={"6px"}>
            {
              ratingCount.map(({rating}) => (
                <StarIcon 
                id={rating}
                key={rating}
                fontSize={"16px"} 
                onClick={() => handleRating(rating)} 
                cursor={"pointer"} 
                color={`${(rating <= ratings) ? "yellow.400" : "gray.100"}`} 
                _hover={{color: "gray.300"}}/>
              ))
            }
          </Flex>
          {formik.touched.rating && formik.errors.rating && (
            <p className="text-sm text-red-500 mt-1">{formik.errors.rating}</p>
          )}
        </FormControl>
        <Flex
          justify={"end"}
          gap={4}
          sx={{ "& > button": { flexBasis: "15rem" } }}
        >
          <Button
            border={"1px solid #9361ea"}
            bg={"white"}
            onClick={() =>
              dispatch(
                setFormModal({
                  status: false,
                })
              )
            }
          >
            Cancel
          </Button>
          <Button 
          type="submit" 
          // bg={"#E8AC286C"}
          background={"red.300"}
          >
            Save Review
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default AddReview;
