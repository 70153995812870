import {
  Avatar,
  Button,
  Center,
  Circle,
  Flex,
  MenuDivider,
  Text,
} from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { BsFillChatLeftFill } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import { FaKey, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router";
import { setFormModal } from "../../redux/slices/modalSlice";


const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user);

  const handleSignout = () => {
    dispatch(removeUser())
    navigate("/")

  };
  return (
    <Flex
      h="4rem"
      boxShadow={"0px 3px 6px #00000029"}
      justify={"flex-end"}
      zIndex={10}
      alignItems={"center"}
      px={[1, 4, 4, 10]}
      display={["none", "none", "none", "flex"]}
      position={"sticky"}
      top={0}
      left={0}
      bg={"white"}
    >
      <Flex gap={4} alignItems={"center"}>
        <Center position={"relative"} cursor={"pointer"}>
          <Circle
            bgColor={"#D40E1F"}
            size={"6px"}
            position={"absolute"}
            top={"-2px"}
            right={"-1px"}
            border={"1px solid white"}
          />
          <BsFillChatLeftFill />
        </Center>

        <Flex alignItems={"center"} gap={4} cursor={"pointer"}>
          <Avatar
            width={"3rem"}
            name="Thomas Andrew P."
            src="https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1118.jpg"
          />

          <Flex direction={"column"} lineHeight={1} gap={1}>
            <Text fontWeight={"500"}>{user?.firstName} {user?.lastName}</Text>
            <Menu>
              <MenuButton
                size={"sm"}
                h={".7rem"}
                minW={"auto"}
                fontSize={"sm"}
                as={Button}
                rightIcon={
                  <AiFillCaretDown style={{ padding: 0, margin: 0 }} />
                }
                p={0}
                bg={"none"}
                _hover={{ bg: "none" }}
                fontWeight={"normal"}
                justifyContent={"start"}
                sx={{ "& > span:first-child": { flexGrow: 0 } }}
              >
                {user?.role}
              </MenuButton>
              <MenuList position={"relative"} zIndex={200}>
                <MenuItem>
                  <Flex gap={2} alignItems={"center"}>
                    <FaUserCircle />
                    <Text>Profile</Text>
                  </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => dispatch(setFormModal({ status: true, title: "Change Password", type: "changePassword"}))}>
                  <Flex gap={2} alignItems={"center"}>
                    <FaKey />
                    <Text>Change Password</Text>
                  </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleSignout}> 
                  <Flex gap={2} alignItems={"center"} >
                    <MdOutlineLogout color="red" />
                    <Text>Logout</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
