import { useEffect } from "react";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import LiveClasses from "./LiveClasses";
import { fetchLiveClass } from "../redux/slices/LiveClassSlice";

const LiveClass = ({user, courseId}) => {
  const dispatch = useDispatch();
  const liveClasses = useSelector((state) => state.liveClass);

  useEffect(() => {
    dispatch(fetchLiveClass(courseId));
  }, [dispatch, courseId]);

  return (
    <Flex direction={"column"}>
      <Flex justify={"space-between"} align={"center"}>
        <Text 
        color={"red.500"}
        // color={"#5CBF22"}
        fontSize={"2rem"} fontWeight={"bold"}>
          Live Class
        </Text>
        {
          user !== "student" && 
          <Button
            leftIcon={<AiOutlinePlusCircle />}
            // bg={"#5CBF22"}
            bg={"red.500"}
            color={"white"}
            onClick={() =>
              dispatch(
                setFormModal({
                  status: true,
                  title: "Create Live Class",
                  type: "createLiveClass",
                  size: "4xl",
                  data:{courseId}
                })
              )
            }
          >
            Schedule Live Class
          </Button>
        }
      </Flex>
      <Divider my={2} />

      <LiveClasses user={user} liveClasses={liveClasses} courseId={courseId} />
    </Flex>
  );
};

export default LiveClass;
