import { Flex, Square, Text } from "@chakra-ui/react";
import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
} from "recharts";

const COLORS = ["#E8AC28", "#5CBF22"]; // You can customize these colors

const GenderPieChart = ({ maleStudentCount, femaleStudentCount }) => {
  const data = [
    { name: "Boys", value: maleStudentCount },
    { name: "Girls", value: femaleStudentCount },
  ];
  
  const totalValue = data.reduce((total, entry) => total + entry.value, 0);

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <Flex justify="space-between">
        {payload.map((entry, index) => {
          return (
            <Flex gap={1} key={`item-${index}`} alignItems={"center"} px={1}>
              <Square size={".5rem"} bg={COLORS[index]} />
              <Text>
                {entry.value}: {entry.payload.payload.value}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex h="30rem" direction="column" w="full" bg={"white"}>
      <Text
        color="#0405057C"
        fontWeight="bold"
        size="1.25rem"
        p={4}
        borderBottom={"1px solid #EEF0F7"}
      >
        Total Students by Gender
      </Text>
      <ResponsiveContainer width="100%" height={"100%"}>
        <PieChart>
          <Legend
            align="center"
            verticalAlign="bottom"
            iconType="square"
            content={renderLegend}
          />
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={80} // Adjust this value for the size of the inner hole
            outerRadius={110} // Adjust this value for the overall size of the doughnut chart
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              style={{ display: "flex", justifyContent: "space-between" }}
              value={totalValue}
              position="center"
              fontSize={45}
              fill="#333" // Customize the color of the label
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default GenderPieChart;
