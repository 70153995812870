import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  courses: [],
  loading: false,
  error: null,
  activeCourses: null,
  inActiveCourses: null,
  courseParams: {
    pageSize: 20,
    pageNumber: 1,
    isActive: true
  },
};

export const fetchLecturerCourses = createAsyncThunk(
  "courses/courses",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/course/getcoursebylecturerid2?LecturerId=${arg}`, "post"
      // {...getState()?.lecturerCourse?.courseParams, lecturerId : arg}
    );
  }
);

const lecturerCourseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateLecturerCoursePagination: (state, {payload}) => {
      state.courseParams.pageNumber = payload;
      state.loading = true
    },
    updateLecturerCoursesKeyword: (state, {payload}) => {
      state.courseParams.keyword = payload;
    },

    updateLecturerCourseStatus: (state, {payload}) => {
        state.courseParams.isActive = payload;
      },

  },
  extraReducers: (builder) => {
    //courses
    builder.addCase(fetchLecturerCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLecturerCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courses = payload?.data;
      // state.activeCourses = payload?.data?.filter(course => course.isActive);
      // state.inActiveCourses = payload?.data?.filter(course => !course.isActive);
    });
    builder.addCase(fetchLecturerCourses.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default lecturerCourseSlice.reducer;

export const {
    updateLecturerCoursePagination,
    updateLecturerCoursesKeyword,
    updateLecturerCourseStatus,
} = lecturerCourseSlice.actions;
