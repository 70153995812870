import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  module: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,

  },
};

export const fetchModule = createAsyncThunk(
  "module/module",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/module/getmodulebycourseid`,
      "post",
      {...getState()?.module?.params, courseId: arg}
    );
  }
);

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    updateModulePagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateModuleKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //module
    builder.addCase(fetchModule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchModule.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.module = payload?.data;
    });
    builder.addCase(fetchModule.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default moduleSlice.reducer;
export const { updateModulePagination, updateModuleKeyword } =
  moduleSlice.actions;
