import React from 'react';
import { emptyState } from './entryFile/imagePath';


const EmptyState = () => {
    return (
        <div className='flex flex-col justify-center items-center bg-white h-full py-10'>
        <div className='bg-orange-100 px-6 py-10 rounded-full'>
            <img src={emptyState} alt='' className='w-32'/>
        </div>
        <div className='text-gray-400 text-xs flex flex-col py-6'>
            <p className='flex justify-center text-base items-center'>OOPS! It empty</p>
            <p className='font-semibold text-base'>No record found for your query</p>
        </div> 
        {/* <div className="flex gap-3">
            <button className="text-xs font-light border px-3 py-1.5 bg-orange-200 text-black flex items-center gap-x-1.5">
                <FaUpload />
                Bulk Upload
            </button>
            <button 
    
            className="text-xs font-light border px-3 py-1.5 bg-green-200 text-green-500 hover:bg-red-500 hover:text-white flex items-center gap-x-1.5">
                <AiOutlinePlusCircle />
                {otherBtnText ? otherBtnText: "Add Course"}
            </button>
        </div>  */}
    </div>
    );
};

export default EmptyState;