import { useEffect } from "react";
import { Button } from "@chakra-ui/button";
import { Box, HStack, Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
// import EmptyCourseModule from './EmptyCourseModule'
import CourseModuleList from "./CourseModuleList";
import { fetchModule } from "../redux/slices/moduleSlice";
import DotLoader from "./loaders/DotLoader";
import EmptyState from "./EmptyState";

const ManageModule = ({ courseId }) => {
  const dispatch = useDispatch();

  const modules = useSelector((state) => state.module);

  useEffect(() => {
    dispatch(fetchModule(courseId));
  }, [dispatch, courseId]);

  const addModule = () => {
    dispatch(
      setFormModal({
        status: true,
        title: "Create A New Module",
        type: "addModule",
        data: courseId,
      })
    );
  };

  return (
    <>
      <Box>
        <HStack
          justifyContent="space-between"
          borderBottom={"1px solid #BEBEBE4D"}
          pb={2}
        >
          <Stack>
            <Text color="red.500" fontSize="2xl">
              Course Content
            </Text>
            <Text fontWeight="semibold">Learning Outline</Text>
          </Stack>
          <Button
            onClick={() => addModule()}
            background="#E53E3E"
            _hover={{ background: "red.500" }}
          >
            <AiFillPlusCircle color="white" background="#BEBEBE80" />
            <Text color="white" fontSize={"sm"} marginLeft={2}>
              Add Module
            </Text>
          </Button>
        </HStack>
        {modules?.loading ? (
          <div
            className="text-center h-[700px] flex flex-col justify-center items-center"
            style={{ width: "100%" }}
          >
            <DotLoader />
          </div>
        ) : !modules?.loading && modules?.module?.data?.length < 1 ? (
          <EmptyState />
        ) : (
          <CourseModuleList modules={modules} courseId={courseId} />
        )}
      </Box>
    </>
  );
};

export default ManageModule;
