import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const addUser = async (payload) => {
  return await httpRequest(`${BASE_URL}/users`, "post", payload);
};

export const updateUser = async (payload) => {
  return await httpRequest(`${BASE_URL}/users`, "put", payload);
};

export const createStudent = async (payload) => {
  return await httpRequest(`${BASE_URL}/users/create-student`, "post", payload);
};

export const updateStudent = async (payload, id) => {
  return await httpRequest(`${BASE_URL}/student/${id}`, "put", payload);
};

export const bulkUploadUser = async (payload) => {
  return await httpRequest(`${BASE_URL}/users/bulkUser`, "post", payload);
};
export const bulkUploadStudent = async (payload) => {
  return await httpRequest(`${BASE_URL}/users/create-bulk-student`, "post", payload);
};

export const toggleStatus = async (payload) => {
  return await httpRequest(`${BASE_URL}/users/${payload?.userId}/toggle-status`, "post", payload);
};

export const changePassword = async (payload) => {
  return await httpRequest(`${BASE_URL}/users/reset-password`, "post", payload);
};
