import React from 'react';
import PageTitle from '../components/PageTitle';
import ClassList from '../components/ClassList';

const CourseManagement = () => {
    return (
        <>
        <PageTitle title="Course Management"/>
        <ClassList />
        </>
    );
};

export default CourseManagement;