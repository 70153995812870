import PageTitle from "../components/PageTitle";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import ManageModule from "../components/ManageModule";
import ManageAsssement from "../components/ManageAsssement";
import { MdArrowDropDown } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import StudentsEnrolled from "./StudentsEnrolled";
// import ChatForum from '../components/ChatForum';
import LiveClass from "../components/LiveClass";
import Grades from "./Grades";

const ClassModule = () => {
  let param = useParams();
  let courseId = param?.courseId;
  let courseName = param?.courseName;
  const navigate = useNavigate();
  return (
    <div>
      <PageTitle title={courseName} />
      <Flex
        background="white"
        justifyContent="space-between"
        my={2}
        padding={2}
      >
        <span
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <AiOutlineArrowLeft />
          <Text>Back</Text>
        </span>
        <HStack cursor="pointer">
          <Text>Sort by</Text>
          <MdArrowDropDown className="h-6 w-auto " />
        </HStack>
      </Flex>
      <Box background="white" paddingY={3}>
        <Tabs colorScheme="red" orientation="vertical">
          <TabList
            minWidth="max-content"
            borderRight={"1px solid #F2F2F2"}
            gap={"16px"}
          >
            <Tab justifyContent="flex-start">Students enrolled</Tab>
            <Tab justifyContent="flex-start">Manage Module</Tab>
            <Tab justifyContent="flex-start">Manage Assessment</Tab>
            <Tab justifyContent="flex-start">Grades</Tab>
            {/* <Tab justifyContent="flex-start">Chat Forum</Tab> */}
            <Tab justifyContent="flex-start">Live Class</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <StudentsEnrolled courseId={courseId} />
            </TabPanel>
            <TabPanel>
              <ManageModule courseId={courseId} />
            </TabPanel>
            <TabPanel>
              <ManageAsssement courseId={courseId} />
            </TabPanel>
            <TabPanel>
              <Grades />
            </TabPanel>
            {/* <TabPanel>
                     <ChatForum courseId={courseId} courseName={courseName}/>
                  </TabPanel> */}
            <TabPanel>
              <LiveClass user="lecturer" courseId={courseId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

export default ClassModule;
