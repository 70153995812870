import { useParams } from 'react-router-dom';
import LiveClass from './LiveClass';


const CourseDetailLiveClass = () => {
  const id = useParams();
  return (
    <>
      <LiveClass user={"student"} courseId={id?.courseId}/>    
    </>
  )
}

export default CourseDetailLiveClass;