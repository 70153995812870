import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import storeInit from "./redux/store";
import { ChakraProvider } from '@chakra-ui/react'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={storeInit.store}>
    <PersistGate loading={null} persistor={storeInit.persistor}>
      <BrowserRouter>
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);


reportWebVitals();
