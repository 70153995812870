import { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { BiCloudDownload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
import { uploadFile } from "../../services/studentFileService";
import { fetchStudentFiles } from "../../redux/slices/studentFileSlice";

const StudentUploadFile = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState("");
    const [bookCoverError, setBookCoverError] = useState("");
    const [fileError, setFileError] = useState("");

    console.log(bookCoverError);
    
    const handleFileChange = (event) => {
        setFileError("");
        const file = event.target.files[0];
        if (
            !file?.name.endsWith(".jpg")  &&
            !file?.name.endsWith(".jpeg") &&
            !file?.name.endsWith(".png") &&
            !file?.name.endsWith(".PNG") &&
            !file?.name.endsWith(".JPG") &&
            !file?.name.endsWith(".pdf")
          ) {
            setBookCoverError("Only jpg, jpeg, png and PDF files are acccepted");
            return;
          }
        if (file) {
          convertToBase64(file);
        }
      };
    
      const convertToBase64 = (filer) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result;
          setFile({
            name: filer?.name,
            extension: ".png",
            data: base64String,
          });
        };
        reader.readAsDataURL(filer);
    };

    const uploadingFile = async (e) => {
        e.preventDefault();
        const payload = {
            studentId: user?.id,
            file: file
        }
        setLoading(true);
        const res = await uploadFile(payload);
        if(res?.status) {
          dispatch(fetchStudentFiles())
          dispatch(setFormModal({status: false}))
        }
        setLoading(false);
    };
    
  return (
    <Flex
        direction={"column"}
        gap={8}
        sx={{
        "& .chakra-form__label": { mb: 2, fontWeight: "bold" },
        "& .chakra-input": { bg: "#BEBEBE26", border: "none" },
        }}
        mt={6}
    >
        <form onSubmit={uploadingFile}>
            <Box>
                <FormLabel>File Name</FormLabel>
                <Input
                placeholder="Enter here"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                />
            </Box>
            <Box
                mt={5}
                className="bg-gray-100 p-6 py-16 rounded"
                id="form-file-upload"
            >
                <Input
                type="file"
                id="input-file-upload"
                required
                onChange={handleFileChange}
                hidden
                accept="image/*"
                multiple={false}
                />
                <FormLabel id="label-file-upload" htmlFor="input-file-upload">
                <Flex gap={2} justifyContent={"center"} cursor={"pointer"}>
                    <BiCloudDownload className="text-gray-600 text-lg" />
                    <p className="text-xs font-light text-gray-500">
                    {file?.name ? file?.name : "Drag and drop your file here"}
                    </p>
                </Flex>
                </FormLabel>
                {fileError && (
                <Alert status="error" className="mt-3">
                    <AlertIcon />
                    <AlertTitle>{fileError}</AlertTitle>
                </Alert>
                )}
            </Box>
            <Flex
                justify={"end"}
                gap={5}
                sx={{ "& > button": { flexBasis: "15rem" } }}
                mt={4}
            >
                <Button
                border={"1px solid #9361ea"}
                bg={"white"}
                onClick={() =>
                    dispatch(
                    setFormModal({
                        status: false,
                    }))
                }
                >
                Cancel
                </Button>
                <Button
                isLoading={loading}
                type="submit"
                loadingText="Processing..."
                bg={"#9361ea"}
                >
                Save
                </Button>
            </Flex>
        </form>
    </Flex>
)}

export default StudentUploadFile