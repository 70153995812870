import React, { useEffect } from 'react';
import { Box, Flex, Input, Text, Select } from '@chakra-ui/react';
import CustomTable2 from '../components/CustomTable2';
import { BiCalendar } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGrades, updateGradesPagination } from '../redux/slices/gradeSlice';
import ResponsivePagination from "react-responsive-pagination";

const Grades = () => {

  let param = useParams(); 
  let courseId = param?.courseId;
  const dispatch = useDispatch();
  const grades = useSelector((state) => state.grades);
  const gradeList = grades?.grades;

  useEffect(() => {
    dispatch(fetchGrades(courseId))
  }, [grades.params, courseId, dispatch])

  const calendarStyles = {
    background: "none",
    bottom: 0,
    // color: "transparent",
    cursor: "pointer",
    height: "auto",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "auto",
    
  };
  
    const tableProps = {
        data: gradeList?.data,
        title: {
          Title: "Assessment",
          category: "category",
          type: "type",
          grade: "Grade",
          score: "score",
          passMark: "passMark",
          student: "Student",
        },
        dataKeys: [
          "checkbox",
          "Title",
          "category",
          "type",
          "grade",
          "score",
          "passMark",
          "student",
        ],
        search:false,
        headerStrip:false,
        sn : false,
        transformRow: (item) => ({
          Title: item?.assesments?.title,
          category: item?.assesments?.category === 0 ? "Multichoice" : "Essay",
          type: item?.assesments?.type === 0 ? "Assignment" : item?.assesments?.type === 1 ? "Exam" : "Quiz",
          score: item?.score,
          grade: (
            <span style={{color:item.grade === 'F'? 'red' : '#5CBF22'}}>{item?.grade}</span>
          ),
          passMark: item?.assesments?.passMark,
          student: (
            <div className='flex items-center gap-1'>
              <Text fontSize={'13px'} color={'#0B82F8'} letterSpacing={'0.74px'} >{item?.student?.firstName}</Text>
              <Text fontSize={'13px'} color={'#0B82F8'} letterSpacing={'0.74px'} >{item?.student?.lastName}</Text>
            </div>
          ),
          checkbox:(
            <input type="checkbox" />
          )
        }),
      };


  return (
    <Box >
        <Flex direction={['column', 'row', 'row' ]} justifyContent={['flex-start', 'space-between', 'space-between']} alignItems={'center'} bg={'red.100'} borderRadius={'0px 0px 28px 28px'} py={'20px'} px={'30px'} >
            <Box >
              <Flex gap={2} alignItems={"center"} bg="white" px={2} my={2} display={"inline-flex"} alignSelf={"start"} border={'1px solid #BEBEBE'} borderRadius={'6px'}
                sx={{
                  "input[type='date']::-webkit-calendar-picker-indicator ":
                    calendarStyles,
                }}
              >
                <BiCalendar size={"1.5rem"} />
                <Input w={"7rem"} px={1} border={"none"} type="date" _placeholder={{ color: "#B4B1B1" }} focusBorderColor='transparent'
                />{" "}-{" "}
                <Input w={"7rem"} px={1} border={"none"} type="date" _placeholder={{ color: "#B4B1B1" }} focusBorderColor='transparent'
                />
              </Flex>
            </Box>
            <Box>
              <Select placeholder='All Type' bg={'#FFFFFF'} border={'1px solid #BEBEBE'} borderRadius={'6px'} focusBorderColor='#BEBEBE'>
                <option value='Theory'>Theory</option>
                <option value='Multiple Choice'>Multiple Choice</option>
              </Select>
            </Box>
        </Flex>

        <Box py={'20px'}>
          <CustomTable2 {...tableProps} />
          <div className="mt-5">
            <ResponsivePagination
              current={grades.params?.pageNumber}
              total={grades?.grades?.totalPages}
              onPageChange={(page) => dispatch(updateGradesPagination(page))}
            />
          </div>
        </Box>
    </Box>
  )
}

export default Grades;
