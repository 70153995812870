import { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import AsessmentTestTab from "./AsessmentTestTab";
import { monthList2 } from "../utils/monthList";

const CourseAssessmentTab = ({ show, setShow, assessment }) => {
  const [assessmentTarget, setAssementTarget] = useState("");
  const handleSelect = (value) => {
    setAssementTarget(value);
    setShow(false);
  };
  return (
    <div>
      {show ? (
        <>
          <Box
            mb={"30px"}
            bg={"#9361ea"}
            // bg={"#F3FBEF"}
            borderRadius={"20px 0px 28px 0px"}
            py={"30px"}
            px={"30px"}
          >
            <p className="font-semibold text-2xl text-slate-800 text-white">
              Assessments
            </p>
          </Box>
          <Flex justifyContent={"flex-end"}>
            <Box
              border={"1px solid #BEBEBE"}
              borderRadius={"6px 6px 6px 6px"}
              mb={"10px"}
              p={"2px"}
            >
              <HStack cursor="pointer">
                <Text fontSize={"12px"}>Newest</Text>
                <MdArrowDropDown className="h-4 w-auto " />
              </HStack>
            </Box>
          </Flex>
          <div className="space-y-5">
            {assessment?.data?.map((item) => {
              let now = new Date(item?.examDate);
              const renderDate =
                now.getDate() +
                " " +
                monthList2[now.getMonth()] +
                " " +
                now.getFullYear();
              return (
                <div
                  className="border-[#5CBF222D] border-style:solid border w-[100%]"
                  key={item.id}
                >
                  <div className="flex w-[100%] justify-between ">
                    <div className="border-r text-[#D40E1F] font-bold flex justify-center items-center p-10">
                      {"Active"}
                    </div>
                    <div className="p-5 w-full  space-y-2">
                      <p className="text-[#040505] font-bold">{item?.title}</p>
                      <p className="text-[#040505] text-sm">
                        {"Test Assessment"}
                      </p>
                      <p className="text-xs text-[#E8AC28]">{`To be submitted on the ${renderDate}, ${item.endTime}`}</p>
                      <p className="text-xs ">{"active"}</p>
                    </div>

                    <button
                      onClick={() => handleSelect(item)}
                      className="text-sm text-blue-400 flex justify-end gap-2 w-full items-center mr-4"
                    >
                      Go to assessment
                      <ChevronRightIcon className="text-blue-400 w-5" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <AsessmentTestTab assessment={assessmentTarget} />
      )}
    </div>
  );
};

export default CourseAssessmentTab;
