import { Link } from "react-router-dom";
import { background } from "../components/entryFile/imagePath";

const PageNotFound = ({errorCode = "404", title="Page Not Found", desc="Sorry, we couldn’t find the page you’re looking for."}) => {
    return (
      <>
        <main className="max-h-screen ">
        <div className="relative">
            <img
            src={background}
            alt=""
            className="absolute inset-0 -z-10 h-screen w-full object-cover"
            />
        </div>
        <div className="absolute inset-0 -z-10 h-screen w-full bg-black opacity-40"></div>
        <div className="grid h-screen place-items-center px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-3xl font-semibold text-primaryOrange">{errorCode}</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
            <p className="mt-6 text-base leading-7 text-gray-100">{desc}</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/"
                className="rounded-md bg-primaryOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryOrange"
              >
                Go back home
              </Link>
              <Link to="/" className="text-sm font-semibold text-gray-900">
                Contact support <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
        </main>
      </>
    )
  }
export default PageNotFound;