import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  gradeReport: null,
  userReport: null,
  generalReport: null,
  loading: false,
  error: null,
};

export const fetchGradeReport = createAsyncThunk(
  "gradeReport/gradeReport",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/report/grade-report?StartDate=${arg.StartDate}&EndDate=${arg.EndDate}&ProgrammeId=${arg.ProgrammeId}&CourseId=${arg.CourseId}`,
      "get"
    );
  }
);

export const fetchUserReport = createAsyncThunk(
  "userReport/userReport",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/report/user-report`,
      "get"
    );
  }

);
export const fetchGeneralReport = createAsyncThunk(
  "generalReport/generalReport",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/report/general-report?startDate=${arg.startDate}&endDate=${arg.endDate}`,
      "get"
    );
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: (builder) => {

    //general report
    builder.addCase(fetchGradeReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGradeReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.gradeReport = payload?.data;
    });
    builder.addCase(fetchGradeReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //user report
    builder.addCase(fetchUserReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userReport = payload?.data;
    });
    builder.addCase(fetchUserReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //general report
    builder.addCase(fetchGeneralReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGeneralReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.generalReport = payload?.data;
    });
    builder.addCase(fetchGeneralReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
    
  },
});

export default reportSlice.reducer;
