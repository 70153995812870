import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const addModule = async (payload) => {
  return await httpRequest(`${BASE_URL}/module`, "post", payload);
};

export const unlockModule = async (payload) => {
  return await httpRequest(`${BASE_URL}/module/unlockmodule`, "put", payload);
};
