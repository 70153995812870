import { Center, Circle, Flex, Text } from "@chakra-ui/react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "September",
    present: 5890,
  },
  {
    name: "October",
    present: 2390,
  },
  {
    name: "November",
    present: 3490,
  },
  {
    name: "December.",
    present: 8000,
  },
];

export default function AttendanceGraph() {
  return (
    <Flex h="30rem" direction="column" w="full" bg={"white"}>
      <Flex justify={"space-between"} p={4} borderBottom={"1px solid #EEF0F7"}>
        <Text color="#0405057C" fontWeight="bold" size="1.25rem">
          Student Enrollment
        </Text>
        <Flex alignItems={"center"} gap={2}>
          <Circle size={".5rem"} bg={"#5CBF22"} />
          <Text>Student</Text>
          {/* <Circle size={".5rem"} bg={"red"} />
          <Text>Absent</Text> */}
        </Flex>
      </Flex>

      <Center p={1} w="full" h="full">
        <ResponsiveContainer width="90%" height="80%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#D40E1F" stopOpacity={0.2} />
                <stop offset="75%" stopColor="#D40E1F" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />

            <Area
              dataKey="Enrollment"
              stackId="1"
              stroke="#D40E1F"
              dot
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            {/* <Area
              dataKey="absent"
              stackId="1"
              stroke="#82ca9d"
              dot
              fillOpacity={1}
              fill="url(#colorPv)"
            /> */}
          </AreaChart>
        </ResponsiveContainer>
      </Center>
    </Flex>
  );
}
