import { Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const DashboardCard = ({ number, name, iconImg }) => {
  return (
    <Center
      p={2}
      pt={1}
      flex={["100%", "calc(100% / 2)", "calc(100% / 2)", "calc(100% / 4)"]}
    >
      <Flex
        bg={"white"}
        grow={1}
        px={4}
        h={"7rem"}
        alignItems={"center"}
        justify={"space-between"}
      >
        <Flex direction={"column"} gap={2}>
          <Text fontSize={"1.64rem"} fontWeight={"bold"}>
            {number}
          </Text>
          <Text color={"#0405057C"} lineHeight={1} fontSize={"1rem"}>
            {name}
          </Text>
        </Flex>
        <Image src={iconImg} width={"2.5rem"} />
      </Flex>
    </Center>
  );
};

export default DashboardCard;
