import React, { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import { Flex } from "@chakra-ui/react";
import DashboardCard from "../components/DashboardCard";
import AttendanceGraph from "../components/AttendanceGraph";
// import { MdOutlineWifiTethering } from "react-icons/md";
// import TopPerformingStudents from "../components/TopPerformingStudents";
import CalendarEvents from "../components/CalendarEvents";
import { useDispatch, useSelector } from "react-redux";
import { fetchLecturerDashboard } from "../redux/slices/dashboardSlice";

// const calendarStyles = {
//   background: "none",
//   bottom: 0,
//   // color: "transparent",
//   cursor: "pointer",
//   height: "auto",
//   left: 0,
//   position: "absolute",
//   right: 0,
//   top: 0,
//   width: "auto",
// };

const LecturerDashboard = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard);
  const dashboardData = data?.lecturerDashbaord;

  useEffect(() => {
    dispatch(fetchLecturerDashboard())
  },[dispatch])

  const cardDetails = [
    { name: "Total Students", number: dashboardData?.studentCount, iconImg: "/students-icon.svg" },
    { name: "Total number of courses", number: dashboardData?.courseCount, iconImg: "/courses-icon.svg" },
    { name: "Active Courses", number: dashboardData?.activeCourseCount, iconImg: "/departments-icon.svg" },
    { name: "Inactive Courses", number: dashboardData?.inActiveCourseCount, iconImg: "/lecturers-icon.svg" },
  ];

  return (
    <>
      <PageTitle title="Dashboard" />
      {/* <Flex
        gap={2}
        alignItems={"center"}
        bg="white"
        px={2}
        my={2}
        display={"inline-flex"}
        alignSelf={"start"}
        sx={{
          "input[type='date']::-webkit-calendar-picker-indicator ":
            calendarStyles,
        }}
      >
        <BiCalendar size={"1.5rem"} />
        <Input
          w={"7rem"}
          px={1}
          border={"none"}
          type="date"
          _placeholder={{ color: "#B4B1B1" }}
        />{" "}
        -{" "}
        <Input
          w={"7rem"}
          px={1}
          border={"none"}
          type="date"
          _placeholder={{ color: "#B4B1B1" }}
        />
      </Flex> */}

      <Flex wrap={"wrap"} mx={-2}>
        {cardDetails.map((card, i) => (
          <DashboardCard {...card} key={i} />
        ))}
      </Flex>

      <Flex
        sx={{
          "@media screen and (max-width: 75rem)": {
            flexWrap: "wrap",
          },
          "@media screen and (min-width: 75rem)": {
            flexWrap: "no-wrap",
          },
        }}
        mx={-2}
      >
        <Flex
          p={2}
          direction={"column"}
          gap={4}
          flex={["100%", "100%", "75%", "50%"]}
        >
          <AttendanceGraph />

          {/* <Flex
            justify={"center"}
            align={"center"}
            bg={"white"}
            p={4}
            h="10rem"
          >
            <MdOutlineWifiTethering />
          </Flex> */}
        </Flex>
        {/* <Flex p={2} flex={["100%", "100%", "25%", "25%"]}>
          <TopPerformingStudents />
        </Flex> */}
        <Flex p={2} flex={["100%", "100%", "25%", "25%"]}>
          <CalendarEvents />
        </Flex>
      </Flex>
    </>
  );
};

export default LecturerDashboard;
