import React, { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import { Grid, GridItem } from "@chakra-ui/react";
import CalendarEvents from "../components/CalendarEvents";
import GeneralAnnouncement from "../components/GeneralAnnouncement";
import StudentViewCard from "../components/StudentViewCard";
import CoursesTab from "../components/CoursesTab";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentDashboard } from "../redux/slices/dashboardSlice";

const StudentDashboard = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard);
  const dashboardData = data?.studentDashbaord;

  useEffect(() => {
    dispatch(fetchStudentDashboard())
  },[dispatch])

  const cardDetails = [
    { name: "Total Courses", number: dashboardData?.courseCount, iconImg: "/students-icon.svg" },
    { name: "Active Courses", number: dashboardData?.activeCourseCount, iconImg: "/courses-icon.svg" },
    { name: "Completed Courses", number: dashboardData?.completedCourseCount, iconImg: "/departments-icon.svg" },
  ];

  return (
    <>
      <PageTitle title="Student Dashboard" />
      <Grid className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 grid-row-6 gap-4 mt-8">
          {cardDetails.map((card, i) => (
            <GridItem className="col-span-1" border={"1px solid #d1d1d1"} borderRadius={'20px'} key={i}>
              <StudentViewCard {...card} />
            </GridItem>
          ))}
          <GridItem bg={"white"} border={"1px solid #d1d1d1"} className="md:col-span-1 md:col-end-5 md:row-start-1 md:row-span-3">
              <GeneralAnnouncement />
          </GridItem>
          <GridItem className="md:col-start-1 col-span-1 sm:col-span-2 md:col-span-3 md:row-end-7 md:row-span-5">
              <CoursesTab />
          </GridItem>
          <GridItem className="col-span-1 row-span-3">
              <CalendarEvents />
          </GridItem>
      </Grid>
    </>
  );
};

export default StudentDashboard;
