import { combineReducers } from "redux";
import authSlice from "./authSlice";
import modalSlice from "./modalSlice";
import bulkUploadSlice from "./bulkUploadSlice";
import pageSlice from "./pageSlice";
import UsersSlice from "./UsersSlice";
import ProgrammeSlice from "./programmeSlice";
import lecturersSlice from "./lecturersSlice";
import announcementSlice from "./announcementSlice";
import courseSlice from "./courseSlice";
import lecturerCoursesSlice from "./lecturerCoursesSlice";
import LibraryBooksSlice from "./LibraryBooksSlice";
import studentSslice from "./studentSslice";
import moduleSlice from "./moduleSlice";
import liveClassSlice from "./LiveClassSlice";
import assessmentSlice from "./assessmentSlice";

import getAllCourseSlice from "./getAllCourseSlice";
import studentCourseSlice from "./studentCourseSlice";
import courseByStatusSlice from "./courseByStatusSlice";
import courseDetailSlice from "./courseDetailSlice";
import assessmentByCourseSlice from "./assessmentByCourseSlice";
import courseReviewSlice from "./courseReviewSlice";
import emailTempateSlice from "./emailTempateSlice";
import gradeSlice from "./gradeSlice";
import studentGradeSlice from "./studentGradeSlice";
import dashboardSlice from "./dashboardSlice";
import reportSlice from "./reportSlice";
import studentFileSlice from "./studentFileSlice";

const combinedSlices = combineReducers({
  auth: authSlice,
  modal: modalSlice,
  page: pageSlice,
  bulkUpload: bulkUploadSlice,
  users: UsersSlice,
  programmes: ProgrammeSlice,
  lecturers: lecturersSlice,
  announcements: announcementSlice,
  courses: courseSlice,
  lecturerCourse: lecturerCoursesSlice,
  libraryBooks: LibraryBooksSlice,
  students: studentSslice,
  module: moduleSlice,
  liveClass: liveClassSlice,
  assesments: assessmentSlice,
  allcourses: getAllCourseSlice,
  studentCourse: studentCourseSlice,
  courseStatus: courseByStatusSlice,
  courseDetail: courseDetailSlice,
  assessmentByCourse: assessmentByCourseSlice,
  courseReview: courseReviewSlice,
  emailTemplate: emailTempateSlice,
  grades: gradeSlice,
  studentGrade: studentGradeSlice,
  dashboard: dashboardSlice,
  report: reportSlice,
  file: studentFileSlice,
});

export default combinedSlices;
