import { useCallback, useEffect } from "react";
import PageTitle from "../components/PageTitle";
import CustomStepper from "../components/CustomSteps";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { assesmentById } from "../services/courseService";
import {
  addQuizPayload,
  updateAssesmentAssessmentPayload,
  updateMoreOptions,
} from "../redux/slices/assessmentSlice";
import { v4 as uuidv4 } from "uuid";

const UIploadQuestions = () => {
  const currentStep = useSelector((state) => state.assesments.currentStep);
  const dispatch = useDispatch();

  uuidv4();

  const params = useParams();
  let assesmentId = params?.assesmentId;

  console.log("assessmentid", assesmentId);

  const fetchAssesmentById = useCallback(async () => {
    const res = (await assesmentById(assesmentId))?.data;
    console.log("ressssssssss", res);
    dispatch(updateAssesmentAssessmentPayload(res?.assessmentPayload));
    dispatch(updateMoreOptions(res?.assessmentPayload?.moreOptions));

    if (res?.questionsPayload?.length > 0) {
      res?.questionsPayload?.forEach((item) => {
        item.questionId = uuidv4();
        item.answersPayloads.forEach((answer) => {
          answer.choiceId = uuidv4();
        });
      });
      dispatch(addQuizPayload(res?.questionsPayload));
    }
  }, [assesmentId, dispatch]);

  useEffect(() => {
    if (assesmentId) {
      fetchAssesmentById();
    }
  }, [fetchAssesmentById, assesmentId]);

  return (
    <div>
      <PageTitle title="Upload Questions" />
      {/* <Flex
        background="white"
        justifyContent="space-between"
        my={1.5}
        padding={2}
      >
        <span
          className="flex items-center gap-2 cursor-pointer"
          onClick={goBack}
        >
          <AiOutlineArrowLeft />
          <Text>Back</Text>
        </span>
        <HStack cursor="pointer">
          <Link
            to="/upload-questions"
            className="text-[13px] font-semibold bg-[#E8AC286B] px-6 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Cancel
          </Link>
          <button
            onClick={() => onChange()}
            to="/upload-questions"
            className="text-[13px] font-semibold bg-[#5CBF22] w-[98px] px-3 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
          >
            Next
          </button>
        </HStack>
      </Flex> */}
      {/* <Box background={"white"} padding={3}>
        <Heading as={"h2"} fontSize={"xl"}>
          Create exams questions
        </Heading>
      </Box> */}
      <CustomStepper currentStep={currentStep} />
    </div>
  );
};

export default UIploadQuestions;
