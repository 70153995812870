import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  courses: null,
  loading: false,
  error: null,
  courseParams: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchCourses = createAsyncThunk(
  "courses/courses",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/course/getcoursebyprogid`, "post", {...getState()?.courses?.courseParams, programmeId : arg}
    );
  }
);

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateCoursePagination: (state, {payload}) => {
      state.courseParams.pageNumber = payload;
      state.loading = true
    },
    updateCoursesKeyword: (state, {payload}) => {
      state.courseParams.keyword = payload;
    },

    clearCourses: (state) => {
      state.courses = null;
    },
  },
  extraReducers: (builder) => {
    //courses
    builder.addCase(fetchCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courses = payload?.data;
    });
    builder.addCase(fetchCourses.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default courseSlice.reducer;



export const {
    updateCoursePagination,
    updateCoursesKeyword,
    clearCourses,
} = courseSlice.actions;
