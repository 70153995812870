import { 
    Box, Stack, Step, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useBreakpointValue
} from "@chakra-ui/react";
import Settings from "./Questions/Settings";
import Preview from "./Questions/Preview";
import QuizAndAnswer from "./Questions/QuizAndAnswer";

const steps = [
    {id: 0, title: 'Settings', description: <Settings /> },
    {id: 1, title: 'Questions', description: <QuizAndAnswer /> },
    {id: 2, title: 'Preview', description: <Preview /> },
    {id: 3, title: 'Publish', description: '' },
]
  
const CustomStepper = ({ currentStep }) => {
    const orientation = useBreakpointValue({ base: 'vertical', md: 'horizontal' });
    
    const activeStepText = steps.filter(step => currentStep === step.id)[0];

    return (
        <Stack background={"white"} mt={2} padding={4}>
            <Stepper index={currentStep} size={"sm"} orientation={orientation}>
                {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                    <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                    />
                    </StepIndicator>
                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                    </Box>
                    <StepSeparator />
                </Step>
                ))}
            </Stepper>
            <Stack mt={5}>
                {activeStepText.description}
            </Stack>
        </Stack>
    )
}

export default CustomStepper;