import { useEffect } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { GiSpeaker } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAnnouncements } from "../redux/slices/announcementSlice";
import { monthList2 }  from '../utils/monthList'

const Announcement = ({ title, announcementDetails, scheduleDate }) => {
  let now = new Date(scheduleDate);
  const renderDate = now.getDate() + ' ' + monthList2[now.getMonth()] + ' ' + now.getFullYear()
  return (
    <Box px={2} py={1} pb={2} _notLast={{ borderBottom: "1px solid #F0F0F0" }}>
      <Flex justifyContent="space-between">
        <Text color={"#E8AC28"} fontWeight={"bold"} fontSize={"0.875rem"}>
          {title}
        </Text>
        <Text fontSize={"0.7rem"} color={"#D40E1F"}>
          {renderDate}
        </Text>
      </Flex>
      <Text color={"#7B7B7B"} fontSize={"0.75rem"}>
        {announcementDetails}
      </Text>
    </Box>
  );
};

const GeneralAnnouncement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.announcements);
  const announcementList = announcements?.announcements?.data
  
  useEffect(() => {
    dispatch(fetchAnnouncements())
  }, [announcements.params, dispatch])
  return (
    <Flex
      h="30rem"
      w="full"
      bg={"white"}
      direction={"column"}
      whiteSpace={"break-spaces"}
    >
      <Flex gap={2} p={3} alignItems={"center"}>
        <GiSpeaker
          style={{ transform: "rotate(320deg)" }}
          color="#858585"
          size={"1.7rem"}
        />
        <Text fontWeight={"bold"} color={"#858585"}>
          General Announcement
        </Text>
      </Flex>
      <Flex direction={"column"} border={"1px solid #F0F0F0"} mx={3}>
        {announcementList?.slice(0, 5)?.map((announcement, i) => (
          <Announcement key={i} {...announcement} />
        ))}
      </Flex>
      <Flex justify={"center"} mt="auto" p={1}>
        <Button
          fontSize={"0.875rem"}
          color={"#5CBF22"}
          p={0}
          background={"none"}
          _hover={{ background: "none" }}
          onClick={() => {
            navigate("/announcement");
          }}
        >
          View All
        </Button>
      </Flex>
    </Flex>
  );
};

export default GeneralAnnouncement;
