import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import CustomTable2 from "../components/CustomTable2";
import { stdGradeCourse } from "../data/mock";
import { Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentGrades } from "../redux/slices/studentGradeSlice";

const StudentGradeSubject = () => {
  const { courseName } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state?.grades);
  console.log("here is the student grade subject", data);
  useEffect(() => {
    dispatch(fetchStudentGrades({ courseId: courseName, studentId: user?.id }));
  }, [data?.params, dispatch, user?.id, courseName]);

  function calculateTotalGrade(data) {
    let totalGrade = 0;
    for (const item of data) {
      const grade =
        item.Grade === "-" || item.Grade.trim() === ""
          ? 0
          : parseInt(item.Grade, 10);
      totalGrade += grade;
    }
    return totalGrade;
  }

  function calculateMaximumScore(data) {
    let MaximumScore = 0;
    for (const item of data) {
      const maxScore =
        item.MaximumScore === "-" || item.MaximumScore.trim() === ""
          ? 0
          : parseInt(item.MaximumScore, 10);
      MaximumScore += maxScore;
    }
    return MaximumScore;
  }
  const totalGrade = calculateTotalGrade(stdGradeCourse);
  const MaximumScore = calculateMaximumScore(stdGradeCourse);

  const tableProps = {
    data: stdGradeCourse,
    title: {
      Title: "Title",
      type: "type",
      Grade: "Grade",
      MaximumScore: "Maximum Score",
      Feedback: "Feedback",
      status: "status",
    },
    dataKeys: ["Title", "type", "Grade", "MaximumScore", "Feedback", "status"],
    headerTitle: `Grades`,
    otherBtn: false,
    backBTN: true,
    sumTable: true,
    totalGrade,
    MaximumScore,
    headerTitleColor: "#E53E3E",
    transformRow: (item) => ({
      Title: item.Title,
      type: item.type,
      Grade: item.Grade,
      MaximumScore: item.MaximumScore,
      Feedback: item.Feedback,
      status: (
        <Text
          color={
            item.status === "Not Graded"
              ? "#0B82F8"
              : item.status === "Passed"
              ? "#5CBF22"
              : item.status === "Failed"
              ? "#D40E1F"
              : ""
          }
          letterSpacing={"0.32px"}
        >
          {item.status}
        </Text>
      ),
    }),
  };

  return (
    <>
      <PageTitle title="Grades" />
      <CustomTable2 {...tableProps} />
    </>
  );
};

export default StudentGradeSubject;
