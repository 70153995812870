import { BASE_URL, BASE_URL0 } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const addCourse = async (payload) => {
    return await httpRequest(`${BASE_URL}/course`, "post", payload);
};
export const editCourse = async (payload, id) => {
    return await httpRequest(`${BASE_URL}/course/${id}`, "put", payload);
};
export const addCourseReview = async (payload) => {
    return await httpRequest(`${BASE_URL}/coursereview`, "post", payload);
};
export const deleteCourse = async (id) => {
    return await httpRequest(`${BASE_URL}/course/${id}`, "delete");
};

export const scheduleLiveClass = async (payload) => {
    return await httpRequest(`${BASE_URL}/liveclass`, "post", payload);
};

export const updateLiveClass = async (payload) => {
    return await httpRequest(`${BASE_URL}/liveclass/${payload?.id}`, "put", payload);
};

export const deleteLiveClass = async (id) => {
    return await httpRequest(`${BASE_URL}/liveclass/${id}`, "delete");
};

export const generateJitsiTokenForLecturer = async (payload) => {
    return await httpRequest(`${BASE_URL}/liveclass/generatejitsitoken`, "post", payload);
};

export const createCouurseEmailTemplate = async (payload) => {
    return await httpRequest(`${BASE_URL0}/customemail/create-email-template?api-version=v1`, "post", payload);
};

export const sendCourseEmail = async (payload) => {
    return await httpRequest(`${BASE_URL0}/customemail/send-email?api-version=1`, "post", payload);
};

export const assesmentById = async (id) => {
    return await httpRequest(`${BASE_URL}/exam/getexistingexam?AssesmentId=${id}`);
};