import {useState} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal } from "../redux/slices/modalSlice";
import { deleteAnnouncement } from "../services/announcementService";
import { fetchAnnouncements } from "../redux/slices/announcementSlice";
import { deleteAcademicSession, deleteCourse, deleteProgramme } from "../services/ProgrammesService";
import { fetchProgrammes, fetchSessions } from "../redux/slices/programmeSlice";
import { fetchCourses } from "../redux/slices/courseSlice";
import toast from "react-hot-toast";
import { removeBookFromLibrary } from "../services/libraryService";
import { fetchLibraryBooks } from "../redux/slices/LibraryBooksSlice";
import { deleteLiveClass } from "../services/courseService";
import { fetchLiveClass } from "../redux/slices/LiveClassSlice";
import { createAssesment, updateAssesment } from "../services/assesmentService";

const ConfirmPopup = () => {
  const dispatch = useDispatch();
  const confirmModal = useSelector((state) => state.modal.confirmModal);
  const assesments = useSelector((state) => state.assesments);
  const [loading, setLoading] = useState(false)

  console.log(confirmModal?.payload);

  const confirmed = async() => {
    let res;
    setLoading(true)
    if(confirmModal.type === "announcement") {
      res = (await deleteAnnouncement(confirmModal.payload)); 
      dispatch(fetchAnnouncements())
    }else if(confirmModal.type === "programme"){
      res = await deleteProgramme(confirmModal.payload)
      dispatch(fetchProgrammes(confirmModal.sessionId))
    }else if(confirmModal.type === "course"){
      res = await deleteCourse(confirmModal.payload)
      dispatch(fetchCourses(confirmModal.sessionId))
    }else if(confirmModal.type === "session"){
      res = await deleteAcademicSession(confirmModal.payload)
      dispatch(fetchSessions())
    }else if(confirmModal.type === "library"){
      res = await removeBookFromLibrary(confirmModal.payload)
      dispatch(fetchLibraryBooks())
    }else if(confirmModal.type === "liveClass"){
      res = await deleteLiveClass(confirmModal.payload?.classId)
      dispatch(fetchLiveClass(confirmModal.payload?.courseId))
    }
    else if(confirmModal.type === "assessment"){
      if(assesments.assesmentId) {
        res = await updateAssesment(assesments)
      }else{
        res = await createAssesment(assesments)
      }
   
      res?.status && window.location.replace(`/upload-questions/${confirmModal.payload}`);
    }
      setLoading(false)
      dispatch(setConfirmModal({ status: false, data: null }));
      if(res?.status === true) {
        toast.success("Operation successful", {duration: 10000})
      }else {
        toast.error("Operation failed", {duration: 10000})
      }
      
  }
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={confirmModal?.status}
      onClose={() => {
        dispatch(setConfirmModal({ status: false, data: null }));
      }}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody px={"50px"}>
          <Heading textAlign={"center"} color={"gray"}>
          {confirmModal?.title ? confirmModal?.title  : " Are you sure?"}  
          </Heading>
          <Text
            fontSize={"20px"}
            color={"gray"}
            textAlign={"center"}
            my={"30px"}
          >
            {confirmModal?.desc ? confirmModal?.desc  : "You won't be able to revert this!"}  
          </Text>

          <Flex justify={"center"} align={"center"} gap={"10px"}>
            <Button
              color={"white"}
              bg={"red"}
              onClick={() =>
                dispatch(setConfirmModal({ status: false, data: null }))
              }
              w={"50%"}
              _hover={{ background: "" }}
              mb={"30px"}
            >
              CANCEL!
            </Button>

            <Button
              color={"white"}
              bg={"#38b000"}
              w={"50%"}
              _hover={{ background: "" }}
              mb={"30px"}
              onClick={confirmed}
              isLoading={loading}
              loadingText="Processing ..."
            >
             {confirmModal?.buttonText ? confirmModal?.buttonText  : "YES, DELETE IT!"} 
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmPopup;
