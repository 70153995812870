import { useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { addAnnouncementSchema } from "../utils/formValidationSchema";
import {
  addAnnouncement,
  updateAnnouncement,
} from "../services/announcementService";
import { useDispatch } from "react-redux";
import { fetchAnnouncements } from "../redux/slices/announcementSlice";
import { setFormModal } from "../redux/slices/modalSlice";
import { formatTime, simpleDateString } from "../utils/func";
import toast from "react-hot-toast";

const AddAnnouncementModal = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    title: data?.title,
    announcementDetails: data?.announcementDetails,
    scheduleDate: simpleDateString(data?.scheduleDate),
    scheduleTime: data?.scheduleTime,
    announcementsGroup: data?.announcements,
  };

  const handleSubmit = async (values) => {
    values.scheduleTime = formatTime(values.scheduleTime);
    values.announcementsGroup = Number(values.announcementsGroup);
    setLoading(true);
    let res;
    if (data) {
      values.announcements = Number(values.announcementsGroup);
      values.id = data.id
      res = await updateAnnouncement(values);
    } else {
      res = await addAnnouncement(values);
    }

    if (res?.status === true) {
      dispatch(fetchAnnouncements());
      dispatch(setFormModal({ status: false }));
      toast.success(res?.message, {duration: 10000})
    } else {
      console.log(res?.data);
      toast.error(res?.message, {duration: 10000});
    }
    setLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addAnnouncementSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <FormControl
            isInvalid={errors.announcementsGroup && touched.announcementsGroup}
            color="black"
          >
            <FormLabel fontSize={"small"}>Select User Group</FormLabel>
            <Select
              bg={"gray.100"}
              fontSize={14}
              name="announcementsGroup"
              onBlur={handleBlur}
              onChange={handleChange}
              borderRadius={"0"}
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              placeholder="Select an Option"
            >
              <option
                selected={values?.announcementsGroup === 0 ? true : false}
                value="0"
              >
                Lecturers
              </option>
              <option
                selected={values?.announcementsGroup === 1 ? true : false}
                value="1"
              >
                Students
              </option>
              <option
                selected={values?.announcementsGroup === 2 ? true : false}
                value="2"
              >
                All
              </option>
            </Select>
            {touched.announcementsGroup && errors.announcementsGroup && (
              <FormErrorMessage>{errors.announcementsGroup}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={errors.title && touched.title}
            color="black"
          >
            <FormLabel fontSize={"small"}>Title</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="title"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.title}
            />
            {touched.title && errors.title && (
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={
              errors.announcementDetails && touched.announcementDetails
            }
            color="black"
          >
            <FormLabel fontSize={"small"}>Announcement details</FormLabel>
            <Textarea
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="announcementDetails"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.announcementDetails}
            />
            {touched.announcementDetails && errors.announcementDetails && (
              <FormErrorMessage>{errors.announcementDetails}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={errors.scheduleDate && touched.scheduleDate}
            color="black"
          >
            <FormLabel fontSize={"small"}>Scheduled Date</FormLabel>
            <Input
              type="date"
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="scheduleDate"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.scheduleDate}
            />
            {touched.scheduleDate && errors.scheduleDate && (
              <FormErrorMessage>{errors.scheduleDate}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={errors.scheduleTime && touched.scheduleTime}
            color="black"
          >
            <FormLabel fontSize={"small"}>Scheduled Time</FormLabel>
            <Input
              type="time"
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="scheduleTime"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.scheduleTime}
            />
            {touched.scheduleTime && errors.scheduleTime && (
              <FormErrorMessage>{errors.scheduleTime}</FormErrorMessage>
            )}
          </FormControl>

          <Button
            type="submit"
            width={"100%"}
            mt={6}
            background={"red.300"}
            _hover={{ background: "#D40E1F" }}
            color={"whiteAlpha.900"}
            isLoading={loading}
            loadingText="Saving ..."
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddAnnouncementModal;
