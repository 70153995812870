import React from 'react'
import PageTitle from '../components/PageTitle'
import StudentCourseTab from '../components/StudentCourseTab'

const StudentCourses = () => {

  return (
    <div>
      <>
      <PageTitle title="Courses"/>
      <StudentCourseTab/>
      </>
    </div>
  )
}

export default StudentCourses