import { Box ,Text, Flex, Divider} from '@chakra-ui/react';
import {FaCommentDots, FaTrash} from 'react-icons/fa';
import {  numberToAlphabet } from "../../utils/func";
import { useDispatch } from 'react-redux';
import { removeQuiz } from '../../redux/slices/assessmentSlice';

const QuestionPreview = ({questionsPayload, remove}) => {
    const dispatch = useDispatch()
    return (
        <div className="mb-5">
        {questionsPayload?.map(({questionText, answersPayloads, questionId}, index) => (
              <Box key={index} border={'1px solid #BEBEBE26'} boxShadow={'0px 16px 40px #758EFE29'}  p={5}>
              <Flex justifyContent={'space-between'} alignItems={'center'} pb={5} >
                  <Flex alignItems={'center'} gap={3}>
                      <FaCommentDots style={{color:'#E8AC28',width: '33px', height: '29px'}} />
                      <Text letterSpacing={'0.36px'} color={'#040505'} fontSize={'20px'} fontWeight={'medium'}>Question {index + 1}</Text>
                  </Flex>
                  {remove && (
                      <Flex alignItems={'center'} gap={'50px'}>
                      
                      <Flex alignItems={'center'} gap={3} cursor={'pointer'} onClick={() => dispatch(removeQuiz(questionId))}>
                          <FaTrash style={{color:'#ff0000',width: '20px', height: '20px'}} />
                          <Text color={'#0405057C'} letterSpacing={'0.29px'} fontSize={'16px'} fontWeight={'medium'}>Remove</Text>
                      </Flex>
                  </Flex>
                  )}
                
              </Flex>
              <Divider/>
              <Box>
                  <Text py={4}>{questionText}</Text>
                  {answersPayloads.map(({answerText, choiceId, isCorrect}, index) => (
                  <Flex alignItems={'center'} key={choiceId} mt={3} 
             
                      style={{
                      backgroundColor:
                        
                          isCorrect
                          ? '#5CBF22' 
                          : 'transparent',
                      color:
                         isCorrect
                          ? '#FFFFFF' 
                          : '#000000', 
                      }}
                  >
                      <Text border={'1px solid #BEBEBE85'} width={'10%'} textAlign={'center'} py={3}>{numberToAlphabet(index + 1)}</Text>
                      <Text border={'1px solid #BEBEBE85'} width={'90%'} py={3} px={3}>{answerText}</Text>
                  </Flex>
                  ))}

            
              </Box>
              
          </Box>
        ))}
  </div>
    );
};

export default QuestionPreview;