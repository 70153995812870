import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  users: null,
  allStudents: null,
  loading: false,
  error: null,
  type: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: "",
  },
  studentParam: {
    pageSize: 20,
    pageNumber: 1,
    keyword: "",
  }
};

export const fetchUsers = createAsyncThunk(
  "user/users",
  async (arg, { getState }) => {
    return httpRequest(`${BASE_URL}/users/getbyrole?roleName=${arg}&pageNumber=${getState()?.users?.params?.pageNumber}&pageSize=${getState()?.users?.params?.pageSize}&search=${getState()?.users?.params?.keyword}`);
  }
);

export const fetchAllStudents= createAsyncThunk(
  "allstudents/allstudents",
  async (arg, { getState }) => {
    console.log(arg)
    return httpRequest(
      `${BASE_URL}/student/get-all`,
      "post",
      {...getState()?.users?.studentParam}
    );
  }
);


const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.users = payload?.data;
    });
    builder.addCase(fetchUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
    
    //students
    builder.addCase(fetchAllStudents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStudents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allStudents = payload?.data;
    });
    builder.addCase(fetchAllStudents.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },

  reducers: {
    clearUserStore: (state) => {
      state.users = null;
      state.loading = true
    },
    updatePagination: (state, {payload}) => {
      state.params.pageNumber = payload;
      state.loading = true
    },
    updateKeyword: (state, {payload}) => {
      state.loading = true
      state.params.keyword = payload;
    },
    updateAllStudentsPagination: (state, { payload }) => {
      state.studentParam.pageNumber = payload;
      state.loading = true;
    },
    updateAllStudentsKeyword: (state, { payload }) => {
      console.log(payload)
      state.loading = true;
      state.studentParam.keyword = payload;
    },

    updateType: (state, {payload}) => {
      state.loading = true
      state.type = payload;
    },
    statusUpdate: (state, {payload}) => {
      state.users = state?.users?.map(user => (
        user.id === payload?.userId ? { ...user, isActive: payload?.activateUser } : user
      ));
    },
  },
});

export default usersSlice.reducer;
export const {
  updatePagination,
  updateKeyword,
  updateAllStudentsPagination,
  updateAllStudentsKeyword,
  updateType,
  statusUpdate,
  clearUserStore
} = usersSlice.actions;
