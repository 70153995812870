import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import avatar from '../assets/images/Ellipse 66.png'
import { setFormModal } from '../redux/slices/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { BsChatDots } from 'react-icons/bs'
import { useEffect } from 'react'
import { fetchCourseReview } from '../redux/slices/courseReviewSlice'
import { useParams } from 'react-router-dom'
import { StarIcon } from '@chakra-ui/icons'

const AboutCourse = ({data}) => {

    let param = useParams();
    let courseId = param?.courseId;
    const dispatch = useDispatch();
    const courseReview = useSelector((state) => state.courseReview);

    useEffect(() => {
        dispatch(fetchCourseReview(courseId))
    }, [courseId, dispatch])
    const courseReviewList = courseReview?.courseReview?.data;

  return (
    <div className=''>
        <p className='mb-4 text-lg font-light text-gray-500 leading-6'>{data?.description}</p>
        <Flex mt={"30px"} gap={"30px"}>
            <p className='text-base font-semibold text-yellow-600'>Duration:</p>
            <p className='text-base'>1 Month</p>
        </Flex>
        <Flex gap={"30px"}>
            <p className='text-base font-semibold text-yellow-600'>Lecturer:</p>
            <p className='text-base'>{`${data?.lecturerName}`}</p>
        </Flex>
        <Flex gap={"20px"}>
            <p className='text-base font-semibold text-yellow-600'>Course Unit:</p>
            <p className='text-base'>{`${data?.unit}`}</p>
        </Flex>
        <Box pt={"30px"}>
            <h3 className='font-semibold border-b-2 pb-1'>Course Review and rating</h3>
            {
                courseReviewList?.length === 0 ? 
                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} h={'300px'}>
                    <BsChatDots style={{width:'48px',height:'46px', color:'#0405055B'}} />
                    <Text color={'#040505'} letterSpacing={'0.43px'} fontSize={'18px'} my={5} fontWeight={'medium'}>Click 
                    <span 
                    onClick={() =>
                        dispatch(
                        setFormModal({
                            status: true,
                            title: "Add Review",
                            type: "addreview",
                            size: "xl",
                            data: courseId,
                        })
                        )
                    }
                    style={{ color:'#0B82F8', cursor:'pointer', marginLeft:"4px" }}
                    > 
                        Here 
                    </span> To Add A Review
                    </Text>
                </Flex> :
                <>
                    {
                        courseReviewList?.map(({id, review, rating, student}) => {
                            const goldStars = new Array(rating).fill(null);
                            const grayStars = new Array(5 - rating).fill(null);
                            return(
                            <Flex paddingY={"20px"} gap={"16px"} alignItems={"flex-start"} key={id}>
                                <Image src={avatar} alt=''/>
                                <Box className='w-full'>
                                    <Flex mb={"10px"} justifyContent={"space-between"}>
                                        <Box>
                                            <h4 className='font-semibold text-gray-600 text-base'>{`${student?.firstName} ${student?.lastName}`}</h4>
                                            <p className='text-sm font-medium text-gray-400'>18 Aug, 2023</p>
                                        </Box>
                                        <Flex gap={"6px"}>
                                            {
                                                goldStars.map((_, index) => (
                                                    <StarIcon fontSize={"12px"} color={"yellow.400"} key={index}/>
                                                ))
                                            }
                                            {
                                                grayStars.length >= 1 && grayStars.map((_, index) => (
                                                    <StarIcon fontSize={"12px"} color={"gray.300"} key={index}/>
                                                ))
                                            }
                                        </Flex>
                                    </Flex>
                                    <p className='text-base text-gray-600'>{review}</p>
                                </Box>
                            </Flex>
                        )})
                    }
                    <Button 
                    mt={"30px"} 
                    width={"200px"} 
                    colorScheme='red'
                    onClick={() =>
                        dispatch(
                        setFormModal({
                            status: true,
                            title: "Add Review",
                            type: "addreview",
                            size: "xl",
                            data: courseId
                        })
                        )
                    }
                    >
                        Give Review
                    </Button>
                </>
            }
        </Box>
    </div>
  )
}

export default AboutCourse