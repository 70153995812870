import { Box, Flex, Icon } from "@chakra-ui/react"
import CourseAccordionn from "./CourseAccordionn"
import { CheckIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { fetchModule } from "../redux/slices/moduleSlice"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import EmptyState from "./EmptyState"

const CourseModuleTab = () => {

    let param = useParams();
    let courseId = param?.courseId;
    const dispatch = useDispatch()
    const modules = useSelector((state) => state.module);

    useEffect(() => {
        dispatch(fetchModule(courseId));
    }, [dispatch, courseId]);

    const moduleArray = modules?.module?.data;
    
  return (
    <Box>
        {
            moduleArray?.length >=1 ? moduleArray?.map(({id, title, description, attachment, link}, index) => (
                <Flex key={id} justifyContent={"space-between"} mb={"30px"} pb={"30px"} borderBottom={"1px solid #d1d1d1"}>
                    <Flex alignItems={"flex-start"} gap={"10px"}>
                        <Icon viewBox='0 0 200 200' color='yellow.500'>
                        <path
                            fill='currentColor'
                            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                        />
                        </Icon>
                        <Box>
                            <h3 className="text-xl font-semibold text-slate-800">{`Module ${index + 1}`}</h3>
                            <p className="text-gray-500 font-semibold my-4">{title}</p>
                            <CourseAccordionn 
                            description={description} 
                            attachment={attachment}
                            link={link}
                            />
                        </Box>
                    </Flex>
                    <CheckIcon color={"yellow.500"}/>
                </Flex>
            ))
            : <EmptyState />
        }
    </Box>
  )
}

export default CourseModuleTab