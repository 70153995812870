import React, { useEffect } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentGrades } from "../redux/slices/studentGradeSlice";
import { updateGradesPagination } from "../redux/slices/gradeSlice";
import CustomTable2 from "../components/CustomTable2";
import ResponsivePagination from "react-responsive-pagination";

function ViewGradesModal({ handleCloseModal, courseId }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state?.studentGrade);
  console.log("here is the student data", data);

  useEffect(() => {
    dispatch(fetchStudentGrades({ courseId, studentId: user?.id }));
  }, [data?.params, dispatch, user?.id, courseId]);

  const tableProps = {
    data: data?.grades?.data,
    title: {
      assessmentTitle: "Assessment Title",
      Type: "Type",
      Score: "Score",
      MaximumScore: "Maximum Score",
      // Feedback: "Feedback",
      // status: "Status",
    },
    dataKeys: [
      "assessmentTitle",
      "Type",
      "Score",
      "MaximumScore",
      // "Feedback",
      // "Status",
    ],
    search: false,
    otherBtn: true,
    headerStrip: false,
    sn: true,
    transformRow: (item) => ({
      assessmentTitle: item?.assesments?.title,
      // title: item?.assessments?.title,
      Type:
        item?.assesments?.type === 0
          ? "Assignment"
          : item?.assesments?.type === 1
          ? "Exam"
          : "Quiz",
      Score: item?.score,
      MaximumScore: item?.assesments?.passMark,
      // Feedback: "You dull, no worry",
      // Status: item?.status,
    }),
  };
  return (
    <Modal isOpen={true} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>View Grade</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box py={"20px"}>
            <CustomTable2 {...tableProps} />
            <div className="mt-5">
              <ResponsivePagination
                current={data.params?.pageNumber}
                total={data?.grades?.totalPages}
                onPageChange={(page) => dispatch(updateGradesPagination(page))}
              />
            </div>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ViewGradesModal;
