import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { BiVideoPlus } from 'react-icons/bi'
import { BsFilePdf } from 'react-icons/bs'

const CourseAccordionn = ({description, link, attachment}) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem borderBottom={"none"} borderTop={"none"}>
            <h2>
            <AccordionButton bg={"blue.50"} color={"blue.500"} borderRadius={"5px"}>
                <Box as="span" flex='1' textAlign='left'>
                View Module content
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <p className='text-sm font-light text-gray-500 leading-6'>{description}</p>
                <h4 className='font-semibold text-sm mt-4 mb-4'>What's Included</h4>
                {
                    attachment && 
                    <Flex mb={3} alignItems={"center"} gap={2}>
                        <BsFilePdf color='' />
                        <a href={`http://efccscholarroom.vatebra.org/${attachment}`} rel="noreferrer"  target='_blank'>
                        Introduction to Mathematics PPT
                        </a>
                    </Flex>
                }
                {
                    link && 
                    <Flex alignItems={"center"} gap={2}>
                        <BiVideoPlus />
                        <p>Mathematics for engineers</p>
                    </Flex>
                }
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
  )
}

export default CourseAccordionn