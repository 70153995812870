import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  adminDashboard: null,
  lecturerDashbaord: null,
  studentDashbaord: null,
  loading: false,
  error: null,
};

export const fetchAdminDashboard = createAsyncThunk(
  "admindashboard/admindashboard",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/dashboard/admin-dashboard`,
      "get"
    );
  }
);

export const fetchLecturerDashboard = createAsyncThunk(
  "lecturerdashboard/lecturerdashboard",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/dashboard/lecturer-dashboard`,
      "get"
    );
  }

);
export const fetchStudentDashboard = createAsyncThunk(
  "studentdashboard/studentdashboard",
  async (arg) => {
    return httpRequest(
      `${BASE_URL}/dashboard/student-dashboard`,
      "get"
    );
  }
);

const dashboardSlice = createSlice({
  name: "admindashboard",
  initialState,
  extraReducers: (builder) => {

    //admin
    builder.addCase(fetchAdminDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdminDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.adminDashboard = payload?.data;
    });
    builder.addCase(fetchAdminDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //lecturer
    builder.addCase(fetchLecturerDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLecturerDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.lecturerDashbaord = payload?.data;
    });
    builder.addCase(fetchLecturerDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //student
    builder.addCase(fetchStudentDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStudentDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.studentDashbaord = payload?.data;
    });
    builder.addCase(fetchStudentDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
    
  },
});

export default dashboardSlice.reducer;
