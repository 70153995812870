import {useEffect} from "react";
import PageTitle from "../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal, setFormModal } from "../redux/slices/modalSlice";
import CustomTable2 from "../components/CustomTable2";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { MdOutlineMoreVert } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { TiDeleteOutline } from 'react-icons/ti';
import { fetchAnnouncements, fetchGroupAnnouncements } from "../redux/slices/announcementSlice";
import { simpleDateString } from "../utils/func";

const Announcement = () => {

  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.announcements);
  const user = useSelector((state) => state.auth.user);
  const role = user?.role;

  useEffect(() => {
    dispatch(fetchAnnouncements())
  }, [announcements.params, dispatch])
  
  useEffect(() => {
    if(role === "Admin"){
      dispatch(fetchAnnouncements())
    }else if(role === "Student"){
      dispatch(fetchGroupAnnouncements(1))
    }else{
      dispatch(fetchGroupAnnouncements(0))
    }
  },[announcements.groupParams, announcements.params, role, dispatch])

  const editAction = (data) => {
    dispatch(
      setFormModal({
        status: true,
        title: "Edit Announcement",
        type: "addAnnouncement",
        data: data,
      })
    );
  };

  const deleteAction = (data) => {
    dispatch(
      setConfirmModal({
        status: true,
        payload: data?.id,
        type: "announcement"
      })
    );
  };

  const announcementData = role === "Admin" ? announcements?.announcements?.data : announcements?.announcementByGroup?.data
  
  const tableProps = {
    data: announcementData,
    title: {
      title: 'Title',
      scheduleDate: 'Event Date',
      scheduleTime: "Event Time",
      moreOption: ""
    },
    dataKeys: ["title", "scheduleDate", "scheduleTime", "moreOption"],
    otherBtn: true,
    otherBtnText: "Add Announcement",
    headerTitle: "",
    loading: announcements?.loading,
    otherBtnAction: () => dispatch(setFormModal({status: true, title: "Create Announcement", type: "addAnnouncement"})),
    transformRow: (item) => ({
      title: item.title,
      scheduleDate: simpleDateString(item.scheduleDate),
      scheduleTime: item.scheduleTime,
      moreOption: (
        <>
          <Menu>
            <MenuButton>
              <MdOutlineMoreVert className="font-bold" />
            </MenuButton>
            <MenuList minWidth={"120px"}>
              <MenuItem gap={3}  onClick={() => editAction(item)}>
                <AiOutlineEdit />
                Edit
              </MenuItem>
              <MenuItem gap={3} onClick={() => deleteAction(item)}>
                <TiDeleteOutline />
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      ),
    }),
  }
  let userType = user?.role === "Student" ? false : true;
  return <div>
    <PageTitle title='Announcement'/>
    <CustomTable2 {...tableProps} headerStrip={userType} searchType={"announcement"}/>
  </div>;
};

export default Announcement;
