import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTimeSpent } from "../redux/slices/assessmentSlice";

function CountdownTimer() {
  const [timeRemaining, setTimeRemaining] = useState(7200); // 2 hours in seconds
  const [timeUsed, setTimeUsed] = useState(0);
  const dispatch = useDispatch();

  const tss = useSelector((state) => state.assesments.timeSpent);
  const examSubmitting = useSelector(
    (state) => state.assesments.examSubmitting
  );
  console.log("tssss", tss);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0 && !examSubmitting) {
        setTimeRemaining(timeRemaining - 1);
        setTimeUsed(7200 - timeRemaining);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining, examSubmitting]);

  useEffect(() => {
    const usedHours = Math.floor(timeUsed / 3600);
    const usedMinutes = Math.floor((timeUsed % 3600) / 60);
    const usedSeconds = timeUsed % 60;

    const totalUsedTime = `${usedHours}h ${usedMinutes}min ${usedSeconds}s`;
    // console.log("total used time", totalUsedTime);
    dispatch(updateTimeSpent(totalUsedTime));
  }, [timeUsed, dispatch]);

  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <div className="py-3 px-8 border border-[#5CBF22] bg-white">
      <div>
        Time Left: {hours}h {minutes}m {seconds}s
      </div>
    </div>
  );
}

export default CountdownTimer;
