import React, { useState, useEffect } from "react";
import PageTitle from "../components/PageTitle";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Text,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Checkbox } from "@chakra-ui/checkbox";
import { setConfirmModal, setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchLibraryBooks } from "../redux/slices/LibraryBooksSlice";
import DotLoader from "../components/loaders/DotLoader";
import EmptyState from "../components/EmptyState";

const LibraryManagement = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [show, setShow] = useState("");
  const [showCheckBox, setShowCheckbox] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    setSelectedIds([])
    setShowCheckbox(false)
    dispatch(fetchLibraryBooks());
  }, [dispatch]);

  const libraryBooks = useSelector((state) => state.libraryBooks);
  const books = libraryBooks?.libraryBooks?.data;

  const onEnter = (id) => {
    setShow(id);
  };
  const onLeave = (id) => {
    setShow("");
  };
  const checkBoxShow = () => {
    setShowCheckbox(true);
  };
  const removeBooks = () => {
    dispatch(
      setConfirmModal({
        status: true,
        payload: selectedIds,
        type: "library",
      })
    );
    
  };

  return (
    <div>
      <PageTitle title="Library" />
      {
        user?.role !== "Student" &&
        <Flex
          mt={3}
          p={3}
          paddingRight={10}
          borderBottomLeftRadius={25}
          borderBottomRightRadius={25}
          gap={3}
          justifyContent={"end"}
          background={"white"}
        >
          {books?.length > 0 && (
            <Button
              background="orange.100"
              width="100px"
              fontSize={12}
              size={"sm"}
              onClick={selectedIds?.length < 1 ? checkBoxShow : removeBooks}
            >
              Delete
            </Button>
          )}

          <Button
            onClick={() => {
              dispatch(
                setFormModal({
                  status: true,
                  title: `Upload Files`,
                  type: "library",
                  size: "2xl",
                })
              );
            }}
            background="green.200"
            width="100px"
            fontSize={12}
            color="green.500"
            size={"sm"}
          >
            Upload
          </Button>
        </Flex>
      }
      {libraryBooks?.loading ? (
        <div
          className="text-center h-[700px] flex flex-col justify-center items-center"
          style={{ width: "100%" }}
        >
          <DotLoader />
        </div>
      ) : !libraryBooks?.loading && books?.length < 1 ? (
        <EmptyState />
      ) : (
        <>
          <Box>
            <Text fontSize="lg" mt={4} fontWeight="hairline">
              Recently Added
            </Text>
            <Grid
              mt={4}
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
                "repeat(5, 1fr)",
              ]}
              gap={10}
            >
              {books
                ?.slice(0, 5)
                .map(
                  ({
                    id,
                    author,
                    coverImagePath,
                    title,
                    description,
                    isPremium,
                    pages,
                    fileMaterialPath
                  }) => (
                    <a href={fileMaterialPath} target="_blank" rel="noreferrer">
                    <GridItem
                      onMouseEnter={() => onEnter(Number(id))}
                      onMouseLeave={() => onLeave(Number(id))}
                      background="#E8E8E8"
                      position="relative"
                      padding={8}
                      key={id}
                    >
                      <Image
                        src={coverImagePath}
                        alt="book cover"
                        boxSize="100%"
                        height={"230px"}
                      />
                      <Box
                        position="absolute"
                        bottom="5"
                        left="2.5"
                        right="2.5"
                        background="white"
                        paddingY={2}
                        paddingX={4}
                      >
                        <Text
                          fontSize="sm"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {title}
                        </Text>
                        <Text
                          fontSize="smaller"
                          mb={1}
                          color="GrayText"
                          textAlign="center"
                        >
                          {author}
                        </Text>
                        {show === id && (
                          <Box marginY={4}>
                            <Text fontSize="11px" color="gray.400">
                              {description}
                            </Text>
                          </Box>
                        )}
                        <Divider />
                        <Flex mt={1}>
                          <Text fontSize="smaller" color="GrayText">
                            {isPremium ? "Premium" : "Free"}
                          </Text>
                          <Spacer />
                          <Text fontSize="smaller" color="GrayText">
                            {pages} Pages
                          </Text>
                        </Flex>
                      </Box>
                    </GridItem>
                    </a>
                  )
                )}
            </Grid>
          </Box>
          <Box>
            <Text fontSize="lg" mt={4} fontWeight="hairline">
              All Books
            </Text>
            <Grid
              mt={4}
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
                "repeat(5, 1fr)",
              ]}
              gap={10}
            >
              {books?.map(
                ({
                  id,
                  author,
                  coverImagePath,
                  title,
                  description,
                  isPremium,
                  pages,
                  fileMaterialPath
                }) => (
                    <a href={fileMaterialPath} target="_blank" rel="noreferrer">
                  <GridItem
                    onMouseEnter={() => onEnter(id)}
                    onMouseLeave={() => onLeave(id)}
                    background="#E8E8E8"
                    position="relative"
                    padding={8}
                    key={id}
                  >
                    {showCheckBox && (
                      <Checkbox
                        position="absolute"
                        top={5}
                        left={2.5}
                        size="sm"
                        colorScheme="cyan"
                        borderColor="#0405057C"
                        value={id}
                        defaultChecked={selectedIds.includes(id)}
                        onChange={() => handleCheckboxChange(id)}
                      />
                    )}
                    <Image
                      src={coverImagePath}
                      alt="book cover"
                      boxSize="100%"
                      height={"230px"}
                    />
                    <Box
                      position="absolute"
                      bottom="5"
                      left="2.5"
                      right="2.5"
                      background="white"
                      paddingY={2}
                      paddingX={4}
                    >
                      <Text fontSize="sm" fontWeight="bold" textAlign="center">
                        {title}
                      </Text>
                      <Text
                        fontSize="smaller"
                        mb={1}
                        color="GrayText"
                        textAlign="center"
                      >
                        {author}
                      </Text>
                      {show === id && (
                        <Box marginY={4}>
                          <Text fontSize="11px" color="gray.400">
                            {description}
                          </Text>
                        </Box>
                      )}
                      <Divider />
                      <Flex mt={1}>
                        <Text fontSize="smaller" color="GrayText">
                          {isPremium ? "Premium" : "Free"}
                        </Text>
                        <Spacer />
                        <Text fontSize="smaller" color="GrayText">
                          {pages} Pages
                        </Text>
                      </Flex>
                    </Box>
                  </GridItem>
                  </a>
                )
              )}
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export default LibraryManagement;
