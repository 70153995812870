import React, { useEffect } from 'react'
import PageTitle from '../components/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModal, setFormModal } from '../redux/slices/modalSlice';
import CustomTable2 from '../components/CustomTable2';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdOutlineMoreVert } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { TiDeleteOutline } from 'react-icons/ti';
import { fetchCourses, updateCoursePagination } from '../redux/slices/courseSlice';
import { useParams } from 'react-router-dom';
import ResponsivePagination from "react-responsive-pagination";

const CourseList = () => {

  const dispatch = useDispatch();
  let param = useParams();
  let programmeId = param?.programmeId;
  let name = param?.name;
  const courses = useSelector((state) => state.courses);

  useEffect(() => {
    dispatch(fetchCourses(programmeId))
  }, [courses?.courseParam, programmeId, dispatch])

  const deleteAction = (data) => {
    dispatch(
      setConfirmModal({
        status: true,
        payload: data?.id,
        type: "course",
        programmeId: programmeId
      })
    );
  };

  const tableProps = {
      data: courses?.courses?.data,
      loading: courses?.loading,
      title: {
        courseName: "Course Name",
        courseCode: "Course Code",
        courseUnit: "Course Unit",
        lecturer: "Lecturer",
        status: "Status",
        moreOption: '',
     
      },
      dataKeys: ["courseName", "courseCode", "courseUnit", "lecturer", "status", "moreOption"],
      otherBtn: true,
      otherBtnText: "Add Course",
      headerTitle: `${name} - Courses (${courses?.courses?.totalCount})`,
      otherBtnAction: () => dispatch(setFormModal({status: true, title: "Add New Course", type: "addCourse", data: programmeId})),
      expectedCsvHeader: ["courseName", "courseCode", "courseUnit", "lecturer", "status",],
    uploadType: "users",
      transformRow: (item) => ({
        courseName: item.courseName,
        courseCode: item.code,
        courseUnit: item.unit,
        lecturer: (
          <div className='flex items-start gap-1'>
            <span>{item?.lecturer?.firstName}</span>
            <span>{item?.lecturer?.lastName}</span>
          </div>
        ),
        status: (
          <div>
            {
              item.isActive ? 
              <p className='px-2 text-green-400 text-sm rounded-full max-w-max'>Active</p>
              : <p className='px-2 text-yellow-400 rounded-full text-sm max-w-max'>Suspended</p>
            }
          </div>
        ),
        moreOption: (
          <>
            <Menu>
              <MenuButton>
                <MdOutlineMoreVert className="font-bold" />
              </MenuButton>
              <MenuList minWidth={"120px"}>
                <MenuItem 
                gap={3} 
                onClick={
                  () => dispatch(setFormModal({
                    status: true, 
                    title: "Edit Course", 
                    type: "editCourse", 
                    data: {name: item?.courseName, 
                      code: item?.code, 
                      id: item?.id, 
                      unit: item?.unit, 
                      description: item?.description, 
                      lecturer: item?.lecturerId, 
                      status: item?.isActive,
                      programme: item?.programmeId,
                      image: item?.image
                    }
                  }))
                  }>
                  <AiOutlineEdit />
                  Edit
                </MenuItem>
                <MenuItem 
                onClick={() => deleteAction(item)}
                gap={3} 
                _hover={{background: "", textColor:"red"}}>
                  <TiDeleteOutline />
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      }),
  };
    
  return (
    <div>
        <PageTitle title="Course management" />
        <CustomTable2 {...tableProps} searchType={"courses"}/>
        <div className="mt-5">
          <ResponsivePagination
            current={courses.courseParams?.pageNumber}
            total={courses?.courses?.totalPages}
            onPageChange={(page) => dispatch(updateCoursePagination(page))}
          />
        </div>
    </div>
  )
}

export default CourseList;