import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  studentCourse: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const fetchCourseByStudent= createAsyncThunk(
  "studentcourse/studentcourse",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/course/get-course-by-student-id`,
      "post",
      {...getState()?.studentCourse?.params, studentId: arg}
    );
  }
);

const studentCourseSlice = createSlice({
  name: "studentcourse",
  initialState,
  reducers: {
    updateStudentsCoursePagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateStudentsCourseKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //students
    builder.addCase(fetchCourseByStudent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseByStudent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.studentCourse = payload?.data;
    });
    builder.addCase(fetchCourseByStudent.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default studentCourseSlice.reducer;
export const { updateStudentsCoursePagination, updateStudentsCourseKeyword } =
  studentCourseSlice.actions;
