import { background } from "../../components/entryFile/imagePath";

const AuthWrapper = ({children, title}) => {
    return (
        <div className="max-h-screen">
        <div className="relative">
          <img
            src={background}
            alt=""
            className="absolute inset-0 -z-10 h-screen w-full object-cover"
          />
        </div>
        <div className="absolute inset-0 -z-10 h-screen w-full bg-blue-500 opacity-40"></div>
        <div className="z-50 max-w-lg mx-3 sm:mx-auto h-screen flex flex-col justify-center">
          <div className="shadow rounded-t bg-white py-12 px-6 sm:px-16">
            <p className="font-bold text-lg mb-6 text-center"> {title}</p>
         {children}
       
          </div>
          <div className="rounded-b bg-gray-300 p-4 flex justify-center">
            <p className="text-sm">Powered by Vatebra Ltd</p>
          </div>
        </div>
      </div>
    );
};

export default AuthWrapper;