import { useState } from "react";
import {
  Input,
  InputGroup,
  Stack,
  InputRightElement,
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { newPasswordSchema } from "../../utils/formValidationSchema";
import { changePassword } from "../../services/usersService";
import { resetPassword } from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/slices/authSlice";

const NewPasswordForm = ({ code, tenant, userId }) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state?.auth?.user?.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let res;
    if (code && userId) {
      res = await resetPassword(userId, values.password, tenant);
    } else {
      const payload = {
        email,
        password: values.password,
      };
      res = await changePassword(payload);
    }
    if (res?.status) {
      alert("Your password was changed successfully");
      dispatch(removeUser());
      navigate("/");
    } else {
      alert(res?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={newPasswordSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, handleBlur, handleChange }) => (
          <Form className="mb-6">
            <Stack spacing={6}>
              <FormControl
                isInvalid={errors.password && touched.password}
                mb={5}
                color="black"
              >
                <InputGroup size="lg">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter New password"
                    variant="flushed"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      background={"none"}
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <ViewIcon w={5} h={5} color="GrayText" />
                      ) : (
                        <ViewOffIcon color={"GrayText"} w={5} h={5} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {touched.password && errors.password && (
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={errors.confirmPassword && touched.confirmPassword}
                mb={5}
                color="black"
              >
                <InputGroup size="lg">
                  <Input
                    pr="4.5rem"
                    type={show1 ? "text" : "password"}
                    placeholder="Confirm password"
                    variant="flushed"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      background={"none"}
                      onClick={() => setShow1(!show1)}
                    >
                      {show1 ? (
                        <ViewIcon w={5} h={5} color="GrayText" />
                      ) : (
                        <ViewOffIcon color={"GrayText"} w={5} h={5} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                )}
              </FormControl>
              {/* <HStack justifyContent="space-between" mt={4}>
                <Link to="/" className="text-primaryOrange">
                  Go to login
                </Link>
              </HStack> */}
              <Button
                isLoading={loading}
                loadingText="Please wait ..."
                colorScheme="orange"
                type="submit"
              >
                Reset Password
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewPasswordForm;
