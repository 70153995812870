import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  currentStep: 0,
  assessmentPayload: {},
  timeSpent: "",
  assesmentId: null,
  questionsPayload: [],
  moreOptions: [],
  qustionType: {
    allinOne: 0,
    oneByOne: 0,
  },
  assesments: null,
  loading: false,
  params: {
    pageSize: 20,
    pageNumber: 1,
    isActive: true,
  },
  examSubmitting: false,
};

export const fetchCourseAssesments = createAsyncThunk(
  "assesments/assesments",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/exam/getactiveassessmentsbycourseid`,
      "post",
      { ...getState()?.assesments?.params, courseId: arg }
    );
  }
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,

  reducers: {
    updateQuizPayload: (state, { payload }) => {
      state.questionsPayload = [...state.questionsPayload, payload];
    },
    addQuizPayload: (state, { payload }) => {
      state.questionsPayload = payload;
    },
    removeQuiz: (state, { payload }) => {
      state.questionsPayload = state.questionsPayload?.filter(
        (questionsPayload) => questionsPayload?.questionId !== payload
      );
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    previousStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    manageAssesmentOptions: (state, { payload }) => {
      const number = Number(payload);
      if (state.moreOptions?.includes(number)) {
        const indexToRemove = state.moreOptions?.indexOf(number);
        state.moreOptions?.splice(indexToRemove, 1);
      } else {
        state.moreOptions?.push(number);
      }
    },
    updateMoreOptions: (state, { payload }) => {
      state.moreOptions = payload;
    },
    updateAssesmentAssessmentPayload: (state, { payload }) => {
      state.assessmentPayload = payload;
    },
    updateAssesmentId: (state, { payload }) => {
      state.assesmentId = payload;
    },
    extendAssesmentAssessmentPayload: (state, { payload }) => {
      console.log("payload", payload);
      state.assessmentPayload.isComplete = payload;
    },
    updateTimeSpent: (state, { payload }) => {
      console.log("timespent payload", payload);
      state.timeSpent = payload;
    },
    updateExamSubmitting: (state, { payload }) => {
      state.examSubmitting = payload;
    },

    resetAssessment: (state) => {
      state.assessmentPayload = {};
      state.currentStep = 0;
      state.questionsPayload = [];
      state.moreOptions = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCourseAssesments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseAssesments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.assesments = payload?.data;
    });
    builder.addCase(fetchCourseAssesments.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default assessmentSlice.reducer;
export const {
  updateQuizPayload,
  removeQuiz,
  nextStep,
  previousStep,
  manageAssesmentOptions,
  updateAssesmentAssessmentPayload,
  extendAssesmentAssessmentPayload,
  updateMoreOptions,
  addQuizPayload,
  resetAssessment,
  updateTimeSpent,
  updateAssesmentId,
  updateExamSubmitting,
} = assessmentSlice.actions;
