import React from "react";
import {
  Box,
  Text,
  Flex,
  Divider,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FaCommentDots, FaRegCommentAlt } from "react-icons/fa";
import { BiSolidFlagAlt } from "react-icons/bi";
import { newQuestionsArr } from "../utils/convertArray";

const StudentQuestions = ({
  currentQuestionIndex,
  currentQuestion,
  handleAnswerSelection,
  selectedAnswers,
  handlePreviousQuestion,
  assessmentQuestion,
  handleNextQuestion,
}) => {
  const N = currentQuestion?.answers?.length;
  const foo = Array.from({ length: N }, (_, i) => String.fromCharCode(97 + i));
  const newAnswerArray = newQuestionsArr(currentQuestion?.answers, foo);

  console.log("assessment question", assessmentQuestion);
  return (
    <>
      <Flex gap={5}>
        <Box
          border={"1px solid #BEBEBE26"}
          boxShadow={"0px 16px 40px #758EFE29"}
          width={"75%"}
          p={5}
        >
          <Flex justifyContent={"space-between"} alignItems={"center"} pb={5}>
            <Flex alignItems={"center"} gap={3}>
              <FaCommentDots
                style={{ color: "#E8AC28", width: "33px", height: "29px" }}
              />
              <Text
                letterSpacing={"0.36px"}
                color={"#040505"}
                fontSize={"20px"}
                fontWeight={"medium"}
              >
                Question {currentQuestionIndex + 1}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={"50px"}>
              <Flex alignItems={"center"} gap={3}>
                <FaRegCommentAlt
                  style={{ color: "#E8AC28", width: "20px", height: "20px" }}
                />
                <Text
                  color={"#0405057C"}
                  letterSpacing={"0.29px"}
                  fontSize={"16px"}
                  fontWeight={"medium"}
                >
                  Comment
                </Text>
              </Flex>
              <Flex alignItems={"center"} gap={3}>
                <BiSolidFlagAlt
                  style={{ color: "#000000", width: "24px", height: "24px" }}
                />
                <Text
                  color={"#0405057C"}
                  letterSpacing={"0.29px"}
                  fontSize={"16px"}
                  fontWeight={"medium"}
                >
                  Flag
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <Box>
            <Text py={4}>{currentQuestion?.questionText}</Text>
            {newAnswerArray?.map(({ isCorrect, answerText, item }, index) => (
              <Flex
                alignItems={"center"}
                key={index}
                mt={3}
                cursor={"pointer"}
                onClick={() =>
                  handleAnswerSelection(isCorrect, answerText, item)
                }
                style={{
                  backgroundColor:
                    selectedAnswers[currentQuestionIndex] &&
                    selectedAnswers[currentQuestionIndex].item === item
                      ? "#5CBF22"
                      : "transparent",
                  color:
                    selectedAnswers[currentQuestionIndex] &&
                    selectedAnswers[currentQuestionIndex].item === item
                      ? "#FFFFFF"
                      : "#000000",
                }}
              >
                <Text
                  border={"1px solid #BEBEBE85"}
                  width={"10%"}
                  textAlign={"center"}
                  py={3}
                >
                  {item?.toUpperCase()}
                </Text>
                <Text
                  border={"1px solid #BEBEBE85"}
                  width={"90%"}
                  py={3}
                  px={3}
                >
                  {answerText}
                </Text>
              </Flex>
            ))}

            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              pt={10}
              pb={5}
            >
              <Button
                bg={"#FFFFFF"}
                border={"1px solid #E8AC28A6"}
                color={"#040505"}
                letterSpacing={"0.65px"}
                fontSize={"18px"}
                fontWeight={"medium"}
                borderRadius={"5px"}
                width={"200px"}
                _hover={{ backgroundColor: "" }}
                onClick={handlePreviousQuestion}
                disabled={currentQuestionIndex === 0}
              >
                Previous Question
              </Button>
              <Button
                bg={"#E8AC286C"}
                color={"#040505"}
                letterSpacing={"0.65px"}
                fontSize={"18px"}
                fontWeight={"medium"}
                borderRadius={"5px"}
                width={"200px"}
                _hover={{ backgroundColor: "#5CBF22", color: "#FFFFFF" }}
                onClick={
                  currentQuestionIndex === assessmentQuestion.length - 1
                    ? handleNextQuestion
                    : handleNextQuestion
                }
                disabled={
                  currentQuestionIndex === assessmentQuestion.length - 1
                }
              >
                {currentQuestionIndex === assessmentQuestion.length - 1
                  ? "Submit"
                  : "Next Question"}
              </Button>
            </Flex>
          </Box>
        </Box>
        <Box width={"25%"}>
          <Text
            color={"#0405057C"}
            letterSpacing={"0.29px"}
            fontSize={"16px"}
            fontWeight={"bold"}
            pb={4}
          >
            Question {currentQuestionIndex + 1}/{assessmentQuestion.length}
          </Text>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={2}>
            {assessmentQuestion.map((_, index) => (
              <GridItem
                key={index}
                bg={
                  currentQuestionIndex === index
                    ? "#E8AC2823"
                    : selectedAnswers[index]
                    ? "#5CBF222D"
                    : "#BEBEBE26"
                }
                rounded={"full"}
                h={"30px"}
                w={"30px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {index + 1}
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Flex>
    </>
  );
};

export default StudentQuestions;
