import { bookcover } from "../components/entryFile/imagePath";

export const studentData = [
  {
    firstName: "Chiamaka",
    lastName: "Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    firstName: "Chiamaka",
    lastName: "Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    firstName: "Chiamaka",
    lastName: "Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    firstName: "Chiamaka",
    lastName: "Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    firstName: "Chiamaka",
    lastName: "Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
];

export const programmeData = [
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
    {
        programmeName: "Business Analysis",
        programmeCode: "MBA",
        duration: 4
    },
]

export const announcementData = [
  {
    name: 'Public holidays coming up',
    datePosted: '18-May-2023',
    timePosted: '08:00Am'
  },
  {
    name: 'Health and Safety Awareness Training',
    datePosted: '18-May-2023',
    timePosted: '08:00Am'
  },
]

export const userData = [
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Super Admin'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Admin'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Lecturer'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Admin'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Lecturer'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Admin'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Lecturer'},
  { firstName: 'Kanu', lastName: 'Odinaka', email:'test@gmail.com', role: 'Admin'},
];

export const courseData = [
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA310',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Rules of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Analyzing and Scoping',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
  {
    courseName: 'Fundamental of Marketing',
    courseCode: 'MBA210',
    courseUnit: '15',
    lecturer: 'Fredrick Leo',
    status: 'Active'
  },
]

export const recentList = [
  {id: 1, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 2, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 3, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 4, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 5, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
]
export const libraryList = [
  {id: 6, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 7, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 8, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 9, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
  {id: 10, photo : bookcover, heading: "Make your bed", author: "Pro. Emmanuel Buknor" },
]

export const courseLists = [
  {id: 1, title: "Module 1", subHeading: "Introduction to Mathematics 1", text: "In this course, you will learn the basic principles of marketing and how they can be applied to real-world situations. You will gain an understanding of key concepts such as market research, consumer behavior, and product…positioning."},
  {id: 2, title: "Module 2", subHeading: "Integration and Differentiation", text: "In this course, you will learn the basic principles of marketing and how they can be applied to real-world situations. You will gain an understanding of key concepts such as market research, consumer behavior, and product…positioning."},
  {id: 3, title: "Module 3", subHeading: "Quadratic Equation", text: "In this course, you will learn the basic principles of marketing and how they can be applied to real-world situations. You will gain an understanding of key concepts such as market research, consumer behavior, and product…positioning."},
]

export const studentRecords = [
  {id : 1, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
  {id : 2, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
  {id : 3, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
  {id : 4, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
  {id : 5, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
  {id : 6, studentName: "Treasure williams", dateSubmitted: "18, May 2023 9:00am", duration: "3h30mins"},
]

export const stdEnrolled = [
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
  {
    studentName: "Chiamaka Odein",
    studentNumber: "2110623977",
    email: "CO@gmail.com",
    programme: "Business Analysis",
    dateEnrolled: "8/10/2023",
  },
];

export const publishRecords = [
  {id : 1, title: "Introduction to Maths", category: "Exam", type: "Mixed", sections: "2", status: "completed"},
  {id : 2, title: "Introduction to Maths", category: "Exam", type: "Mixed", sections: "2", status: "completed"},
  {id : 3, title: "Integration and Differentiation", category: "Assignment", type: "Theory", sections: "2", status: "completed"},
  {id : 4, title: "Algebra", category: "Quiz", type: "MCQ", sections: "1", status: "completed"},
  {id : 5, title: "Introduction to Maths", category: "Exam", type: "Mixed", sections: "2", status: "completed"},
]

export const duration = [1, 2, 3, 4, 5]
export const status = [{id: 1, value: true, label: "Active"}, {id: 2, value : false, label: "Closed"}]
export const stdGrades = [
  {course:"Mathematics"}, {course:"English Language"},{course:"General Studies"}, {course:"Business Analysis"},
  {course:"Literature"}, {course:"Fundamentals of Marketing"}
]

export const stdGradeCourse = [
  {Title: "Theory question on marketing", type:"Assignment", Grade:"-", MaximumScore:" ", Feedback:"-", status:"Not Graded"},
  {Title: "Multiple choice question test", type:"Quiz", Grade:"20", MaximumScore:"30", Feedback:"Great attempt but a bit poor", status:"Passed"},
  {Title: "Final Assessment section", type:"Quiz", Grade:"29", MaximumScore:"30", Feedback:"Good job", status:"Passed"},
  {Title: "Final Assessment section", type:"Exam", Grade:"10", MaximumScore:"30", Feedback:"Nice Context, try harder", status:"Failed"},
  {Title: "Multiple choice question test", type:"Quiz", Grade:"20", MaximumScore:"30", Feedback:"Great attempt but a bit poor", status:"Passed"},
  {Title: "Final Assessment section", type:"Quiz", Grade:"29", MaximumScore:"30", Feedback:"Good job", status:"Passed"},
  {Title: "Final Assessment section", type:"Exam", Grade:"10", MaximumScore:"30", Feedback:"Nice Context, try harder", status:"Failed"},
] 

export const assessmentQuestion = [
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },

  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },

  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },

  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },

  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
  {question:"Which one is not a part of the 4 Ps?", answers:{
    A: 'Product', B: 'Set of activities to deliver customer value and satisfaction', C:'Strategizing and implementing the organization process',
    D: 'Focusing on sales and profit'
    }
  },
]

export const assesmentCategory = [
  {id: 0, value: "Assignment"},
  {id: 1, value: "Exam"},
  {id: 2, value: "Quiz"},
]

export const assesmentType = [
  {id: 0, value: "MultiChoice"},
  // {id: 1, value: "Essay"},
]

export const assesmentOptions = [
  {id: 0, value: "Shuffle Question For Student"},
  {id: 1, value: "Show Result After Exam"},
  {id: 2, value: "Show Student Choices After Exam"},
  {id: 3, value: "Show Feedback To Students"},
]

export const questionTypeView2 = [
  {id: 0, value: "Allow to go to previous screen"},
  {id: 1, value: "Restrict student to question time"},
]

