import { useState } from "react";
import {
  Input,
  InputGroup,
  Stack,
  Button,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { forgotPasswordSchema } from "../../utils/formValidationSchema";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../services/authService";
import { persistAuth } from "../../redux/slices/authSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const ForgotPasswordForm = () => {;
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true)
    setError("")
    const res = (await forgotPassword(values))
    if(res?.status === true) {
      toast.success(res.message)
      dispatch(persistAuth(res?.data))
      navigate("/")
    }else{
      setError(res.message)
    }
    setLoading(false)
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3 mb-4">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, handleBlur, handleChange }) => (
          <Form className="mb-6">
            <Stack spacing={6}>
              <FormControl
                isInvalid={errors.email && touched.email}
                mb={5}
                color="black"
              >
                <InputGroup>
                  <Input
                    size="lg"
                    type="text"
                    name="email"
                    placeholder="Enter Email Address"
                    variant="flushed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputGroup>
                {touched.email && errors.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <Button
                isLoading={loading}
                loadingText="Logging in ..."
                colorScheme="orange"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
