import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const uploadFile = async (payload) => {
  return await httpRequest(`${BASE_URL}/studentfile`, "post", payload);
};

export const editFile = async (payload, id) => {
  return await httpRequest(`${BASE_URL}/studentfile/${id}`, "put", payload);
};
