import { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/minimal.css";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import SessionPolygon from "../assets/images/SessionPolygon";
import {
  fetchSessions,
  updateSessionPagination,
} from "../redux/slices/programmeSlice";
import SearchStrip from "../components/layout/SearchStrip";
import DotLoader from "../components/loaders/DotLoader";
import DropdownModal from "../modals/DropdownModal"

const ProgrammeManagement = () => {
  const dispatch = useDispatch();
  const programmes = useSelector((state) => state.programmes);
  const loading = programmes.loading

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch, programmes.sessionParams]);

  const openModal = () => {
    dispatch(
      setFormModal({
        status: true,
        title: "Add Academic Period",
        type: "addAcademic",
      })
    );
  };


  return (
    <>
      <PageTitle title="Programme Management" />
      <SearchStrip type="session" />
      <div className="bg-white px-3 py-2 mt-1.5 font-bold">
        <h3>Academic period</h3>
      </div>
      {
        loading ?
          <div className="text-center h-[700px] flex flex-col justify-center items-center" style={{ width: "100%" }}>
            <DotLoader />
          </div>
          :
          <div className="bg-white mt-1">
            <div className="max-w-6xl mx-auto">
              <div className="px-4 w-full grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-x-14 md:gap-y-8 lg:gap-x-24 lg:gap-y-14 content-center mt-12 mb-20 font-semibold">
                {programmes?.sessions?.data?.map(
                  ({ id, year, highlightColor }, index) => (
                    <div
                      className="relative bg-[#F3FBEF] shadow-lg flex flex-col justify-end"
                      key={id}
                    >
                      <span className="absolute -top-3.5 -right-3.5 h-8 w-auto">
                        <SessionPolygon fill={highlightColor} />
                      </span>
                      <div className="p-4 mt-24">
                        <p className="text-gray-400">{index + 1}</p>
                        <h3 className="mb-1 text-gray-600">{`${year.substring(0, 4)} Academic Period`}</h3>
                        <Link
                          to={`/programme-management-list/${id}/${year.substring(
                            0,
                            4
                          )}`}
                          className="text-blue-600 font-base text-xs"
                        >
                          View Programme
                        </Link>
                      </div>
                      <span className="absolute bottom-3.5 right-1.5">
                        <DropdownModal 
                        id={id} 
                        year={year} 
                        highlightColor={highlightColor}
                        />
                      </span>
                    </div>
                  )
                )}
                <div
                  onClick={openModal}
                  className="border-solid border-2 h-56 cursor-pointer border-gray-100 items-center flex flex-col justify-center"
                >
                  <AiOutlinePlus />
                  <p className="text-gray-400 font-light">Add Academic Period</p>
                </div>
              </div>
              
            </div>
          </div>
      }
        <div className="mt-5">
          <ResponsivePagination
            current={programmes.sessionParams?.pageNumber}
            total={programmes?.sessions?.totalPages}
            onPageChange={(page) => dispatch(updateSessionPagination(page))}
          />
        </div>
    </>
  );
};

export default ProgrammeManagement;
