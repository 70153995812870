import {createSlice } from '@reduxjs/toolkit'

const initialState = {
    csvData: null,
}

const bulkUploadSlice = createSlice({
    name: 'bulkUpload',
    initialState,
    reducers: {
        saveCsvData: (state, {payload}) => {
            state.csvData = payload
        },
        clearCsvData: (state, {payload}) => {
            state.csvData = null
        },
    }
})

export default bulkUploadSlice.reducer
export const {saveCsvData, clearCsvData} = bulkUploadSlice.actions
