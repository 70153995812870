import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  lecturers: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchLecturers = createAsyncThunk(
  "lecturers/lecturers",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/lecturer/getall`,
      "post",
      getState()?.lecturers?.params
    );
  }
);

const lecturersSlice = createSlice({
  name: "lecturers",
  initialState,
  reducers: {
    updateLecturersPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateLecturersKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //lecturers
    builder.addCase(fetchLecturers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLecturers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.lecturers = payload?.data;
    });
    builder.addCase(fetchLecturers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default lecturersSlice.reducer;
export const { updateLecturersPagination, updateLecturersKeyword } =
  lecturersSlice.actions;
