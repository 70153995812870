import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL0 } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  emailTemplate: null,
  selectedStudents: [],
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const fetchEmailTemplate = createAsyncThunk(
  "emailTemplate/emailTemplate",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL0}/customemail/get-all-email-template-by-courseid?api-version=v1`, "post", {...getState()?.emailTemplate?.params, courseId : arg}
    );
  }
);

const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState,
  reducers: {
    updateCoursePagination: (state, {payload}) => {
      state.courseParams.pageNumber = payload;
      state.loading = true
    },
    updateEmailTemplateKeyword: (state, {payload}) => {
      state.courseParams.keyword = payload;
    },

    clearEmailTemplate: (state) => {
      state.emailTemplate = null;
    },
    manageSelectedStudent: (state, {payload}) => {
      if (state.selectedStudents?.includes(payload)) {
        const indexToRemove = state.selectedStudents?.indexOf(payload);
        state.selectedStudents?.splice(indexToRemove, 1);
      } else {
        state.selectedStudents?.push(payload);
      }
    },

    clearSelectedStudents: (state) => {
      state.selectedStudents = [];
    },
  },
  extraReducers: (builder) => {
    //emailTemplate
    builder.addCase(fetchEmailTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEmailTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.emailTemplate = payload?.data;
    });
    builder.addCase(fetchEmailTemplate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default emailTemplateSlice.reducer;



export const {
    updateCoursePagination,
    updateEmailTemplateKeyword,
    clearEmailTemplate,
    manageSelectedStudent,
    clearSelectedStudents,
} = emailTemplateSlice.actions;
