import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  grades: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchGrades = createAsyncThunk(
  "grades/grades",
  async (arg, { getState }) => {
    console.log("here is arg", arg);
    return httpRequest(`${BASE_URL}/grade`, "post", {
      ...getState()?.grades?.params,
      courseId: arg,
    });
  }
);

const gradesSlice = createSlice({
  name: "grades",
  initialState,
  reducers: {
    updateGradesPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateGradesKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //announcements
    builder.addCase(fetchGrades.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGrades.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.grades = payload?.data;
    });
    builder.addCase(fetchGrades.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default gradesSlice.reducer;
export const { updateGradesKeyword, updateGradesPagination } =
  gradesSlice.actions;
