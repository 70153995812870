import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import BulkUpload from "../forms/BulkUpload";
import { clearCsvData } from "../../redux/slices/bulkUploadSlice";
import AddUser from "../forms/AddUser";
import { setFormModal } from "../../redux/slices/modalSlice";
import AddProgramme from "../forms/AddProgramme";
import AddAnnouncementModal from "../AddAnnouncementModal";
import AddStudents from "../forms/AddStudents";
import AddAcademics from "../forms/AddAcademics";
import CreateEvent from "../forms/CreateEvent";
import NewPasswordForm from "../forms/NewPasswordForm";
import AddCourseModule from "../AddCourseModule";
import CreateLiveClass from "../forms/CreateLiveClass";
import FeedbackModal from "../FeedbackModal";
import SuccesModal from "../../modals/SuccesModal";
import AddCourse from "../forms/AddCourse";
import EditCourse from "../forms/EditCourse";
import EditProgramme from "../forms/EditProgramme";
import EditAcademics from "../forms/EditAcademics";
import AddReview from "../forms/AddReview";
import AssesmentModal from "../../modals/AssesmentModal";
import UploadFiles from "../forms/UploadFiles";
import EnrolledStudent from "../forms/EnrolledStudent";
import StudentUploadFile from "../forms/StudentUploadFile";

const FormModal = () => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useDispatch();
  const formModal = useSelector((state) => state.modal.formModal);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={formModal?.status}
        onClose={() => {
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(clearCsvData());
        }}
        size={formModal?.size}
      >
        <ModalOverlay />
        <ModalContent>
          {formModal?.title && (
            <ModalHeader
              borderBottom={"1px"}
              // borderBottomColor={"#5CBF22"}
              borderBottomColor={"#9361ea"}
              // color={"#5CBF22"}
              color={"#9361ea"}
              fontSize={"medium"}
            >
              {formModal?.title}
            </ModalHeader>
          )}
          <ModalCloseButton />
          <ModalBody pb={6}>
            {formModal?.type === "addProgramme" ? (
              <AddProgramme data={formModal?.data} />
            ) : formModal?.type === "editProgramme" ? (
              <EditProgramme data={formModal?.data} />
            ) : formModal?.type === "adduser" ? (
              <AddUser data={formModal?.data} />
            ) : formModal?.type === "bulkUpload" ? (
              <BulkUpload data={formModal?.data} />
            ) : formModal?.type === "addAnnouncement" ? (
              <AddAnnouncementModal data={formModal?.data} />
            ) : formModal?.type === "addCourse" ? (
              <AddCourse data={formModal?.data} />
            ) : formModal?.type === "editCourse" ? (
              <EditCourse data={formModal?.data} />
            ) : formModal?.type === "addstudent" ? (
              <AddStudents data={formModal?.data} />
            ) : formModal?.type === "addAcademic" ? (
              <AddAcademics />
            ) : formModal?.type === "editAcademic" ? (
              <EditAcademics data={formModal?.data} />
            ) : formModal?.type === "addModule" ? (
              <AddCourseModule data={formModal?.data} />
            ) : formModal?.type === "changePassword" ? (
              <NewPasswordForm />
            ) : formModal?.type === "createEvent" ? (
              <CreateEvent />
            ) : formModal?.type === "addreview" ? (
              <AddReview data={formModal?.data} />
            ) : formModal?.type === "createLiveClass" ? (
              <CreateLiveClass data={formModal?.data} />
            ) : formModal?.type === "library" ? (
              <UploadFiles />
            ) : formModal?.type === "addFeedback" ? (
              <FeedbackModal />
            ) : formModal?.type === "addSuccess" ? (
              <SuccesModal />
            ) : formModal?.type === "library" ? (
              <UploadFiles />
            ) : formModal?.type === "enrolledStudents" ? (
              <EnrolledStudent />
            ) : formModal?.type === "assesment" ? (
              <AssesmentModal
                data={formModal?.data}
                closeModal={() =>
                  dispatch(setFormModal({ status: false, data: null }))
                }
              />
            ) : formModal?.type === "studentFile" ? (
              <StudentUploadFile data={formModal?.data} />
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormModal;
