import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  announcements: null,
  announcementByGroup: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
  groupParams: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchAnnouncements = createAsyncThunk(
  "announcements/announcements",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/announcements/getall`,
      "post",
      getState()?.announcements?.params
    );
  }
);

export const fetchGroupAnnouncements = createAsyncThunk(
  "groupannouncements/groupannouncements",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/announcements/getannoucmentbygroup`,
      "post",
      {...getState()?.announcements?.params, group: arg}
    );
  }
);

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    updateAnnouncementsPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateAnnouncementsKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },

    //Group
    updateGroupAnnouncementsPagination: (state, { payload }) => {
      state.groupParams.pageNumber = payload;
      state.loading = true;
    },
    updateGroupAnnouncementsKeyword: (state, { payload }) => {
      state.groupParams.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //announcements
    builder.addCase(fetchAnnouncements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAnnouncements.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.announcements = payload?.data;
    });
    builder.addCase(fetchAnnouncements.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });

    //Group
    builder.addCase(fetchGroupAnnouncements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGroupAnnouncements.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.announcementByGroup = payload?.data;
    });
    builder.addCase(fetchGroupAnnouncements.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default announcementsSlice.reducer;
export const { 
  updateAnnouncementsPagination, 
  updateAnnouncementsKeyword,
  updateGroupAnnouncementsPagination,
  updateGroupAnnouncementsKeyword
} =
  announcementsSlice.actions;
