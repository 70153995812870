import {useState} from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  HStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { addModuleSchema } from "../utils/formValidationSchema";
import { addModule } from "../services/moduleService";
import { formatTime } from "../utils/func";
import { fetchModule } from "../redux/slices/moduleSlice";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const AddCourseModule = ({data}) => {
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [attachmentError, setAttachmentError] = useState("")

    const dispatch = useDispatch()

    const handleAttachment = (file) => {
      setAttachmentError("")
      const inputFile = file.target.files[0];

  
      if (
        !inputFile?.name.endsWith(".pdf")
      ) {
        setAttachmentError("Only pdfs are acccepted");
        return;
      }
      if (inputFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage({
            name: inputFile?.name,
            extension: ".pdf",
            data: reader.result,
          });
        };
        reader.readAsDataURL(inputFile);
      }
    };



  const initialValues = {
    title: "",
    description: "",
    link: "",
    scheduleDate: "",
    scheduleTime: "",
  };

  const handleSubmit = async (values) => {
    values.courseId = data
    values.scheduleTime = formatTime(values.scheduleTime);
    values.image = image
    setLoading(true)
    const res = await addModule(values)
    console.log(res);
    if(res?.status === true) {
      dispatch(fetchModule(data))
      toast.success(res?.message, {duration: 10000})
      dispatch(setFormModal({ status: false }));
    }else {
      toast.error("OOps, something went wrong. Try again", {duration: 10000})
    }
    setLoading(false)
  };



  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addModuleSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, handleBlur, handleChange }) => (
          <Form>
            <FormControl isInvalid={errors.title && touched.title}>
              <FormLabel fontSize={"small"}>Module Title</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="title"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.title && errors.title && (
                <FormErrorMessage>{errors.title}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.description && touched.description}
            >
              <FormLabel fontSize={"small"}>Module Description</FormLabel>
              <Textarea
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="description"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.description && errors.description && (
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.link && touched.link}>
              <FormLabel fontSize={"small"}>Add Link(optional)</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="link"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.link && errors.link && (
                <FormErrorMessage>{errors.link}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontSize={"small"}>
                Upload Attachment(Optional)
              </FormLabel>
              <Input
                placeholder="Enter Here"
                type="file"
                fontSize={"small"}
                bg={"gray.100"}
                onChange={handleAttachment}
                accept="application/pdf"
              />
                {attachmentError && (
                  <span className="text-pink-800">{attachmentError}</span>
                )}
            </FormControl>
            <HStack>
              <FormControl
                mt={4}
                isInvalid={errors.scheduleDate && touched.scheduleDate}
              >
                <FormLabel fontSize={"small"}>Scheduled Date</FormLabel>
                <Input
                  type="date"
                  placeholder="Choose Date"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="scheduleDate"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.scheduleDate && errors.scheduleDate && (
                  <FormErrorMessage>{errors.scheduleDate}</FormErrorMessage>
                )}
              </FormControl>
              scheduleTime
              <FormControl
                mt={4}
                isInvalid={errors.scheduleTime && touched.description}
              >
                <FormLabel fontSize={"small"}>Scheduled Time</FormLabel>
                <Input
                  type="time"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="scheduleTime"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.scheduleTime && errors.scheduleTime && (
                  <FormErrorMessage>{errors.scheduleTime}</FormErrorMessage>
                )}
              </FormControl>
            </HStack>
            <Button
              type="submit"
              width={"100%"}
              mt={6}
              background={"#E53E3E"}
              _hover={{ background: "#D40E1F" }}
              color={"whiteAlpha.900"}
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCourseModule;
