import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import EmptyState from "./EmptyState";
import DotLoader from "./loaders/DotLoader";
import SearchStrip from "./layout/SearchStrip";
import PageHeaderStrip from "./layout/PageHeaderStrip";
import {PiSigmaLight} from 'react-icons/pi';

const CustomTable2 = ({ sn = true, data, dataKeys, transformRow, pageNumber, pageCount, title, loading, search=true, search_placeholder, headerStrip=true, headerTitle,headerTitleColor, otherBtnText, otherBtnAction, expectedCsvHeader, uploadType, searchType, bulkProps, backBTN=false, otherBtn=true, sumTable=false, MaximumScore, totalGrade}) => {
  let initialCount = pageNumber && pageCount ? pageNumber * pageCount - pageCount : 0;
  return (
    <>
    {search && (
        <SearchStrip search_placeholder={search_placeholder} type={searchType} backBTN={backBTN}/>
    )}

    {headerStrip && (
      <PageHeaderStrip 
      headerTitleColor={headerTitleColor}
      headerTitle={headerTitle} 
      otherBtn={otherBtn} 
      otherBtnText={otherBtnText} 
      otherBtnAction={otherBtnAction} 
      expectedCsvHeader={expectedCsvHeader} 
      uploadType={uploadType}
      bulkProps={bulkProps}
      />
    )}
    <TableContainer className="text-xs font-light">
      <Table colorScheme="gray" variant="striped" className="bg-white">
        <Thead 
        // background="#F3FBEF"
        >
          <Tr 
          className="bg-atlasLight"
          // color="green.300"
          >
            {sn && <Th style={{ maxWidth: 70, paddingLeft: 15 }} className="text-altasColor" >#</Th>}
            {dataKeys?.map((item, titleKey) => (
              <Th 
                key={titleKey} 
                className="text-altasColor"
              >
                {title[item]}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.length >= 1 ? (
            data?.map((row) => {
              const rowObj = transformRow ? transformRow(row) : row;
              initialCount++;
              return (
                <Tr key={initialCount}>
                  {sn && (
                    <Td
                      style={{ maxWidth: 70, paddingLeft: 15 }}
                      className="text-primary"
                    >
                      <b className="text-base">{initialCount}.</b>
                    </Td>
                  )}
                  {dataKeys.map((item, itemKey) => (
                    <Td key={itemKey} fontSize={"14px"}>{rowObj[item]}</Td>
                  ))}
                </Tr>
              );
            })
          ) : loading ? (
            <Tr>
              <Td colSpan={dataKeys?.length + 1}>
                <div className="text-center" style={{ width: "100%" }}>
                  <DotLoader />
                </div>
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Td style={{ color: "red" }} colSpan={dataKeys?.length + 1}>
                <EmptyState otherBtnText={otherBtnText}  />
              </Td>
            </Tr>
          )}

           {sumTable && 
            <Tr color={'#040505CF'} fontWeight={'900'} fontSize={'16px'} letterSpacing={'0.32px'} bg={'#BEBEBE26'} >
              <Td colSpan={3}  textAlign={'center'} >
                <Button leftIcon={<PiSigmaLight style={{color:'#040505CF', width: '15px', height: '20px',}} />} gap={3} color={'#040505CF'}
                  bg={'transparent'} _hover={{backgroundColor:'transparent'}}
                >Course Total</Button>
              </Td>
              <Td >{totalGrade}</Td>
              <Td >{MaximumScore}</Td>
              <Td colSpan={3} textAlign={'right'}>Average Score</Td>
            </Tr>
          }
        </Tbody>
      </Table>
    </TableContainer>
    </>
  );
};

export default CustomTable2;
