import { Form, Formik } from "formik";
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Input,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  manageAssesmentOptions,
  nextStep,
  updateAssesmentAssessmentPayload,
} from "../../redux/slices/assessmentSlice";
import { useParams } from "react-router-dom";
import { assesmentSchema } from "../../utils/formValidationSchema";
import {
  assesmentCategory,
  assesmentOptions,
  assesmentType,
  questionTypeView2,
} from "../../data/mock";
import { formatTime, simpleDateString } from "../../utils/func";
const Settings = () => {
  const dispatch = useDispatch();
  const [quesTypeViewValue, setQuesTypeViewValue] = useState("0");

  console.log("quesTypeViewValue", quesTypeViewValue);

  const assesments = useSelector((state) => state.assesments);

  console.log("opy", assesments);


  const qustionType = {
    allinOne: 0,
    oneByOne: 1,
  };

  const assePayload = assesments?.assessmentPayload

  let param = useParams();
  let courseId = param?.courseId;

  const initialValues = {
    category : assePayload?.category,
    type: assePayload?.type,
    courseId,
    title: assePayload?.title,
    instructions: assePayload?.instructions,
    passMark: assePayload?.passMark,
    examDate: assePayload?.examDate,
    startTime: assePayload?.startTime,
    endTime: assePayload?.endTime,
  };

 

  const handleSubmit = (values) => {
    values.type = Number(values?.type);
    values.category = Number(values?.category);
    values.startTime = formatTime(values?.startTime);
    values.endTime = formatTime(values?.endTime);
    values.moreOptions = assesments?.moreOptions;
    values.qustionType = qustionType;
    dispatch(updateAssesmentAssessmentPayload(values));
    dispatch(nextStep());
  };


  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={assesmentSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Flex
            gap={[null, null, 8]}
            flexDirection={["column", "column", "row"]}
            justifyContent={"space-between"}
          >
            <Stack spacing={6} mb={"10"} w={["100%", "100%", "50%"]}>
              <FormControl
                isInvalid={errors.type && touched.type}
                color="black"
              >
                <FormLabel>Assessment type</FormLabel>
                <Select
                  bg={"gray.100"}
                  fontSize={14}
                  placeholder="Select option"
                  type="number"
                  name="type"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {assesmentType?.map(({ id, value }) => (
                    <option key={id} value={id} selected={id === values?.type}>
                      {value}
                    </option>
                  ))}
                </Select>
                {touched.type && errors.type && (
                  <FormErrorMessage>{errors.type}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={errors.category && touched.category}
                color="black"
              >
                <FormLabel>Assessment Category</FormLabel>
                <Select
                  bg={"gray.100"}
                  fontSize={14}
                  placeholder="Select option"
                  name="category"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {assesmentCategory?.map(({ id, value }) => (
                    <option key={id} value={id} selected={id === values?.category}>
                      {value}
                    </option>
                  ))}
                </Select>
                {touched.category && errors.category && (
                  <FormErrorMessage>{errors.category}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.title && touched.title}>
                <FormLabel>Enter title</FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="title"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.title}
                />
                {touched.title && errors.title && (
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={errors.instructions && touched.instructions}
              >
                <FormLabel>Enter Instructions</FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="instructions"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.instructions}
                />
                {touched.instructions && errors.instructions && (
                  <FormErrorMessage>{errors.instructions}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={errors.passMark && touched.passMark}>
                <FormLabel>Enter Passmark</FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  type="number"
                  bg={"gray.100"}
                  name="passMark"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.passMark}
                />
                {touched.passMark && errors.passMark && (
                  <FormErrorMessage>{errors.passMark}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>

            <Stack spacing={6} mb={"10"} w={["100%", "100%", "50%"]}>
              <FormControl isInvalid={errors.examDate && touched.examDate}>
                <FormLabel>Exam Date</FormLabel>
                <Input
                  type="date"
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={"gray.100"}
                  name="examDate"
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={simpleDateString(values?.examDate)}
                />
                {touched.examDate && errors.examDate && (
                  <FormErrorMessage>{errors.examDate}</FormErrorMessage>
                )}
              </FormControl>

              <Flex gap={6} flexDirection={["column", "row", "row"]}>
                <FormControl isInvalid={errors.startTime && touched.startTime}>
                  <FormLabel>Start Time</FormLabel>
                  <Input
                    type="time"
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={"gray.100"}
                    name="startTime"
                    focusBorderColor="#ffffff"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startTime}
                  />
                  {touched.startTime && errors.startTime && (
                    <FormErrorMessage>{errors.startTime}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.endTime && touched.endTime}>
                  <FormLabel>End Time</FormLabel>
                  <Input
                    type="time"
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={"gray.100"}
                    name="endTime"
                    focusBorderColor="#ffffff"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endTime}
                  />
                  {touched.endTime && errors.endTime && (
                    <FormErrorMessage>{errors.endTime}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel>Question type view</FormLabel>
                <RadioGroup
                  focusBorderColor="#ffffff"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.qustionType}
                >
                  <Stack direction="column" gap={4} mt={4}>
                    <RadioGroup
                      onChange={setQuesTypeViewValue}
                      value={quesTypeViewValue}
                    >
                      <Radio
                        alignItems={"flex-start"}
                        value="0"
                        name="qustionType"
                      >
                        <Stack direction={"column"} mt={-1}>
                          <Text fontWeight={"semibold"}>All in One</Text>
                          <Text color={"#0405057C"} fontSize={"sm"}>
                            Show all questions in one screen
                          </Text>
                        </Stack>
                      </Radio>
                      <Radio
                        alignItems={"flex-start"}
                        value="1"
                        name="qustionType"
                      >
                        <Stack direction={"column"} mt={-1}>
                          <Text fontWeight={"semibold"}>One by One</Text>
                          <Stack spacing={3} direction="column">
                            {questionTypeView2?.map(({ id, value }) => (
                              <Checkbox
                                isDisabled={quesTypeViewValue !== "1"}
                                colorScheme="green"
                              >
                                {value}
                              </Checkbox>
                            ))}
                          </Stack>
                        </Stack>
                      </Radio>
                    </RadioGroup>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>More Options</FormLabel>

                <Flex
                  gap={[null, null, 8]}
                  flexDirection={["column", "column", "row"]}
                  justifyContent={"space-between"}
                >
                  {assesmentOptions?.map(({ id, value }) => (
                    <Stack key={id} mb={"10"} w={["100%", "100%", "50%"]}>
                      <Checkbox
                        value={id}
                        colorScheme="green"
                        defaultChecked={assesments?.moreOptions?.includes(id)}
                        onChange={(e) =>
                          dispatch(manageAssesmentOptions(e.target.value)) 
                        }
                      >
                        {value}
                      </Checkbox>
                    </Stack>
                  ))}
                </Flex>

                {/* <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack spacing={3} direction="column">
                    <Checkbox colorScheme="green">
                      Allow to go to previous screen
                    </Checkbox>
                    <Checkbox colorScheme="green">
                      Restrict student to question time
                    </Checkbox>
                  </Stack>
                  <Stack spacing={3} direction="column">
                    <Checkbox colorScheme="green">
                      Allow to go to previous screen
                    </Checkbox>
                    <Checkbox colorScheme="green">
                      Restrict student to question time
                    </Checkbox>
                  </Stack>
                </Stack> */}
              </FormControl>
            </Stack>
          </Flex>

          <Flex
            background="white"
            justifyContent="space-between"
            my={1.5}
            padding={2}
          >
            <span className="flex items-center gap-2 cursor-pointer"></span>
            <HStack cursor="pointer">
              <button
                type="submit"
                to="/upload-questions"
                className="text-[13px] font-semibold bg-[#5CBF22] w-[98px] px-3 py-1.5 text-slate-800 hover:bg-[#D40E1F] hover:text-white rounded-md"
              >
                Next
              </button>
            </HStack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default Settings;
