import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  assessmentCourse: null,
  loading: false,
  error: null,
  assessmentDetail: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const fetchAssessementByCourseId= createAsyncThunk(
  "assessmentcourse/assessmentcourse",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/exam/getactiveassessmentsbycourseid`,
      "POST",
      {isActive: true, courseId: arg}
    );
  }
);

export const fetchAssessementDetail= createAsyncThunk(
  "assessmentdetail/assessmentdetail",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/exam/getexamquestionsbyassessmentid?Id=${arg.id}&studentId=${arg.studentId}`,
      "get",
    );
  }
);

const assessmentByCourseSlice = createSlice({
  name: "assessmentcourse",
  initialState,
  
  extraReducers: (builder) => {
    //assessment
    builder.addCase(fetchAssessementByCourseId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssessementByCourseId.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.assessmentCourse = payload?.data;
    });
    builder.addCase(fetchAssessementByCourseId.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
    //details
    builder.addCase(fetchAssessementDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssessementDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.assessmentDetail = payload?.data;
    });
    builder.addCase(fetchAssessementDetail.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default assessmentByCourseSlice.reducer;
