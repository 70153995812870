import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  liveClass: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,

  },
  currentClass: null
};

export const fetchLiveClass = createAsyncThunk(
  "liveClass/liveClass",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/liveclass/getliveclassbycourseid`,
      "post",
      {...getState()?.liveClass?.params, id: arg}
    );
  }
);

const liveClassSlice = createSlice({
  name: "liveClass",
  initialState,
  reducers: {
    updateLiveClassPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateLiveClassKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
    updateCurrentClass: (state, {payload})  => {
      state.currentClass = payload
    }
  },
  extraReducers: (builder) => {
    //liveClass
    builder.addCase(fetchLiveClass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLiveClass.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.liveClass = payload?.data;
    });
    builder.addCase(fetchLiveClass.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default liveClassSlice.reducer;
export const { updateLiveClassPagination, updateLiveClassKeyword, updateCurrentClass } =
  liveClassSlice.actions;
