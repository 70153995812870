import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGrades,
  updateGradesPagination,
} from "../redux/slices/gradeSlice";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import CustomTable2 from "../components/CustomTable2";
import ResponsivePagination from "react-responsive-pagination";
import { Link } from "react-router-dom";

function ViewSubmissions({ courseId, handleCloseModal }) {
  const dispatch = useDispatch();
  const grades = useSelector((state) => state.grades);
  const gradeList = grades?.grades;

  useEffect(() => {
    dispatch(fetchGrades(courseId));
  }, [grades.params, courseId, dispatch]);

  function formatDate(inputDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Create a Date object from the input date string
    const date = new Date(inputDate);

    // Extract date components
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;

    return formattedDate;
  }

  let status = {
    A: "Excellent",
    B: "Very good",
    C: "Pass",
    D: "Poor",
    E: "Very Poor",
    F: "Fail",
  };

  const tableProps = {
    data: gradeList?.data,
    title: {
      studentName: "Student Name",
      dateSubmitted: "Date Submitted",
      timeSpent: "Time Spent",
      maximumScore: "Maximum Score",
      score: "Score",
      status: "Status",
      action: "",
    },
    dataKeys: [
      "studentName",
      "dateSubmitted",
      "timeSpent",
      "maximumScore",
      "score",
      "status",
      "action",
    ],
    search: false,
    headerStrip: false,
    sn: false,
    transformRow: (item) => ({
      studentName: `${item?.student?.firstName} ${item?.student?.lastName}`,
      dateSubmitted: formatDate(item?.assesments?.uploadedtime),
      type:
        item?.assesments?.type === 0
          ? "Assignment"
          : item?.assesments?.type === 1
          ? "Exam"
          : "Quiz",
      score: item?.score,
      status: (
        <span style={{ color: item.grade === "F" ? "red" : "#5CBF22" }}>
          {status[item?.grade]}
        </span>
      ),
      maximumScore: item?.assesments?.passMark,
      timeSpent: item?.timeSpent,
      action: (
        <>
          <Link className="text-blue-500 underline" to={`/`}>
            View Details
          </Link>
        </>
      ),
      // maximumScore: item?.maximumScore,
    }),
  };

  return (
    <Modal isOpen={true} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>View Submissions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {/* <Flex
              direction={["column", "row", "row"]}
              justifyContent={["flex-start", "space-between", "space-between"]}
            >
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                  >
                    <BiUser size="15px" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Total Students</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="yellow.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                  >
                    <CheckCircleIcon size="15px" color="yellow.500" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Total Present Students</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                    background="red.200"
                  >
                    <WarningIcon size="15px" color="red.500" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Total Absent Students</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Flex
              justifyContent={["flex-start", "space-between", "space-between"]}
            >
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
                mt={5}
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                    background="blue.200"
                  >
                    <CheckIcon size="15px" color="blue.500" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Total Passed Students</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
                mt={5}
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                    background="red.200"
                  >
                    <CloseIcon color="red.500" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Total Failed Students</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                w="220px"
                h="70px"
                border="1px solid"
                borderColor="gray"
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
                mt={5}
              >
                <Flex>
                  <Box
                    borderRadius="full"
                    boxSize="25px"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={2}
                    background="gray.200"
                  >
                    <SettingsIcon color="gray.500" />
                  </Box>

                  <Box fontSize="13px">
                    <Text>Average Score</Text>
                    <Text>30</Text>
                  </Box>
                </Flex>
              </Box>
            </Flex> */}

            <Box py={"20px"}>
              <CustomTable2 {...tableProps} />
              <div className="mt-5">
                <ResponsivePagination
                  current={grades.params?.pageNumber}
                  total={grades?.grades?.totalPages}
                  onPageChange={(page) =>
                    dispatch(updateGradesPagination(page))
                  }
                />
              </div>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ViewSubmissions;
