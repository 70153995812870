import { useState } from "react";
import { Button, FormControl, FormLabel, HStack, Input } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import { editAcademicSession } from "../../services/ProgrammesService";
import { fetchSessions } from "../../redux/slices/programmeSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setFormModal } from "../../redux/slices/modalSlice";

const EditAcademics = ({data}) => {
    
  const [pickyear, setpickYear] = useState("");
  const [show, setShow] = useState(false);
  const [highlightColor, setHighlightColor] = useState(data?.highlightColor);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const currentYear = new Date().getFullYear().toString();

  const saveAcademicSession = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    let now = new Date(pickyear);
    const year = pickyear === "" ? data.year : now.getFullYear().toString();

    const res = await editAcademicSession({id: data?.id, year, highlightColor });

    console.log("res", res);
    if (res?.status === true) {
      dispatch(fetchSessions());
      toast.success("Update successful", {duration: 10000})
    } else {
      toast.error(res?.message, {duration: 10000})
    }
    setLoading(false);
    dispatch(setFormModal({ status: false }));
  };

  return (
    <div>
      <FormControl>
        <FormLabel fontSize={"small"}>Choose Year</FormLabel>
        {
            show ? 
                <ReactDatePicker
                selected={pickyear}
                onChange={(date) => setpickYear(date)}
                showYearPicker
                minDate={new Date(currentYear, 0, 1)}
                dateFormat="yyyy"
                placeholderText="Choose here"
                required
                form="academic-form"
                /> :
                <Input
                    type="text"
                    required
                    placeholder="Enter unit"
                    fontSize={"small"}
                    bg={"gray.100"}
                    name="year"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    value={data?.year}
                    onClick={() => setShow(true)}
                />
        }
        </FormControl>
        <FormControl className="mt-4">
        <FormLabel fontSize={"small"}>Select Hightlight Color</FormLabel>
        <HStack>
          {/* The default icon size is 1em (16px) */}
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#0B82F8" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#0B82F8"
            onClick={() => setHighlightColor("#0B82F8")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#E8AC28" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#E8AC28"
            onClick={() => setHighlightColor("#E8AC28")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#44821F" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#44821F"
            onClick={() => setHighlightColor("#44821F")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#d40e1f" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#d40e1f"
            onClick={() => setHighlightColor("#d40e1f")}
          />
          <Icon
            as={FaCircle}
            backgroundColor={
              highlightColor === "#df15e6" ? "#000000" : "transparent"
            }
            boxSize={6}
            color="#df15e6"
            onClick={() => setHighlightColor("#df15e6")}
          />
        </HStack>
      </FormControl>
      <form id="academic-form" 
      onSubmit={saveAcademicSession}
      >
        <Button
          type="submit"
          width={"100%"}
          // background={"#5CBF22"}
          background={"#9361ea"}
          _hover={{ background: "#D40E1F" }}
          color={"whiteAlpha.900"}
          mt={10}
          isLoading={loading}
          loadingText="Saving ..."
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default EditAcademics;
