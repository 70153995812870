import {useState} from "react";
import {
  Box,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { addUserSchema } from "../../utils/formValidationSchema";
import { addUser, updateUser } from "../../services/usersService";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
import { fetchUsers } from "../../redux/slices/UsersSlice";
import { capitalizeFirstLetter } from "../../utils/func";
import toast from "react-hot-toast";

const AddUser = ({data}) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.users?.type);
  const [loading, setLoading] = useState(false)
  const initialValues = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
  };

  const handleSubmit = async (values) => {
    setLoading(true)
    values.role = capitalizeFirstLetter(type)
    let res;
    if(data) {
      values.id = data.id
      res = await updateUser(values)
    }else {
      res = await addUser(values);
    }
    console.log("res", res);
    if(res?.status === true) {
      dispatch(fetchUsers(type))
      toast.success(res?.message, {duration: 10000})
      dispatch(
        setFormModal({
          status: false,
        })
      );
    }else{
      toast.error(res?.message, {duration: 10000})
    }
    console.log(res?.data);
    setLoading(false)
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addUserSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange, isValid, isSubmitting }) => (
          <Form>
            <FormControl
              isInvalid={errors.firstName && touched.firstName}
              color="black"
            >
              <FormLabel
                color={"#374B43"}
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                First Name
              </FormLabel>
              <Input
                placeholder="Enter here"
                bg={"#E2E2E280"}
                borderRadius={"0"}
                _placeholder={{ color: "#BEBEBE9C" }}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                name="firstName"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
              />
              {touched.firstName && errors.firstName && (
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={errors.lastName && touched.lastName}
              color="black"
              mt={4}
            >
              <FormLabel
                color={"#374B43"}
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Last Name
              </FormLabel>
              <Input
                placeholder="Enter here"
                bg={"#E2E2E280"}
                borderRadius={"0"}
                _placeholder={{ color: "#BEBEBE9C" }}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
              />
              {touched.lastName && errors.lastName && (
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={errors.email && touched.email}
              color="black"
              mt={4}
            >
              <FormLabel
                color={"#374B43"}
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Email Address
              </FormLabel>
              <Input
                type="email"
                placeholder="Enter here"
                bg={"#E2E2E280"}
                borderRadius={"0"}
                _placeholder={{ color: "#BEBEBE9C" }}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
                disabled={data}
              />
              {touched.email && errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              )}
            </FormControl>
            <ModalFooter mb={2}>
              <Button
                width={"100%"}
                // background={"#5CBF22"}
                background={"#9361ea"}
                _hover={{ background: "#D40E1F" }}
                color={"whiteAlpha.900"}
                className="mt-5"
                type="submit"
                isLoading={loading}
                disabled={!isValid || isSubmitting}
                loadingText="Saving..."
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddUser;
