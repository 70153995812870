import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { addProgrammeSchema } from "../../utils/formValidationSchema";
import { duration } from "../../data/mock";
import { addProgramme } from "../../services/ProgrammesService";
import { useState } from "react";
import { setFormModal } from "../../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import { fetchProgrammes } from "../../redux/slices/programmeSlice";
import toast from "react-hot-toast";

const AddProgramme = ({data}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  
  const initialValues = {
    name: null,
    code: null,
    duration: null,
    starDate: null,
    endDate: null,
  };

  const handleSubmit = async (values) => {
    values.sessionId = data
    setLoading(true)
    const res = await addProgramme(values)
    if(res?.status === true) {
      dispatch(fetchProgrammes(data))
      toast.success(res?.message, {duration: 10000})
      dispatch(setFormModal({ status: false }));
    }else {
      toast.error(res?.messages[0], {duration: 10000})
    }
    setLoading(false)
   
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addProgrammeSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <FormControl  isInvalid={errors.name && touched.name}
              color="black">
            <FormLabel fontSize={"small"}>Programme Name</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="name"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.name}
            />
            {touched.name && errors.name && (
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt={4} isInvalid={errors.code && touched.code}
              color="black">
            <FormLabel fontSize={"small"}>Programme Code</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"gray.100"}
              name="code"
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.code}
            />
            {touched.code && errors.code && (
              <FormErrorMessage>{errors.code}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt={4} isInvalid={errors.duration && touched.duration}
              color="black">
            <FormLabel fontSize={"small"}>Duration of Programme</FormLabel>
            <Select
                name="duration"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"gray.100"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                placeholder="Select Duration"
              >
                {duration?.map((dur) => (
                  <option key={dur} value={values?.duration}>{dur === 1 ? "1 year" : `${dur} years`}</option>
                ))}
              </Select>
            {touched.duration && errors.duration && (
              <FormErrorMessage>{errors.duration}</FormErrorMessage>
            )}
          </FormControl>
          <div className="flex gap-8">
            <FormControl mt={4} isInvalid={errors.starDate && touched.starDate}
              color="black">
              <FormLabel fontSize={"small"}>
                Start <span className="text-red-500">*</span>{" "}
              </FormLabel>
              <Input
                type="date"
                required
                placeholder="Pick a Date"
                fontSize={"small"}
                bg={"gray.100"}
                name="starDate"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.starDate}
              />
              {touched.starDate && errors.starDate && (
                <FormErrorMessage>{errors.starDate}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
              color="black">
              <FormLabel fontSize={"small"}>
                End <span className="text-red-500">*</span>{" "}
              </FormLabel>
              <Input
                type="date"
                required
                placeholder="Pick a Date"
                fontSize={"small"}
                bg={"gray.100"}
                name="endDate"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.endDate}
              />
              {touched.endDate && errors.endDate && (
                <FormErrorMessage>{errors.endDate}</FormErrorMessage>
              )}
            </FormControl>
          </div>

          <Button
            width={"100%"}
            // background={"#5CBF22"}
            background={"#9361ea"}
            _hover={{ background: "#D40E1F" }}
            color={"whiteAlpha.900"}
            className="mt-5"
            type="Save"
            isLoading={loading}
            loadingText="Saving ..."
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddProgramme;
