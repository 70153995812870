import {
  Avatar,
  Box,
  Button,
  Center,
  Circle,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsFillChatLeftFill } from "react-icons/bs";
import { MdMenu, MdOutlineLogout, MdSearch } from "react-icons/md";
import { AiFillCaretDown } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

const MobileHeader = ({ onOpen }) => {
  return (
    <Flex
      alignItems={"center"}
      justify={"space-between"}
      display={["flex", "flex", "flex", "none"]}
      h={"4rem"}
      px={[1, 4, 4, 10]}
      boxShadow={"0px 3px 6px #00000029"}
      zIndex={10}
      position={"sticky"}
      top={0}
      left={0}
      bg={"white"}
    >
      <Flex gap={2}>
        <IconButton
          aria-label="Toggle-menu"
          icon={<MdMenu />}
          onClick={onOpen}
        />
        <IconButton aria-label="Search database" icon={<MdSearch />} />
      </Flex>
      <Flex gap={2} alignItems={"center"}>
        <Menu>
          <MenuButton
            size={"sm"}
            h={".7rem"}
            minW={"auto"}
            fontSize={"sm"}
            as={Button}
            p={0}
            bg={"none"}
            _hover={{ bg: "none" }}
            fontWeight={"normal"}
            justifyContent={"start"}
            sx={{ "& > span:first-child": { flexGrow: 0 } }}
          >
            <IconButton
              as={Box}
              aria-label="Search database"
              p={2}
              icon={
                <Flex alignItems={"center"} gap={1} cursor={"pointer"}>
                  <Avatar
                    width={"2rem"}
                    height={"2rem"}
                    name="Thomas Andrew P."
                    src="https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1118.jpg"
                  />
                  <AiFillCaretDown />
                </Flex>
              }
            />
          </MenuButton>
          <MenuList position={"relative"} zIndex={200}>
            <MenuItem>
              <Flex gap={2}  alignItems={"center"}>
                <FaUserCircle />
                <Text>Profile</Text>
              </Flex>
            </MenuItem>
            <MenuDivider />
            <MenuItem>
              <Flex gap={2}  alignItems={"center"}>
                <MdOutlineLogout color="red" />
                <Text>Logout</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>

        <IconButton
          aria-label="Search database"
          icon={
            <Center position={"relative"} cursor={"pointer"}>
              <Circle
                bgColor={"#D40E1F"}
                size={"6px"}
                position={"absolute"}
                top={"-2px"}
                right={"-1px"}
                border={"1px solid white"}
              />
              <BsFillChatLeftFill />
            </Center>
          }
        />
      </Flex>
    </Flex>
  );
};

export default MobileHeader;
