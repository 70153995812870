import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  courses: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const fetchCourseStudentByStatus= createAsyncThunk(
  "coursestatus/coursestatus",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/course/get-course-status-by-student-id`,
      "post",
      {...getState()?.courses?.params, studentId: arg.id, isActive: arg.status}
    );
  }
);

const courseByStatusSlice = createSlice({
  name: "coursestatus",
  initialState,
  reducers: {
    updateCourseStatusPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateCourseStatusKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //students
    builder.addCase(fetchCourseStudentByStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseStudentByStatus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courses = payload?.data;
    });
    builder.addCase(fetchCourseStudentByStatus.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default courseByStatusSlice.reducer;
export const { updateCourseStatusPagination, updateCourseStatusKeyword } =
  courseByStatusSlice.actions;
