import {useEffect, useState} from 'react';
import { Box, Flex, Grid, GridItem, Image, Text, HStack } from '@chakra-ui/react';
import {MdAddCircleOutline} from 'react-icons/md';
import envelope from '../assets/images/envelop-icon.svg'
import EmailTemplate from '../components/layout/EmailTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmailTemplate } from '../redux/slices/emailTempateSlice';
import { fetchStudentsByCourse } from '../redux/slices/studentSslice';


const EmailReminder = () => {
    const navigate = useNavigate();
    const params = useParams()
    const courseId = params?.courseId
    const dispatch = useDispatch()

    
  useEffect(() => {
    dispatch(fetchStudentsByCourse(courseId))
   }, [dispatch, courseId])
    
    const [temp, setTemp] = useState("")


    const templateProps = {
        headText: 'Send instant email',
        inputLabel: 'Subject',
        btnText:'Send',
        headTextColor:'#054828',
    }

    const emailTemplates = useSelector((state) => state.emailTemplate?.emailTemplate?.data);

    useEffect(() => {
     dispatch(fetchEmailTemplate(courseId))
    }, [dispatch, courseId])
    
    
  return (
    <>
        <PageTitle title='Email' />
        <Flex background="white" justifyContent="space-between" mt={2} mb={5} py={3} px={5}>
            <HStack cursor={'pointer'} onClick={() => navigate(-1)}>
                <AiOutlineArrowLeft />
                <Text color={'#374B43'} fontSize={'14px'} >Back</Text>
            </HStack>
        </Flex>
        <Box px={[3,6,10]}>
            <Grid templateColumns={['repeat(1,1fr)', 'repeat(1,1fr)', 'repeat(2,1fr)', 'repeat(3,1fr)', 'repeat(4,1fr)']} gap={6} mx={'auto'} mb={'30px'}>
                <GridItem w={['270px', '270px', '270px', '230px']} mx={['auto', 'auto', 'auto' , '0' ]} >
                    <Flex direction={'column'} onClick={() => navigate(`/course-management/email-reminder/new-email/${courseId}`)}>
                        <Box h={'147px'} bg={'#FFFFFF'} display={'flex'} justifyContent={'center'} alignItems={'center'} cursor={"pointer"} >
                            <MdAddCircleOutline style={{color:'#E53E3E', height:'24px', width:'24px'}}  />
                        </Box>
                        <Text color={'red.500'} fontSize={'16px'} fontWeight={'600'} py={3}>Create new template</Text>
                    </Flex>
                </GridItem>
              {emailTemplates?.map((template) => (
                  <GridItem cursor={"pointer"} onClick={() => setTemp(template)} key={template?.id} w={['270px', '270px', '270px', '230px']} mx={['auto', 'auto','auto', '0' ]} >
                  <Flex direction={'column'}>
                      <Box h={'147px'} bg={'#FFFFFF'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                          <Image src={envelope} alt='Message icon'/>
                      </Box>
                      <Text color={'#5CBF22'} fontSize={'16px'} fontWeight={'600'} py={3}>{template?.title}</Text>
                  </Flex>
              </GridItem>
              ))}
             
            </Grid>
            
            <EmailTemplate temp={temp} {...templateProps} />
            
        </Box>
    </>
  )
}

export default EmailReminder;