import {
  Box,
  Button,
  Flex,
  SlideFade,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { dateInWord } from "../utils/func";
import { setConfirmModal, setFormModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";


const LiveClass = ({user, clas, courseId }) => {
  const { isOpen, onToggle } = useDisclosure();


  const colors = ["#0D5AA7", "#E8AC28", "#44821F"];
  const randomNum = Math.floor(Math.random() * 3);
  const dispatch = useDispatch();



  return (
    <Flex
      p={4}
      pl={8}
      border={"1px solid #BEBEBE56"}
      borderRadius={"5px"}
      position={"relative"}
      h={"8rem"}
      overflow="hidden"
      align={"center"}
      justify={"space-between"}
      onMouseEnter={onToggle}
      onMouseLeave={onToggle}
    >
      <Box
        position={"absolute"}
        w="15px"
        bg={colors[randomNum]}
        left={0}
        top={0}
        bottom={0}
      />
      <Flex direction={"column"}>
        <Text
          fontWeight={"bold"}
          fontSize={"1.25rem"}
          color={colors[randomNum]}
        >
          {clas?.topic}
        </Text>
        <Text color={"#0405057C"}>
          <strong>{dateInWord(clas?.scheduleDate)}, </strong>{" "}
          {clas?.scheduleTime} - {clas?.endTime}
        </Text>
      </Flex>
      <SlideFade in={isOpen} offsetY="20px">
        <Flex
          gap={6}
          align={"center"}
          sx={{
            "& .ghost": {
              variant: "ghost",
              size: "sm",
              display: "flex",
              _hover: { bg: "none" },
            },
          }}
        >
          {
            user === "lecturer" &&
            <Button
              class="ghost"
              leftIcon={<MdSettings color="#BEBEBE" />}
              onClick={() =>
                dispatch(
                  setFormModal({
                    status: true,
                    title: "Update Live Class",
                    type: "createLiveClass",
                    size: "4xl",
                    data: clas,
                  })
                )
              }
            >
              Edit
            </Button>
          }
          {
            user === "lecturer" && 
            <Button
              class="ghost"
              leftIcon={<RiDeleteBin2Fill color="#D40E1F" />}
              onClick={() =>
                dispatch(
                  setConfirmModal({
                    status: true,
                    payload: {classId: clas?.id, courseId},
                    type: "liveClass",
                  })
                )
              }
            >
              Delete
            </Button>
          }
       
       <Link to={`/liveclass-session/${clas?.appId}/${clas?.topic}`}>
            <Button
              bg={"#5CBF22"}
              w="7rem"
              color={"white"}
              flexShrink={1}
              borderRadius={"3px"}
            >
              Start
            </Button>
            </Link>
        
        </Flex>
      </SlideFade>
    </Flex>
  );
};

const LiveClasses = ({user, liveClasses, courseId}) => {
  const classes = liveClasses?.liveClass?.data;
  return (
    <Flex direction={"column"} gap={3}>
      <Text
        // bg={"#F3FBEFA3"}
        bg={"red.50"}
        color={"#0405057C"}
        size={"1.25rem"}
        fontWeight={"bold"}
        p={4}
        mx={-4}
      >
        Today
      </Text>
      {classes?.map((clas) => (
        <LiveClass key={clas?.id} clas={clas} courseId={courseId} user={user}/>
      ))}
    </Flex>
  );
};

export default LiveClasses;
