import { Flex, Image, Text } from "@chakra-ui/react";
import { BiSolidRightArrow } from "react-icons/bi";

const StudentViewCard = ({number, name, iconImg}) => {
  return (
      <>
        <Flex
        borderTopRadius={'20px'}
        bg={"white"}
        grow={1}
        px={4}
        h={"8rem"}
        alignItems={"center"}
        justify={"space-between"}
        >
          <Flex direction={"column"} gap={2}>
            <Text fontSize={"1.64rem"} fontWeight={"bold"}>
              {number}
            </Text>
            <Text color={"#0405057C"} lineHeight={1} fontSize={"1rem"}>
              {name}
            </Text>
          </Flex>
          <Image src={iconImg} width={"2.5rem"} />
        </Flex> 
        <Flex
        mt={2}
        grow={1}
        px={4}
        py={2}
        cursor={"pointer"}
        alignItems={"center"}
        justify={"space-between"}
        >
          <p className="text-sm text-gray-500">View All</p>
          <BiSolidRightArrow className="text-gray-400" />
      </Flex>
    </>
  )
}

export default StudentViewCard