import { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { MdArrowDropDown } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import CourseDetailTab from "../components/CourseDetailTab";
import CourseAssessmentTab from "../components/CourseAssessmentTab";
import CourseResources from "../components/CourseResources";
// import StudentChatForum from './StudentChatForum';
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseDetail } from "../redux/slices/courseDetailSlice";
import { fetchAssessementByCourseId } from "../redux/slices/assessmentByCourseSlice";

const CourseDetails = () => {
  const [show, setShow] = useState(true);
  let param = useParams();
  let courseId = param?.courseId;

  const dispatch = useDispatch();
  const data = useSelector((state) => state.courseDetail);
  const assessment = useSelector((state) => state.assessmentByCourse);

  useEffect(() => {
    dispatch(fetchCourseDetail(courseId));
    dispatch(fetchAssessementByCourseId(courseId));
    dispatch(fetchCourseDetail(courseId));
  }, [courseId, dispatch]);

  const courseDetail = data?.courseDetails;

  console.log("assessment by course", assessment);

  return (
    <div>
      <PageTitle title="Course Management" />
      <Flex
        background="white"
        justifyContent="space-between"
        my={2}
        padding={2}
      >
        <Link to={"/courses"} className="flex items-center gap-2">
          <AiOutlineArrowLeft />
          <Text>Back</Text>
        </Link>
        <HStack cursor="pointer">
          <Text>Sort by</Text>
          <MdArrowDropDown className="h-6 w-auto " />
        </HStack>
      </Flex>
      <Box background="white" paddingY={3}>
        <Tabs
          onChange={() => setShow(true)}
          colorScheme="red"
          orientation="vertical"
        >
          <TabList
            minWidth="200px"
            borderRight={"1px solid #F2F2F2"}
            gap={"16px"}
          >
            <Tab justifyContent="flex-start">Course details</Tab>
            <Tab justifyContent="flex-start">Assessment</Tab>
            <Tab justifyContent="flex-start">Resources</Tab>
            {/* <Tab justifyContent="flex-start" >Chat Forum</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>
              <CourseDetailTab data={courseDetail} />
            </TabPanel>
            <TabPanel>
              <CourseAssessmentTab
                show={show}
                setShow={setShow}
                assessment={assessment?.assessmentCourse}
              />
            </TabPanel>
            <TabPanel>
              <CourseResources />
            </TabPanel>
            {/* <TabPanel>
                        <StudentChatForum />
                    </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

export default CourseDetails;
