import { StarIcon } from '@chakra-ui/icons'
import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'

const SuccesModal = () => {
  return (
    <Stack textAlign={"center"} spacing={6}>
        <StarIcon color={"yellow.300"} margin={"10px auto"}/>
        <Heading fontSize={"1.2rem"}>Bravo</Heading>
        <Text>Your question has been published</Text>
        <Button background={"#5CBF22"} paddingX={6} size={"sm"}>Back</Button>
    </Stack>
  )
}

export default SuccesModal