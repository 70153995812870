import React from "react";
import { useSelector } from "react-redux";
import "../../assets/css/fullPageLoader.css";

const FullPageLoader = () => {
  const loader = useSelector((state) => state.modal?.loader);

  return loader?.status ? (
    <div className="page_processing_modal">
      <div className="loadingio-spinner-spinner-6pgwpb38cth"><div className="ldio-sezv7dtj4zh">
    <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
  </div></div>


      <p style={{ paddingLeft: 0, zIndex: 10 }}>{loader?.message ? loader.message : "Processing, Please wait..."}</p>
    </div>
  ) : null;
};

export default FullPageLoader;
