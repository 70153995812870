import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { confirmEmail } from "../services/authService";
import AuthWrapper from "../components/layout/AuthWrapper";
import NewPasswordForm from "../components/forms/NewPasswordForm";
import { useDispatch } from "react-redux";
import { setLoader } from "../redux/slices/modalSlice";


const ChangePassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get("userId");
  const code = queryParams.get("code");
  const tenant = queryParams.get("tenant");

  useEffect(() => {
    const emailConfirmation = async () => {
      dispatch(setLoader({ status: true }));
      const res = await confirmEmail(tenant, userId, code);
      console.log(res);
      dispatch(setLoader({ status: false }));
    };

    emailConfirmation();
  }, [tenant, code, userId, dispatch]);

  return (
    <AuthWrapper title="Change Password">
      <NewPasswordForm code={code} tenant={tenant} userId={userId}/>
    </AuthWrapper>
  );
};

export default ChangePassword;
