import { Button } from '@chakra-ui/react';

const HoverUserMgtBtn = ({ handleClick, text, bg, ...props }) => {
    
  return (
    <Button
        {...props}
        bg={bg}
        onClick={handleClick}
      >
       {text}
    </Button>
  )
}

export default HoverUserMgtBtn