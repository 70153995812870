import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { createCouurseEmailTemplate, sendCourseEmail } from "../../services/courseService";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { setFormModal } from "../../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedStudents } from "../../redux/slices/emailTempateSlice";

const EmailTemplate = ({
  headText,
  headTextColor,
  inputLabel,
  btnText,
  isSelect = true,
  courseId,
  temp,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [audience, setAudience] = useState("All");
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (temp) {
      setTitle(temp?.title);
      setMessage(temp?.message);
    }
  }, [temp]);

  useEffect(() => {
    if (audience === "Selected") {
      dispatch(
        setFormModal({
          status: true,
          title: "Enrolled Student",
          type: "enrolledStudents",
        })
      );
    }
  }, [audience, dispatch]);

  const navigate = useNavigate();

  const selectedStudents = useSelector((state) => state.emailTemplate?.selectedStudents);
  const students = useSelector((state) => state.students?.students?.data);
  const allStudentIds = students?.map(item => item.id);

  const recipient = audience === "All" ? allStudentIds : selectedStudents

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    setLoading(true);
    if (isSelect) {
        if(recipient?.length < 1) {
          setLoading(false);
            return alert("Mail recipients can't be null")
        }
      res = await sendCourseEmail({subject: title, message, courseId, recipient})
    } else {
      res = await createCouurseEmailTemplate({ title, message, courseId });
    }

    if (res?.status === true) {
      toast.success("Operation successful", {duration: 10000});
      if(!isSelect) {
        navigate(`/course-management/email-reminder/${courseId}`);
      }
     
    } else {
      toast.error(res?.message, {duration: 10000});
    }
    setTitle("")
    setMessage("")
    setAudience("All")
    dispatch(clearSelectedStudents())
    setLoading(false);
  };

  return (
    <Box bg={"#FFFFFF"} px={["20px", "50px", "50px"]} py={"30px"} mb={"50px"}>
      <form onSubmit={handleSubmit}>
        <Box
          border={"1px solid #E2E2E2"}
          py={"30px"}
          px={["20px", "50px", "50px"]}
        >
          <Text
            fontWeight={"600"}
            color={headTextColor}
            fontSize={"24px"}
            borderBottom={"1px solid #E2E2E2"}
            pb={"10px"}
          >
            {headText}
          </Text>

          {isSelect && (
            <Box width={["100%", "60%", "60%", "50%"]}>
              <FormControl
                display={"flex"}
                flexDirection={["column", "row", "row"]}
                mt={"30px"}
                gap={["0", "30px", "30px"]}
              >
                <FormLabel
                  fontSize={"16px"}
                  fontWeight={"600"}
                  color={"#808181"}
                  w={"105px"}
                >
                  Recipients
                </FormLabel>
                <Select
                  borderRadius={"0"}
                  onChange={(e) => setAudience(e.target.value)}
                  placeholder="Select here"
                  bg={"#E2E2E2"}
                  borderColor={"#E2E2E2"}
                  focusBorderColor="#E2E2E2"
                >
                  <option value="All" selected={audience === "All"}>All</option>
                  <option value="Selected" selected={audience === "Selected"}>Selected</option>
                </Select>
              </FormControl>
            </Box>
          )}

          <FormControl
            display={"flex"}
            flexDirection={["column", "row", "row"]}
            mt={"30px"}
            gap={["0", "30px", "30px"]}
          >
            <FormLabel
              fontSize={"16px"}
              fontWeight={"600"}
              color={"#808181"}
              w={"90px"}
            >
              {inputLabel}
            </FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              type="text"
              bg={"#E2E2E2"}
              borderColor={"#E2E2E2"}
              focusBorderColor="#E2E2E2"
              borderRadius={"0"}
            />
          </FormControl>

          <FormControl
            display={"flex"}
            flexDirection={["column", "row", "row"]}
            mt={"30px"}
            gap={["0", "30px", "30px"]}
          >
            <FormLabel
              fontSize={"16px"}
              fontWeight={"600"}
              color={"#808181"}
              w={"90px"}
            >
              Message
            </FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              bg={"#E2E2E2"}
              borderColor={"#E2E2E2"}
              focusBorderColor="#E2E2E2"
              borderRadius={"0"}
              h={"319px"}
            />
          </FormControl>

          <Button
            type="submit"
            // bg={"#5CBF22"}
            bg={"red.300"}
            color={"#F3FBEF"}
            borderRadius={"3px"}
            w={["100%", "200px", "300px"]}
            mt={"30px"}
            ml={["0", "111px", "115px", "118px", "120px"]}
            _hover={{ backgroundColor: "#E53E3E" }}
            isLoading={loading}
            loadingText="Processing..."
          >
            {btnText}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EmailTemplate;
