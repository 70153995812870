import React from 'react'
import PageTitle from '../components/PageTitle'
import CustomTable2 from '../components/CustomTable2'
import { setConfirmModal, setFormModal } from '../redux/slices/modalSlice';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';
import { TiDeleteOutline, TiDocument } from 'react-icons/ti';
import { filelist } from '../data/mockData';
import { useDispatch } from 'react-redux';
import { BsFilePdf, BsFolderFill } from 'react-icons/bs';

const StudentFiles = () => {

    const dispatch = useDispatch();

    const deleteAction = (data) => {
        dispatch(
          setConfirmModal({
            status: true,
            payload: data?.id,
            type: "files",
          })
        );
    };

    const tableProps = {
        data: filelist,
        title: {
          name: "Name",
          size: "Size",
          type: "Type",
          date_modified: "Date Modified",
          action: "Action",
          
        },
        dataKeys: ["name", "size", "type", "date_modified", "action"],
        otherBtn: true,
        otherBtnText: "Upload File",
        headerTitle: "All Files (10)",
        otherBtnAction: () => dispatch(setFormModal({status: true, title: "Upload File", type: "studentFile", size: '2xl'})),
        uploadType: "users",
        transformRow: (item) => ({
          name: (
            <div className='flex items-center gap-4'>
                {
                    item.type === "Folder" 
                    ? <BsFolderFill className='text-yellow-500'/> 
                    : item.type === "PDF" ? <BsFilePdf className='text-red-400'/> 
                    : item.type === "Doc" ? <TiDocument className='text-blue-400'/> 
                    : ''
                }
                <span>{item.name}</span>
            </div>
          ),
          size: item.size,
          type: item.type,
          date_modified: (
            <div className='flex items-start gap-1'>
              <span>{item.date_modified}</span>
            </div>
          ),
          action: (
            <>
              <Menu>
                <MenuButton>
                  <MdOutlineMoreVert className="font-bold" />
                </MenuButton>
                <MenuList minWidth={"120px"}>
                  <MenuItem 
                  gap={3} 
                  onClick={
                    () => dispatch(setFormModal({
                      status: true, 
                      title: "Edit Course", 
                      type: "studentFile", 
                      data: {name: item?.courseName, 
                        code: item?.code, 
                        id: item?.id, 
                        unit: item?.unit, 
                        description: item?.description, 
                        lecturer: item?.lecturerId, 
                        status: item?.isActive,
                        programme: item?.programmeId,
                        image: item?.image
                      }
                    }))
                    }
                    >
                    <AiOutlineEdit />
                    Edit
                  </MenuItem>
                  <MenuItem 
                  onClick={() => deleteAction(item)}
                  gap={3} 
                  _hover={{background: "", textColor:"red"}}>
                    <TiDeleteOutline />
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ),
        }),
    };
  return (
    <>
        <PageTitle title="Files (32)" />
        <CustomTable2 {...tableProps} searchType={"files"}/>
        {/* <div className="mt-5">
          <ResponsivePagination
            current={courses.courseParams?.pageNumber}
            total={courses?.courses?.totalPages}
            onPageChange={(page) => dispatch(updateCoursePagination(page))}
          />
      </div> */}
    </>
  )
}

export default StudentFiles