import { useCallback, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { JaaSMeeting } from "@jitsi/react-sdk";
import React from "react";
import { useSelector } from "react-redux";
import { generateJitsiTokenForLecturer } from "../services/courseService";
import { useParams } from "react-router";

const LiveClassSession = () => {

  const params = useParams()
  const {appId, topic} = params

  
  const user = useSelector((state) => state?.auth?.user);

  const getAdminToken = useCallback(async () => {
    const payload = {
      isModerator: true,
      isLiveStreamingEnabled: true,
      isRecordingEnabled: true,
      isOutboundCallEnabled: true,
      isTranscriptionEnabled: true,
      lecturerId: user?.id,
    };
    const res = await generateJitsiTokenForLecturer(payload);
    console.log(res);
  }, [user?.id])

  useEffect(() => {
    if(user?.role === "Lecturer") {
      getAdminToken()
    }
  }, [user, getAdminToken]);

  return (
    <JaaSMeeting
      appId={appId}
      roomName={topic}
      jwt={null}
      configOverwrite={{
        disableThirdPartyRequests: true,
        disableLocalVideoFlip: true,
        backgroundAlpha: 0.5,
      }}
      interfaceConfigOverwrite={{
        VIDEO_LAYOUT_FIT: "nocrop",
        MOBILE_APP_PROMO: false,
        TILE_VIEW_MAX_COLUMNS: 4,
      }}
      userInfo={{
        displayName: `${user?.firstName} ${user?.lastName}`,
      }}
      spinner={Spinner}
      // onApiReady = { (externalApi) => { ... } }
    />
  );
};

export default LiveClassSession;
