import { Button, Circle, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsCalendar3 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CustomCalendar from "./CustomCalendar";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";

const Event = ({ time, title, duration }) => {
  return (
    <Flex
      p={2}
      justify={"space-between"}
      alignItems={"center"}
      _notLast={{ borderBottom: "1px solid #BEBEBE4D" }}
    >
      <Flex gap={2}>
        <Circle size="10px" bgColor={"#5CBF22"} alignSelf={"start"} mt={2} />
        <Flex direction={"column"}>
          <Text>{time}</Text>
          <Text fontSize={"0.875rem"} color={"#0405057C"}>
            {title}
          </Text>
        </Flex>
      </Flex>
      <Text fontWeight={"bold"} color={"#7E7E7E"} fontSize={"0.875rem"}>
        {duration}
      </Text>
    </Flex>
  );
};

const CalendarEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Flex direction={"column"} bg="white" p={3} grow={1}>
      <Flex justify={"space-between"} align={"center"}>
        <Flex gap={2} align={"center"}>
          <BsCalendar3 />
          <Text fontWeight={"bold"}>Calendar</Text>
        </Flex>
        <Text
          color={"#5CBF22"}
          cursor={"pointer"}
          fontWeight={"bold"}
          fontSize={"sm"}
          onClick={() =>
            dispatch(
              setFormModal({
                status: true,
                title: "Create Event",
                type: "createEvent",
                size: "4xl",
              })
            )
          }
        >
          Add Event
        </Text>
      </Flex>
      <CustomCalendar mx={-3} />
      <Flex direction={"column"}>
        <Flex justify={"space-between"}>
          <Text fontWeight={"bold"}>18 May</Text>
          <Text fontSize={"sm"} color={"#0405057C"}>
            (3 events)
          </Text>
        </Flex>
        {Array(3)
          .fill({
            time: "8:00 am",
            title: "Fundamentals of Marketing",
            duration: "15 mins",
          })
          .map((event, i) => (
            <Event key={i} {...event} />
          ))}
      </Flex>
      <Flex justify={"center"} mt="auto" p={1}>
        <Button
          fontSize={"0.875rem"}
          color={"#5CBF22"}
          p={0}
          background={"none"}
          _hover={{ background: "none" }}
          onClick={() => {
            navigate("/events");
          }}
        >
          View All
        </Button>
      </Flex>
    </Flex>
  );
};

export default CalendarEvents;
