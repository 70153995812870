import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { addCourseSchema } from "../../utils/formValidationSchema";
import { status } from "../../data/mock";
import { addCourse } from "../../services/ProgrammesService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLecturers } from "../../redux/slices/lecturersSlice";
import { setFormModal } from "../../redux/slices/modalSlice";
import { fetchCourses } from "../../redux/slices/courseSlice";

const AddCourse = ({ data }) => {
  
  const lecturers = useSelector((state) => state.lecturers);
  const usersList = lecturers?.lecturers?.data;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState({
    name: "",
    extension: "",
    data: "",
  });

  useEffect(() => {
    dispatch(fetchLecturers());
  }, [dispatch]);

  const initialValues = {
    description: "",
    courseName: "",
    code: "",
    unit: "",
    lecturerId: "",
    programeId: data,
    isActive: "",
  };

  const convertToBase64 = (file) => {
    const inputFile = file.target.files[0];
    if (inputFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        console.log(base64String)
        setImgUrl({
          name: inputFile.name,
          extension: ".jpg",
          data: reader.result,
        });
      };
      reader.readAsDataURL(inputFile);
    }
  };

  const handleSubmit = async (values, resetForm) => {
    const formData = {
      description: values.description,
      courseName: values.courseName,
      code: values.code,
      unit: values.unit,
      isActive: values.isActive === "true" ? true : false,
      programeId: values.programeId,
      lecturerId: values.lecturerId,
      image: imgUrl,
    };
    setLoading(true);
    try {
      const res = await addCourse(formData);
      if (res?.status === true) {
        dispatch(fetchCourses(values.programeId))
        resetForm();
        dispatch(
          setFormModal({
            status: false,
          })
        );
      }
    } catch (error) {
      console.error(error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addCourseSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => {
        return (
          <Form>
            <FormControl
              isInvalid={errors.imageUrl && touched.imageUrl}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Course Thumbnail <span className="text-red-500">*</span>
              </FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="imageUrl"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="file"
                onBlur={handleBlur}
                onChange={(e) => {
                  convertToBase64(e)
                }}
              />
              {touched.imageUrl && errors.imageUrl && (
                <FormErrorMessage>{errors.imageUrl}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={errors.courseName && touched.courseName}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Course Name <span className="text-red-500">*</span>
              </FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="courseName"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.courseName}
              />
              {touched.courseName && errors.courseName && (
                <FormErrorMessage>{errors.courseName}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={errors.description && touched.description}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Course Description <span className="text-red-500">*</span>
              </FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"gray.100"}
                name="description"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.description}
              />
              {touched.description && errors.description && (
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={errors.code && touched.code}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Course Code <span className="text-red-500">*</span>
              </FormLabel>
              <Input
                type="text"
                required
                placeholder=""
                fontSize={"small"}
                bg={"gray.100"}
                name="code"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.code}
              />
              {touched.code && errors.code && (
                <FormErrorMessage>{errors.code}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.unit && touched.unit}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Course Unit <span className="text-red-500">*</span>{" "}
              </FormLabel>
              <Input
                type="text"
                required
                placeholder="Enter unit"
                fontSize={"small"}
                bg={"gray.100"}
                name="unit"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.unit}
              />
              {touched.unit && errors.unit && (
                <FormErrorMessage>{errors.unit}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.lecturerId && touched.lecturerId}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Assign Lecturer to Course{" "}
                <span className="text-red-500">*</span>
              </FormLabel>
              <Select
                name="lecturerId"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"gray.100"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                placeholder="Select Lecturer"
              >
                {usersList?.map(({ id, firstName, lastName }) => (
                  <option
                    key={id}
                    defaultValue={values?.lecturerId}
                    value={id}
                  >{`${firstName} ${lastName}`}</option>
                ))}
              </Select>
              {touched.lecturerId && errors.lecturerId && (
                <FormErrorMessage>{errors.lecturerId}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.isActive && touched.isActive}
              color="black"
            >
              <FormLabel fontSize={"small"}>
                Status <span className="text-red-500">*</span>
              </FormLabel>
              <Select
                name="isActive"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"gray.100"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                placeholder="Select Status"
              >
                {status?.map(({ id, value, label }) => (
                  <option
                    key={id}
                    defaultValue={values?.isActive}
                    value={value}
                  >
                    {label}
                  </option>
                ))}
              </Select>
              {touched.isActive && errors.isActive && (
                <FormErrorMessage>{errors.isActive}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              width={"100%"}
              background={"#9361ea"}
              // background={"#5CBF22"}
              _hover={{ background: "#D40E1F" }}
              color={"whiteAlpha.900"}
              className="mt-5"
              type="submit"
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddCourse;
