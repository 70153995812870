import { Progress } from "@chakra-ui/react"
import { Link } from 'react-router-dom'
import ResponsivePagination from "react-responsive-pagination";
import { useDispatch } from "react-redux"

import maths from '../assets/images/Mathematics.png'
import { updateCourseStatusPagination } from "../redux/slices/courseByStatusSlice"
import EmptyState from "./EmptyState";

const CompletedCourses = ({styling, courseLists, data}) => {

    const dispatch = useDispatch();

    return (
        <div>
           {
                courseLists?.data?.length >= 1 ? 
                <>
                    <div className={styling}>
                        {
                            courseLists?.data?.map(item => (
                                <Link to={`/course-detail/${item?.id}`}key={item?.id}>
                                    <div className="relative ">
                                        <div className="absolute w-[100%] h-[100%] bottom-0 bg-gradient-to-t from-black z-10 opacity-90"></div>
                                        <img src={maths} alt="" />
                                        <div className="relative z-30">
                                            <p className="ml-2 absolute font-semibold bottom-14 text-base text-white">{item?.courseName}</p>
                                            <p className="ml-2 absolute bottom-8 text-sm text-white">{`Unit: ${item?.unit}`}</p>
                                            <Progress
                                                value={75}
                                                size='xs'
                                                colorScheme='green'
                                                className="rounded absolute bottom-6 mr-2 ml-2" 
                                            />
                                        </div>
                                    </div>
                                    <button className="-mt-2 w-full bg-[#5CBF22] h-[50px] flex justify-center text-white text-base items-center rounded-b-lg ">
                                        Continue Course
                                    </button>
                                </Link>
                            )) 
                        }
                    </div>
                    <div className="mt-5">
                        <ResponsivePagination
                            current={data.courseParams?.pageNumber}
                            total={data?.courses?.totalPages}
                            onPageChange={(page) => dispatch(updateCourseStatusPagination(page))}
                        />
                    </div>
                </>
                : <EmptyState/>
            }
        </div>
    )
}

export default CompletedCourses