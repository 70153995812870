import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  students: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    
  },
};

export const fetchStudentsByCourse= createAsyncThunk(
  "students/students",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/student/getallstudentsbycourseid`,
      "post",
      {...getState()?.students?.params, courseId: arg}
    );
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    updateStudentsPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateStudentsKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //students
    builder.addCase(fetchStudentsByCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStudentsByCourse.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.students = payload?.data;
    });
    builder.addCase(fetchStudentsByCourse.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default studentsSlice.reducer;
export const { updateStudentsPagination, updateStudentsKeyword } =
  studentsSlice.actions;
