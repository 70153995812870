import * as yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

export const signInSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").required("Email is required"),
  password: yup.string().required("You must enter password"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").required("Email is required"),
});

export const addUserSchema = yup.object().shape({
  firstName: yup.string().required("Enter User First Name"),
  lastName: yup.string().required("Enter user Last Name"),
  email: yup
    .string()
    .email("Wrong email format")
    .test("is-disallowed", "Only correct mails format are allowed", (value) => {
      if (value) {
        return value.includes(".");
      }
      return true;
    })
    .required("Email is required"),
});

export const addProgrammeSchema = yup.object().shape({
  name: yup.string().required("Programme name is required"),
  code: yup.string().required("Programme code is required"),
  duration: yup.string().required("Programme duration is required"),
  starDate: yup.date().required("Enter start date"),
  endDate: yup
    .date()
    .min(yup.ref("starDate"), "end date cannot be before start date")
    .required("Enter end date"),
});

export const addCourseSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  courseName: yup.string().required("Course name is required"),
  code: yup.string().required("Course code is required"),
  unit: yup.string().required("Unit is required"),
  lecturerId: yup.string().required("Lecturer is required"),
  programeId: yup.string().required("Programme is required"),
  isActive: yup.string().required("Select status"),
});

export const reportSchema = yup.object().shape({
  category: yup.string().required("Pick a category"),
  CourseId: yup
    .string()
    .required("course is required"),
  ProgrammeId: yup.string().required("Programme is required"),
  StartDate: yup.string().required("Select start date"),
  EndDate: yup.string().required("Select end date"),
});

export const addAnnouncementSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  announcementDetails: yup
    .string()
    .required("Announcement details is required"),
  scheduleDate: yup.string().required("Pick a date"),
  scheduleTime: yup.string().required("Select time"),
  announcementsGroup: yup.string().required("Select group"),
});

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(5, "Password must be at least 6 characters long")
    .matches(passwordRules, {
      message: "Password must have at least: 1 uppercase and 1 digit",
    })
    .required("You must enter password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please confirm password"),
});

export const addStudentSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name  is required"),
  gender: yup.string().required("Select gender"),
  regNumber: yup.string().required("Enter registration number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Enter email address"),
  session: yup.string().required("Select session"),
  programmeId: yup.string().required("Select Programme"),
  dateEnrolled: yup.string().required("Pick enrolled date"),
});

export const addModuleSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  title: yup.string().required("Title is required"),
  scheduleDate: yup.string().required("Schedule date is required"),
  scheduleTime: yup.string().required("Schedule time is required"),
  link: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
});

export const liveClassSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  topic: yup.string().required("Topic is required"),
  endTime: yup.string().required("End time is required"),
  scheduleDate: yup.string().required("Schedule date is required"),
  scheduleTime: yup.string().required("Schedule time is required"),

});

export const assesmentSchema = yup.object().shape({
  type: yup.number().required("Assesment type is required"),
  category: yup.string().required("Assesment category is required"),
  title: yup.string().required("Title is required"),
  examDate: yup.string().required("Exam date is required"),
  startTime: yup.string().required("Start time is required"),
  endTime: yup.string().required("End time is required"),
  instructions: yup.string().required("Instructions is required"),
  passMark: yup.string().required("Pass mark is required"),

});


export const ratingSchema = yup.object().shape({
  comment: yup.string().required("comment is required"),
  rating: yup.string().required("ratings is required"),
})
