import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  grades: null,
  loading: false,
  error: null,
  params: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchStudentGrades = createAsyncThunk(
  "studentgrades/studentgrades",
  async (arg, { getState }) => {
    console.log("na arg be this", arg);
    return httpRequest(`${BASE_URL}/grade/studentgrade`, "post", {
      ...getState()?.grades?.params,
      courseId: arg.courseId,
      studentId: arg.studentId,
    });
  }
);

const studentGradeSlice = createSlice({
  name: "studentgrades",
  initialState,
  reducers: {
    updateStudentGradesPagination: (state, { payload }) => {
      state.params.pageNumber = payload;
      state.loading = true;
    },
    updateStudentGradesKeyword: (state, { payload }) => {
      state.params.keyword = payload;
    },
  },
  extraReducers: (builder) => {
    //announcements
    builder.addCase(fetchStudentGrades.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStudentGrades.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.grades = payload?.data;
    });
    builder.addCase(fetchStudentGrades.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default studentGradeSlice.reducer;
export const { updateGradesPagination, updateStudentGradesKeyword } =
  studentGradeSlice.actions;
