import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  libraryBooks: null,
  loading: false,
  error: null,
  libraryBooksParams: {
    pageSize: 20,
    pageNumber: 1,
    keyword: null,
  },
};

export const fetchLibraryBooks = createAsyncThunk(
  "libraryBooks/libraryBooks",
  async (arg, { getState }) => {
    return httpRequest(
      `${BASE_URL}/librarymaterial/getall`, "post", {...getState()?.libraryBooks?.libraryBooksParams}
    );
  }
);

const courseSlice = createSlice({
  name: "libraryBooks",
  initialState,
  reducers: {
    updateCoursePagination: (state, {payload}) => {
      state.libraryBooksParams.pageNumber = payload;
      state.loading = true
    },
    updateLibraryBooksKeyword: (state, {payload}) => {
      state.libraryBooksParams.keyword = payload;
    },

    clearLibraryBooks: (state) => {
      state.libraryBooks = null;
    },
  },
  extraReducers: (builder) => {
    //libraryBooks
    builder.addCase(fetchLibraryBooks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLibraryBooks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.libraryBooks = payload?.data;
    });
    builder.addCase(fetchLibraryBooks.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.message;
    });
  },
});

export default courseSlice.reducer;

export const {
    updateCoursePagination,
    updateLibraryBooksKeyword,
    clearLibraryBooks,
} = courseSlice.actions;
