import React from "react";

const SessionPolygon = ({fill}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.195"
      height="47.642"
      viewBox="0 0 47.195 47.642"
    
    >
      <path
        d="M19.787,1.734a2,2,0,0,1,3.018,0l16.9,19.432A2,2,0,0,1,38.2,24.479H4.391a2,2,0,0,1-1.509-3.313Z"
        transform="matrix(0.695, 0.719, -0.719, 0.695, 17.608, 0)"
        fill={fill}
      />
    </svg>
  );
};

export default SessionPolygon;
