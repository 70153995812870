import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import AboutCourse from './AboutCourse'
import CourseModuleTab from './CourseModuleTab'
import CourseDetailLiveClass from './CourseDetailLiveClass'

const CourseDetailTab = ({data}) => {
  return (
    <div>
        <Box 
        mb={"30px"} 
        bg={"red.100"}
        // bg={'#F3FBEF'}
        borderRadius={'20px 0px 28px 0px'} 
        py={'30px'} px={'30px'}>
            <p className='font-semibold text-2xl text-slate-800'>
                {data?.courseName}
            </p>
        </Box>
        <Tabs colorScheme='red'>
            <TabList minWidth="200px" borderRight={"1px solid #F2F2F2"} gap={"16px"}>
                <Tab justifyContent="flex-start" >About Course</Tab>
                <Tab justifyContent="flex-start">Course Modules</Tab>
                <Tab justifyContent="flex-start">Live Class</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <AboutCourse data={data}/>
                </TabPanel> 
                <TabPanel>
                    <CourseModuleTab />
                </TabPanel>
                <TabPanel>
                    <CourseDetailLiveClass />
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default CourseDetailTab