import { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import CustomTable2 from "../components/CustomTable2";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { Button } from "@chakra-ui/react";
import { IoMdEye, IoMdSettings } from "react-icons/io";
import HoverUserMgtBtn from "../components/layout/HoverUserMgtBtn";
import {
  fetchUsers,
  updatePagination,
  updateAllStudentsPagination,
  updateType,
  clearUserStore,
  fetchAllStudents,
} from "../redux/slices/UsersSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toggleStatus } from "../services/usersService";
import toast from "react-hot-toast";
import ResponsivePagination from "react-responsive-pagination";

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const users = useSelector((state) => state.users);

  let param = useParams();
  let type = param?.type;

  const updateUserStatus = async (payload) => {
    const res = await toggleStatus(payload);
    if (res?.status === true) {
      // dispatch(statusUpdate(payload));
      dispatch(fetchUsers(type))
    } else {
      toast.error(res?.message, {duration: 10000});
    }
  };

  useEffect(() => {
    dispatch(clearUserStore())
    dispatch(updateType(type));
    if(type === 'student'){
      dispatch(fetchAllStudents())
    }else{
      dispatch(fetchUsers(type));
    }
  }, [users?.params, users?.studentParam, type, dispatch]);

  const headerContent = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    actions: "Actions",
    viewCourses: "",
    activate: "",
  };

  const otherBtnAction = () => {
    let formType = type === "student" ?  'addstudent' :  'adduser'
    dispatch(
      setFormModal({
        status: true,
        title: `Create new ${type}`,
        type: formType,
      })
    );
  }

  const editAction = (data) => {
    let formType = type === "student" ?  'addstudent' :  'adduser'
    dispatch(
      setFormModal({
        status: true,
        title: "Edit user",
        type: formType,
        data: data,
      })
    );
  };

  const transformRow = (rowData, index) => {
    return {
      ...rowData,
      actions: (
        <Button
          leftIcon={<IoMdSettings style={{ width: "16px", height: "17px" }} />}
          color={"#0405057C"}
          colorScheme="none"
          letterSpacing={"0.32px"}
          fontSize={"13px"}
          fontWeight={"bold"}
          onClick={() => editAction(rowData)}
        >
          Edit User
        </Button>
      ),

      viewCourses: (
        <>
          {type === "lecturer" && (
            <Button
              leftIcon={
                <IoMdEye
                style={{ width: "16px", height: "17px" }} 
                />
              }
              color={"#0405057C"}
              colorScheme="none"
              letterSpacing={"0.32px"}
              fontSize={"13px"}
              fontWeight={"bold"}
              onClick={() => navigate("/course-management")}
            >
              View Courses
            </Button>
          )}
        </>
      ),

      activate: (
        <HoverUserMgtBtn
          width={"178px"}
          size={"sm"}
          color={"#FFFFFF"}
          fontSize={"13px"}
          fontWeight={"bold"}
          letterSpacing={"0.58px"}
          borderRadius={"3px"}
          _hover={{
            backgroundColor: " none",
          }}
          bg={rowData?.isActive ? "#9361ea" : "#5CBF22"}
          text={rowData?.isActive ? "Deactivate" : "Activate"}
          handleClick={() =>
            updateUserStatus({
              activateUser: !rowData?.isActive,
              userId: rowData?.id,
            })
          }
        />
      ),
    };
  };

  const tableData = type === 'student' ? users?.allStudents?.data : users?.users?.data;
  const tableCount = type === 'student' ? users?.allStudents?.totalCount : users?.users?.totalCount;
  const tableCurrent = type === 'student' ? users?.allStudents?.pageNumber : users?.users?.pageNumber;
  const tableTotal = type === 'student' ? users?.allStudents?.totalPages : users?.users?.totalPages;
  const uploadTypes = type === "student" ? "students" : "users";
  const searchType = type === "student" ? "student" : "users";
  const expectedCsvHeader = type === "student" ? ["Email", "EnrolledCourses", "FirstName", "Gender", "LastName", "ProgrammeName", "RegNumber"] :["firstName", "lastName", "email"]
  
  return (
    <>
      <PageTitle title="User Management" />
      <CustomTable2
        otherBtnText={`Add ${type}`}
        headerTitle={`All ${type}s (${tableCount})`}
        search_placeholder={`Search ${type}s...`}
        dataKeys={Object.keys(headerContent)}
        title={headerContent}
        data={tableData}
        otherBtnAction={otherBtnAction}
        transformRow={transformRow}
        expectedCsvHeader={expectedCsvHeader}
        uploadType={uploadTypes}
        bulkProps= {{"role": type}}
        loading={users?.loading}
        searchType={searchType}
      />
         <div className="mt-5">
          <ResponsivePagination
            current={tableCurrent}
            total={tableTotal}
            onPageChange={(page) => type === "student" ? dispatch(updateAllStudentsPagination(page)) : dispatch(updatePagination(page))}
          />
      </div>
    </>
  );
};

export default UserManagement;
