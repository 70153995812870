import React from 'react'
import PageTitle from '../components/PageTitle'
import { Box, Button, Flex, HStack, Heading, Input, Text } from '@chakra-ui/react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { BsFillChatDotsFill } from 'react-icons/bs'
import Counter from '../components/Counter'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFormModal } from '../redux/slices/modalSlice'

const Submission = () => {

    const dispatch = useDispatch()
    const addFeedback = () => {
        dispatch(setFormModal({status: true, title: "Feeback", type: "addFeedback"}))
    }
  return (
    <div>
        <PageTitle title="Submissions"/>
        <Flex background="white" justifyContent="space-between" my={2} padding={2}>
            <Link to={"/course-management"} className='flex items-center gap-2'>
                <AiOutlineArrowLeft />
                <Text>Back</Text>
            </Link>
            <HStack>
                <Button onClick={() => addFeedback()} bg="#E8AC286B" _hover="#D40E1F" size={"sm"}>Give Feedback</Button>
                <Link to={"/course-management"} className="bg-[#5CBF22] hover:bg-[#D40E1F] text-sm text-white rounded px-8 py-1.5">Save</Link>
            </HStack>
        </Flex>
        <Flex mt={"2.5"} bg={"white"} paddingY={2} paddingX={4} gap={"16"}>
            <Box>
                <Box border={"1px solid #BEBEBE80"} paddingX={3} paddingY={5} borderRadius={5} boxShadow={"md"} mb={5}>
                    <Box>
                        <Flex gap={2}>
                            <BsFillChatDotsFill  className='text-primaryOrange w-6 h-auto' />
                            <Text color="#0405057C" fontWeight={"bold"}>
                                Question 1
                                <span className='font-semibold ml-2'>(10 marks)</span>
                            </Text>
                        </Flex>
                        <Text color="#707070" mt={3}>
                        Suppose that you are considering going to a concert. Tickets cost $90. If you go to the concert, you will have to miss 4 hours of work, where you are making $10 an hour. What is your total opportunity cost of going to the concert measured in dollars given up?
                        </Text>
                    </Box>
                    <Box  mt={5}>
                        <Heading fontSize={"1rem"} mb={2} color="#0405057C">Answer</Heading>
                        <Box border={"1px solid #BEBEBE80"} paddingX={3} paddingY={5} borderRadius={5} color="#707070">
                        Design thinking is a process for practical, creative resolution of problems or issues that looks for an improved future result. It is the essential ability to combine empathy, creativity and rationality to meet user needs and drive business success. Unlike analytical thinking, design thinking is a creative process based around the building up of ideas. There are no judgments early on the design thinking (Simon, 1969, p. 55). Design thinking includes imagination and reason, a combination of convergent and divergent thought, and creativity. Design thinking might be thought of as dialectic, or conversation. It involves design wisdom, judgment, and knowledge. Lastly, design thinking is skill (Hegeman, 2008).
                        </Box>
                    </Box>
                </Box>
                <Box border={"1px solid #BEBEBE80"} paddingX={3} paddingY={5} borderRadius={5} boxShadow={"md"} mb={5}>
                    <Box>
                        <Flex gap={2}>
                            <BsFillChatDotsFill  className='text-primaryOrange w-6 h-auto' />
                            <Text color="#0405057C" fontWeight={"bold"}>
                                Question 2
                                <span className='font-semibold ml-2'>(10 marks)</span>
                            </Text>
                        </Flex>
                        <Text color="#707070" mt={3}>
                        If you go to the concert, you will have to miss 4 hours of work, where you are making $10 an hour. What is your total opportunity cost of going to the concert measured in dollars given up?
                        </Text>
                    </Box>
                    <Box  mt={5}>
                        <Heading fontSize={"1rem"} mb={2} color="#0405057C">Answer</Heading>
                        <Box border={"1px solid #BEBEBE80"} paddingX={3} paddingY={5} borderRadius={5} color="#707070">
                        Design thinking is a process for practical, creative resolution of problems or issues that looks for an improved future result. It is the essential ability to combine empathy, creativity and rationality to meet user needs and drive business success. Unlike analytical thinking, design thinking is a creative process based around the building up of ideas. There are no judgments early on the design thinking (Simon, 1969, p. 55). Design thinking includes imagination and reason, a combination of convergent and divergent thought, and creativity. Design thinking might be thought of as dialectic, or conversation. It involves design wisdom, judgment, and knowledge. Lastly, design thinking is skill (Hegeman, 2008). Design thinking includes imagination and reason, a combination of convergent and divergent thought, and creativity. Design thinking might be thought of as dialectic, or conversation. It involves design wisdom, judgment, and knowledge. Lastly, design thinking is skill (Hegeman, 2008). Design thinking includes imagination and reason, a combination of convergent and divergent thought, and creativity. Design thinking might be thought of as dialectic, or conversation. It involves design wisdom, judgment, and knowledge. Lastly, design thinking is skill (Hegeman, 2008).
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box flex={"1"} mt={"20"}>
                <Flex justifyContent={"space-between"}>
                    <Text color={"#5CBF22"}>Question Number</Text>
                    <Text color={"#5CBF22"}>Points</Text>
                </Flex>
                <Box border={"1px solid #BEBEBE80"} mt={3}>
                    <Flex justifyContent={"space-between"} paddingY={3} alignItems={"center"} borderBottom={"1px solid #BEBEBE80"}>
                        <Text background={"#5CBF2259"} borderRadius={"full"} marginLeft={"5"} marginRight={"10"} paddingX={2.5} height={"min-content"}>1</Text>
                        <Counter />
                    </Flex>
                    <Flex justifyContent={"space-between"} paddingY={3} alignItems={"center"} borderBottom={"1px solid #BEBEBE80"}>
                        <Text background={"#5CBF2259"} borderRadius={"full"} marginLeft={"5"} marginRight={"10"} paddingX={2.5} height={"min-content"}>2</Text>
                        <Counter />
                    </Flex>
                    <Flex justifyContent={"space-between"} paddingY={3} alignItems={"center"} borderBottom={"1px solid #BEBEBE80"}>
                        <Text background={"#5CBF2259"} borderRadius={"full"} marginLeft={"5"} marginRight={"10"} paddingX={2.5} height={"min-content"}>3</Text>
                        <Counter />
                    </Flex>
                    <Flex justifyContent={"space-between"} paddingY={3} alignItems={"center"} borderBottom={"1px solid #BEBEBE80"}>
                        <Text background={"#5CBF2259"} borderRadius={"full"} marginLeft={"5"} marginRight={"10"} paddingX={2.5} height={"min-content"}>4</Text>
                        <Counter />
                    </Flex>
                    <Flex justifyContent={"space-between"} paddingY={3} alignItems={"center"} borderBottom={"1px solid #BEBEBE80"}>
                        <Text fontSize={"small"} color={"#5CBF22"} marginLeft={"5"} marginRight={"10"} paddingX={2.5} height={"min-content"}>Total Points</Text>
                        <Input/>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    </div>
  )
}

export default Submission