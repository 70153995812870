import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const createAssesment = async (payload) => {
  console.log("this is the exam payload", payload);
  return await httpRequest(`${BASE_URL}/exam`, "post", payload);
};

export const updateAssesment = async (payload) => {
  return await httpRequest(`${BASE_URL}/editanexam`, "put", payload);
};
