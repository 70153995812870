import { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
// import { Document, Page, pdfjs } from "react-pdf";
import { BiCloudDownload } from "react-icons/bi";
import { addBookToLibrary } from "../../services/libraryService";
import { fetchLibraryBooks } from "../../redux/slices/LibraryBooksSlice";

const UploadFiles = () => {
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isPremium] = useState(false);
  const [author, setAuthor] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [file, setFile] = useState("");
  const [bookCoverError, setBookCoverError] = useState("");
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleBookCover = (file) => {
    setBookCoverError("");
    const inputFile = file.target.files[0];

    if (
      !inputFile?.name.endsWith(".jpg")  &&
      !inputFile?.name.endsWith(".jpeg") &&
      !inputFile?.name.endsWith(".png") &&
      !inputFile?.name.endsWith(".PNG")&&
      !inputFile?.name.endsWith(".JPG")
    ) {
      setBookCoverError("Only jpg, jpeg and png files are acccepted");
      return;
    }
    if (inputFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCoverImage({
          name: inputFile?.name,
          extension: ".jpg",
          data: reader.result,
        });
      };
      reader.readAsDataURL(inputFile);
    }
  };


  const handleFileChange = (event) => {
    setFileError("");
    const file = event.target.files[0];
    if (!file?.name.endsWith(".pdf")) {
      setFileError("Only PDFs are accerpted");
      return;
    }

    if (file) {
      convertToBase64(file);
    }
  };

  const convertToBase64 = (filer) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result;

      setFile({
        name: filer?.name,
        extension: ".pdf",
        data: base64String,
      });
    };
    reader.readAsDataURL(filer);
  };

  const addBook = async (e) => {
    e.preventDefault();
      const payload = {
      description,
      author,
      isPremium,
      title,
      coverImage,
      file,
    };

    setLoading(true);
    const res = await addBookToLibrary(payload);
    if(res?.status) {
      dispatch(fetchLibraryBooks())
      dispatch(setFormModal({status: false}))
    }else{
      alert(res?.message)
    }
    setLoading(false);
  };
  return (
    <Flex
      direction={"column"}
      gap={8}
      sx={{
        "& .chakra-form__label": { mb: 2, fontWeight: "bold" },
        "& .chakra-input": { bg: "#BEBEBE26", border: "none" },
      }}
      mt={6}
    >
      <form onSubmit={addBook}>
        <Box>
          <FormLabel>Book Title/Name</FormLabel>
          <Input
            placeholder="Enter here"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box mt={5}>
          <FormLabel>Book Description</FormLabel>
          <Input
            placeholder="Enter here"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Box mt={5}>
          <FormLabel>Author</FormLabel>
          <Input
            placeholder="Enter here"
            required
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </Box>
        <Box mt={5}>
          <Text fontWeight={"semibold"} mb={1}>
            Book Cover
          </Text>
          <Flex
            alignItems={"center"}
            background={"#F2F2F2"}
            borderRadius={"5px"}
            justifyContent={"flex-end"}
          >
            <Input
              // id="hidden-input"
              type="file"
              // className="hidden"
              onChange={handleBookCover}
              accept="image/*"
              required
              // value={coverImage?.name}
            />
            {/* <FormLabel
              htmlFor="hidden-input"
              className="bg-white cursor-pointer"
              mt={2}
              mr={1}
              fontSize={"small"}
              paddingY={1}
              paddingX={3}
            >
              Browse File
            </FormLabel> */}
          </Flex>
          {bookCoverError && (
            <Alert status="error" className="mt-3">
              <AlertIcon />
              <AlertTitle>{bookCoverError}</AlertTitle>
            </Alert>
          )}
        </Box>

        <Box
          mt={5}
          className="bg-gray-100 p-6 py-16 rounded border-dashed"
          id="form-file-upload"
        >
          <Input
            type="file"
            id="input-file-upload"
            required
            onChange={handleFileChange}
            hidden
            accept="application/pdf"
            multiple={false}
          />
          <FormLabel id="label-file-upload" htmlFor="input-file-upload">
            <Flex gap={2} justifyContent={"center"} cursor={"pointer"}>
              <BiCloudDownload className="text-gray-600 text-lg" />
              <p className="text-xs font-light text-gray-500">
                {file?.name ? file?.name : "Drag and drop your file here"}
              </p>
            </Flex>
          </FormLabel>
          {fileError && (
            <Alert status="error" className="mt-3">
              <AlertIcon />
              <AlertTitle>{fileError}</AlertTitle>
            </Alert>
          )}
        </Box>

        {/* {file?.data && (
          <Box mt={5}>
            <div style={{display: "none"}}>
              <Document
                file={file?.data}
                onLoadSuccess={onDocumentLoadSuccess}
                height={100}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <p>
                Page {pageNumber} of {pages}
              </p>
            </div>
          </Box>
        )} */}

        <Flex
          justify={"end"}
          gap={5}
          sx={{ "& > button": { flexBasis: "15rem" } }}
          mt={4}
        >
          <Button
            border={"1px solid #9361ea"}
            bg={"white"}
            onClick={() =>
              dispatch(
                setFormModal({
                  status: false,
                })
              )
            }
          >
            Cancel
          </Button>
          <Button
            isLoading={loading}
            type="submit"
            loadingText="Processing..."
            bg={"#9361ea"}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default UploadFiles;
