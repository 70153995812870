import moment from 'moment';
export const compareTwoArrays = (array1, array2) => {
    if (array1?.length !== array2?.length) {
      return false;
    }
  
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();
  
    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }
  
    return true;
  }

  export const capitalizeFirstLetter = (word) => {
    if (!word) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  

export const simpleDateString = (date) => {
    return date ? moment(date).format().replace(/T.+$/, '') : '';
};

export const formatTime = (inputTime) => {
  const parts = inputTime.toString().split(':');
  if(parts.length < 2) {
    return `${inputTime}:00:00`;
  }
  if (parts.length === 2) {
      return `${inputTime}:00`;
  }

  return inputTime;
}

export const dateInWord = (date) => {
  return date ? moment(date).format('ll') : ""; 
};

export const sanitizeArray = (arr) => {
  return arr.filter(obj => {
    return Object.values(obj).every(value => {
      return value !== null && value !== undefined && value !== "";
    });
  });
}

export const cleanB64 = (b64) => {
 let res = b64.split(',');
 return res
}

export const numberToAlphabet = (number) => {
  if (number >= 1 && number <= 26) {
    return String.fromCharCode(64 + number); // A is 65 in ASCII
  } else {
    return "Invalid input. Please enter a number between 1 and 26.";
  }
}

export const updateIscorrect = (data) => {
  const updatedData = data.map(item => {
    return {
        ...item,  
        isCorrect: false  
    };
});

return updatedData
}

export const calculateTimeDifference = (time1, time2) => {
  // Parse the input times into Date objects
  const [hours1, minutes1] = time1?.split(':').map(Number);
  const [hours2, minutes2] = time2?.split(':').map(Number);
  const date1 = new Date(0, 0, 0, hours1, minutes1);
  const date2 = new Date(0, 0, 0, hours2, minutes2);

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(date2 - date1);

  // Convert the time difference to hours and minutes
  const hours = Math.floor(timeDifference / (60 * 60 * 1000));
  const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

  // Format the result as "xx hours yy mins"
  const formattedResult = `${hours} hours ${minutes} mins`;

  return formattedResult;
}

export const timeAgo = (date) => {
  return date ? moment(date).startOf('min').fromNow() : ""; 

}

export const dateToAgoFormat = (inputDate) => {
  const inputDateTime = new Date(inputDate);
  const currentDateTime = new Date();

  const timeDifference = currentDateTime - inputDateTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours >= 1) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes >= 1) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "few seconds ago";
  }
}





