import {useEffect} from "react";
import PageTitle from "../components/PageTitle";
import { Link, useParams } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import ResponsivePagination from "react-responsive-pagination";
import { BsEye, BsThreeDots } from "react-icons/bs";
import CustomTable2 from "../components/CustomTable2";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal, setFormModal } from "../redux/slices/modalSlice";
import { fetchProgrammes, updateProgrammePagination } from "../redux/slices/programmeSlice";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { TiDeleteOutline } from "react-icons/ti";

const ProgrammeManagementList = () => {
  const dispatch = useDispatch();

  let param = useParams();
  let sessionId = param?.sessionId;
  let year = param?.year;

  const programmes = useSelector((state) => state.programmes);

  useEffect(() => {
    dispatch(fetchProgrammes(sessionId))
  }, [programmes?.programmeParams, sessionId, dispatch])

  const deleteAction = (data) => {
    dispatch(
      setConfirmModal({
        status: true,
        payload: data?.id,
        type: "programme",
        sessionId: sessionId
      })
    );
  };

  const tableProps = {
    data: programmes?.programmes?.data,
    title: {
      name: "Programme Name",
      code: "Programme Code",
      duration: "Duration",
      addCourse: "",
    },
    dataKeys: ["name", "code", "duration", "addCourse"],
    otherBtn: true,
    otherBtnText: "Add Programme",
    headerTitle: `All Programmes (${programmes?.programmes?.totalCount})`,
    otherBtnAction: () => dispatch(setFormModal({status: true, title: "Add New Programme", type: "addProgramme", data: sessionId})),
    expectedCsvHeader: ["name", "code", "duration", "starDate", "endDate"],
    uploadType: "programmes",
    bulkProps: {"sessionId": sessionId},
    transformRow: (item) => ({
      name:   item.name,
      code: item.code,
      duration: `${item.duration}`,
      addCourse: (
        <div className="flex items-center max-w-[180px] justify-between">
          {/* <Link
            to="/programme-management-addCourse"
            className="text-blue-500 flex items-center gap-1.5"
            onClick={(e) => 
              {e.preventDefault(); 
              dispatch(setFormModal({status: true, title: `${item?.name} - Add New Course`, type: "addCourse", data: item?.id}))
            }}
          >
            <AiOutlinePlusCircle className="font-bold" />
            Add Course
          </Link> */}

          <Link to={`/course-list/${item.id}/${item.name}`} className="text-blue-500 flex items-center gap-1.5">
            Add Course
          </Link>
          <Menu>
            <MenuButton>
              <BsThreeDots className="font-bold"/>
            </MenuButton>
            <MenuList minWidth={"120px"}>
              <MenuItem gap={3}>
                <Link to={`/course-list/${item.id}/${item.name}`} className="flex items-center gap-2">
                  <BsEye />
                  View
                </Link>
              </MenuItem>
              <MenuItem 
              gap={3}
              onClick={
                () => dispatch(setFormModal({
                  status: true, 
                  title: "Edit Programme", 
                  type: "editProgramme", 
                  data: {
                    name: item?.name, 
                    code: item?.code, 
                    id: item?.id, 
                    duration: item?.duration, 
                    endDate: item?.endDate, 
                    startDate: item?.starDate, 
                    sessionId: item?.sessionId, 
                  }
                }))
              }
              >
                <AiOutlineEdit />
                Edit
              </MenuItem>
              <MenuItem 
              gap={3}
              onClick={() => deleteAction(item)}
              _hover={{background: "", textColor:"red"}}
              >
                <TiDeleteOutline />
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      )
    }),
  };

  return (
    <div>
      <PageTitle title={`Programme Management - ${year} session`}/>
      <CustomTable2 
      {...tableProps} 
      loading={programmes?.loading} 
      searchType={"programme"}
      />
      <div className="mt-5">
        <ResponsivePagination
          current={programmes.programmeParams?.pageNumber}
          total={programmes?.programmes?.totalPages}
          onPageChange={(page) => dispatch(updateProgrammePagination(page))}
        />
      </div>
    </div>
  );
};

export default ProgrammeManagementList;
