import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { setConfirmModal, setFormModal } from '../redux/slices/modalSlice'
import { AiOutlineEdit } from 'react-icons/ai'
import { TiDeleteOutline } from 'react-icons/ti'

const DropdownModal = ({id, year, highlightColor}) => {

    const dispatch = useDispatch();


  const deleteAction = () => {
    dispatch(
      setConfirmModal({
        status: true,
        payload: id,
        type: "session",
        // programmeId: programmeId
      })
    );
  };

  return (
    <Menu size={"small"}>
        <MenuButton>
        <BsThreeDotsVertical className="font-bold"/>
        </MenuButton>
        <MenuList minWidth={"90px"}>
            <MenuItem 
            style={{fontSize: "12px"}}
            gap={3}
            onClick={
                () => dispatch(setFormModal({
                status: true, 
                title: "Edit Session", 
                type: "editAcademic", 
                data: {
                    year: year, 
                    highlightColor: highlightColor, 
                    id: id, 
                }
                }))
            }
            >
                <AiOutlineEdit />
                Edit
            </MenuItem>
            <MenuItem 
            gap={3}
            style={{fontSize: "12px"}}
            onClick={() => deleteAction()}
            _hover={{background: "", textColor:"red"}}
            >
                <TiDeleteOutline />
                Delete
            </MenuItem>
        </MenuList>
  </Menu>
  )
}

export default DropdownModal