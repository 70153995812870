import photo from '../assets/images/Component 29.png'

export const mockData = [
    { id: 1, pic: photo, heading: 'Mathematics', dateCreated: "Created on 20th of February, 2023", students: "24 Students enrolled" },
    { id: 2, pic: photo, heading: 'Book Keeping', dateCreated: "Created on 20th of February, 2023", students: "24 Students enrolled" },
    { id: 3, pic: photo, heading: 'Business Studies', dateCreated: "Created on 20th of February, 2023", students: "24 Students enrolled" },
    { id: 4, pic: photo, heading: 'Design Thinking', dateCreated: "Created on 20th of February, 2023", students: "24 Students enrolled" },
]

export const filelist = [
    {id : 1, name : "Study Files", size: "25MB", type: "Folder", date_modified: "5/12/2023"},
    {id : 2, name : "Study Files", size: "25MB", type: "Folder", date_modified: "5/12/2023"},
    {id : 3, name : "Study Files", size: "25MB", type: "Folder", date_modified: "5/12/2023"},
    {id : 5, name : "Study Files", size: "25MB", type: "Folder", date_modified: "5/12/2023"},
    {id : 6, name : "Assigment_edo.docx", size: "25MB", type: "Doc", date_modified: "5/12/2023"},
    {id : 6, name : "Assigment_edo.docx", size: "25MB", type: "Doc", date_modified: "5/12/2023"},
    {id : 7, name : "Assigment_edo.pdf", size: "25MB", type: "PDF", date_modified: "5/12/2023"},
    {id : 8, name : "Assigment_edo.pdf", size: "25MB", type: "PDF", date_modified: "5/12/2023"},
]