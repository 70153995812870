import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
    allcourses: null,
    loading: false,
    error: null,
    courseParams: {
      pageSize: 20,
      pageNumber: 1,
      keyword: null,
    },
};

export const fetchAllCourses = createAsyncThunk(
    "allcourses/allcourses",
    async (arg, { getState }) => {
      return httpRequest(
        `${BASE_URL}/course/getall`, "post", {...getState()?.allcourses?.courseParams}
      );
    }
);

const getAllCourseSlice = createSlice({
    name: "allcourses",
    initialState,
    reducers: {
      updateAllCoursePagination: (state, {payload}) => {
        state.courseParams.pageNumber = payload;
        state.loading = true
      },
      updateAllCoursesKeyword: (state, {payload}) => {
        state.courseParams.keyword = payload;
      },
  
      clearAllCourses: (state) => {
        state.allcourses = null;
      },
    },
    extraReducers: (builder) => {
      //all courses
      builder.addCase(fetchAllCourses.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchAllCourses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allcourses = payload?.data;
      });
      builder.addCase(fetchAllCourses.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
      });
    },
});

export default getAllCourseSlice.reducer;


export const {
    updateAllCoursePagination,
    updateAllCoursesKeyword,
    clearAllCourses,
} = getAllCourseSlice.actions;
